import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import { FlexProps } from 'components/Box/types'
import Tab from 'components/Tab'
import Tabs from 'components/Tabs'
import Text from 'components/Text'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'theme/colors'
import { AuthModalMethodEnums } from '../types'

const SwitchMethod: React.FC<
  { onMethodChange: (method: AuthModalMethodEnums) => void; method: AuthModalMethodEnums } & FlexProps
> = ({ method, onMethodChange, ...props }) => {
  return (
    <Flex {...props} background={colors.providerBackground} borderRadius="8px">
      <Tabs value={method} onTagChanged={onMethodChange} indicator={<StyledIndicator />} width="100%">
        <StyledTabContainer
          id="switchview-wallet"
          value={AuthModalMethodEnums.WEB3}
          $active={method === AuthModalMethodEnums.WEB3}
        >
          <Text fontSize="14px">
            <Trans>Wallet</Trans>
          </Text>
        </StyledTabContainer>

        <StyledTabContainer
          id="switchview-email"
          value={AuthModalMethodEnums.TRADITIONAL}
          $active={method === AuthModalMethodEnums.TRADITIONAL}
        >
          <Text fontSize="14px">
            <Trans>Email</Trans>
          </Text>
        </StyledTabContainer>
      </Tabs>
    </Flex>
  )
}

const StyledIndicator = styled(Box)`
  background: ${({ theme }) => `${theme.colors.textDisable}`};
  border-radius: 4px;
  height: calc(100% - 8px);
  width: calc(100% - 8px);
  margin-bottom: 4px;
  position: absolute;
`

const StyledTabContainer = styled(Tab)<{ $active: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 40px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;

  width: 50%;

  ${Text} {
    z-index: 1;
    transition: ${({ theme }) => theme.transitions.fast};
    color: ${({ theme, $active }) => ($active ? `${theme.colors.text} !important` : theme.colors.textSubtle)};
    white-space: pre !important;
  }

  &:hover,
  &:focus-visible {
    ${Text} {
      color: ${({ theme }) => theme.colors.text};
      white-space: pre !important;
    }
  }
`
export default SwitchMethod
