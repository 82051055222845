import * as crypto from 'crypto'

export type BufferEncoding =
  | 'ascii'
  | 'utf8'
  | 'utf-8'
  | 'utf16le'
  | 'ucs2'
  | 'ucs-2'
  | 'base64'
  | 'base64url'
  | 'latin1'
  | 'binary'
  | 'hex'

export interface AesProps {
  plaintext: string
  base64Key: string
  base64Nonce: string
  encoding: BufferEncoding
}

export const aes128GcmEncrypt = ({ plaintext, base64Key, base64Nonce, encoding }: AesProps) => {
  const ALGORITHM_NAME = 'aes-128-gcm'

  // Create the cipher instance.
  const cipher = crypto.createCipheriv(ALGORITHM_NAME, base64ToArrayBuffer(base64Key), base64ToArrayBuffer(base64Nonce))

  // Encrypt and prepend nonce.
  const ciphertext = Buffer.concat([cipher.update(plaintext), cipher.final()])

  return Buffer.concat([ciphertext, cipher.getAuthTag()]).toString(encoding)
}

export const sha512Encypt = (plaintext: string) => {
  return crypto.createHash('sha512').update(plaintext).digest('base64')
}

const base64ToArrayBuffer = (base64) => {
  const binary_string = window.atob(base64)
  const len = binary_string.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i)
  }
  return bytes
}
