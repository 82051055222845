import { WALLET_INFO } from 'config/constants/wallet'
import { WalletInfo } from 'config/types/wallet'
import { BinanceWalletConnector } from 'packages/wagmi/connectors/binanceWallet'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { WalletConnectLegacyConnector } from 'wagmi/connectors/walletConnectLegacy'

const getWalletByName = (name: string) =>
  WALLET_INFO.find((wallet) => wallet.name.toUpperCase() === name.toUpperCase())?.name

const getNameWalletByConnectedEthereum = (): string => {
  const ethereum = window.ethereum
  switch (true) {
    case ethereum?.isTokenPocket:
      return 'TokenPocket'
    case ethereum?.isSafePal:
      return 'SafePal'
    case ethereum?.isMathWallet:
      return 'MathWallet'
    case ethereum?.isCoin98:
      return 'Coin98'

    case ethereum?.isBitEthereum || ethereum?.isBitKeep || ethereum?.isBitKeepChrome:
      return 'BitKeep'

    case ethereum?.isTrustWallet || ethereum?.isTrust:
      return 'TrustWallet'
    default:
      return 'Metamask'
  }
}

const getNameWalletByConnectedWalletConnect = (): string => {
  const storageWalletConnect = JSON.parse(localStorage.getItem('walletconnect') || '{}')
  if (storageWalletConnect?.peerMeta) {
    const nameofPeerMeta = storageWalletConnect?.peerMeta.name
    const walletName = getWalletByName(nameofPeerMeta)
    return walletName || 'WalletConnect - Unknown'
  }
  return 'WalletConnect - Unknown'
}

const getNameWalletByConnectedBSC = async (): Promise<string> => {
  const isConnected = await window?.BinanceChain?.isConnected()
  if (isConnected) {
    return 'Binance Wallet'
  }
}

export const getTrackingWalletName = async (wallet: WalletInfo): Promise<string> => {
  const isWalletConnect =
    wallet.adapter instanceof WalletConnectConnector || wallet.adapter instanceof WalletConnectLegacyConnector
  const isBSC = wallet.adapter instanceof BinanceWalletConnector

  if (isWalletConnect) {
    return getNameWalletByConnectedWalletConnect()
  }
  if (isBSC) {
    return getNameWalletByConnectedBSC()
  }
  return getNameWalletByConnectedEthereum()
}
