enum LocalStorageKey {
  HUSDBonus = '389fc2c0-e57a-11ed-b5ea-0242ac120002',
}

const MIN_HUSD_BONUS = 1
const MAX_HUSD_BONUS = 1000

export const updateHUSDBonus = (amount: number) => {
  // 900000 = 15 mins
  localStorage.setItem(LocalStorageKey.HUSDBonus, JSON.stringify({ amount, expiredAt: new Date().getTime() + 900000 }))
}

export const getHUSDBonus = () => {
  const bonus = JSON.parse(localStorage.getItem(LocalStorageKey.HUSDBonus))
  return { ...bonus, isExpired: bonus ? new Date(bonus.expiredAt) < new Date() : true }
}

export const removeHUSDBonusStorage = () => localStorage.removeItem(LocalStorageKey.HUSDBonus)

export const verifyValidHUSDBonus = (husdBonusAmount: number) =>
  husdBonusAmount && husdBonusAmount >= MIN_HUSD_BONUS && husdBonusAmount <= MAX_HUSD_BONUS
