import { ChainIdEnum } from 'config/constants/network'
import { tokenBlackList } from 'config/constants/tokens'
import { Token } from 'config/types'

export const isSolToken = (token: Token) => {
  return token?.network === ChainIdEnum.SOL || token?.network === ChainIdEnum.SOL_TESTNET
}

export const validateTokenBlackList = (token: Token): boolean => token && !!tokenBlackList.includes(token)
export const checkTokenEqual = (token1: Token, token2: Token): boolean => {
  return token1 && token2 && token1.code === token2.code && token1.network === token2.network
}
