import React, { useRef } from 'react'
import styled from 'styled-components'
import { PRODUCT_NAME } from 'config/constants/variables'
import StyledNextImage from './StyledNextImage'

export const NextImageLoaderCallback: React.FC<{
  imgs: {
    src: string
    width: number
    height: number
    original?: boolean
  }[]
  onLoaded?: () => void
}> = ({ imgs, onLoaded }) => {
  const loaderRef = useRef(
    imgs.reduce((result, value) => {
      result[value.src] = false
      return result
    }, {}),
  )

  const updateLoader = (key: string) => {
    loaderRef.current[key] = true
    if (!Object.values(loaderRef.current).includes(false)) {
      if (onLoaded) onLoaded()
    }
  }

  return (
    <StyledContainer>
      {imgs.map((img) => (
        <StyledNextImage
          src={img.src}
          alt={`${PRODUCT_NAME.toLowerCase()} preload image`}
          height={img.height}
          width={img.width}
          quality={img.original ? 100 : 80}
          priority
          onLoadingComplete={() => {
            updateLoader(img.src)
          }}
          objectFit="fill"
        />
      ))}
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  opacity: 0;
  pointer-events: none;
  position: absolute;
  height: 0px;
  overflow: hidden;
`
export default NextImageLoaderCallback

