export enum NotificationTypeEnums {
  SYSTEM = 'platform',
  PRIVATE = 'personal',
}

export enum NotificationStatusEnums {
  ALL = 'All',
  READ = 'Read',
  UNREAD = 'Unread',
}

export interface Notification {
  id: string
  bannerUrl: string
  title: string
  description: string
  status: NotificationStatusEnums
  type: NotificationTypeEnums
  createTime: number
  external: boolean
  url: string
  anchorText: string
}
