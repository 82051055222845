import Box from 'components/Box/Box'
import { BoxProps } from 'components/Box/types'
import styled from 'styled-components'

const Td: React.FC<BoxProps & { width?: string; colSpan?: number }> = ({ children, width, colSpan, ...props }) => (
  <StyledTd style={{ width }} colSpan={colSpan}>
    <StyledCell {...props} width="100%">
      {children}
    </StyledCell>
  </StyledTd>
)

const StyledCell = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 12px;
  border-left: ${({ theme: { colors } }) => `0.25px solid ${colors.stroke}`};
  border-right: ${({ theme: { colors } }) => `0.25px solid ${colors.stroke}`};
`
const StyledTd = styled.td`
  &:first-child ${StyledCell}:not(.loader) {
    border-right: none;
  }

  &:last-child ${StyledCell}:not(.loader) {
    border-left: none;
  }
`

export default styled(Td)``
