import styled from 'styled-components'
import { space } from 'styled-system'

const Table = styled.table`
  max-width: 100%;
  width: 100%;

  ${space}
`

const TableBody = styled.tbody`
  & tr {
    td {
      vertical-align: middle;
    }
  }

  tr td:first-child {
    border-top-left-radius: ${({ theme }) => theme.radii.tiny};
  }
  tr td:last-child {
    border-top-right-radius: ${({ theme }) => theme.radii.tiny};
  }

  tr td:first-child {
    border-bottom-left-radius: ${({ theme }) => theme.radii.tiny};
  }
  tr td:last-child {
    border-bottom-right-radius: ${({ theme }) => theme.radii.tiny};
  }
`

export { TableBody }
export default Table
