import BigNumber from 'bignumber.js'
import Skeleton from 'components/Skeleton'
import Text from 'components/Text'
import { Trans } from 'react-i18next'
import { getFullDisplayBalance } from 'utils/formatBalance'

const HUSDDisplayBalance: React.FC<{ balance: BigNumber; color?: string; loading?: boolean }> = ({
  balance,
  color = 'text',
  loading,
}) => {
  if (loading)
    return (
      <>
        <Skeleton width="156px" height="32px" mt="8px" />
        <Skeleton width="96px" height="14px" minHeight="14px !important" mt="8px" />
      </>
    )

  return (
    <>
      <Text fontSize="32px" color={color} fontWeight="700" mt="8px">
        {getFullDisplayBalance(balance, 0, 18)}
      </Text>

      <Text fontSize="14px" color="textSubtle" mt="8px">
        <Trans>~ ${getFullDisplayBalance(balance, 0, 5)}</Trans>
      </Text>
    </>
  )
}

export default HUSDDisplayBalance
