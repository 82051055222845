import Box from 'components/Box/Box'
import Grid from 'components/Box/Grid'
import EmptyContent from 'components/EmptyContent'
import HunnyLazyList from 'components/HunnyLazyList/HunnyLazyList'
import { ModalBody, ModalHeader } from 'components/Modal'
import Modal, { ModalProps } from 'components/Modal/Modal'
import Text from 'components/Text/Text'
import React from 'react'
import { BaseResponse, HunnyRequest, Paging } from 'services/types'
import styled from 'styled-components'
import GameItem from 'views/Games/components/GameItem'
import GameItemLoader from 'views/Games/components/GameItemLoader'

interface GameListModalProps {
  titleHeader: string
  contentDescription: string
  request: (offset: number, limit: number) => HunnyRequest<BaseResponse<Paging<any>>>
}

const LazyGameItem = ({ item }) => <GameItem game={item} p="0px !important" />

const GameListModal: React.FC<ModalProps<GameListModalProps>> = ({ data, onDismiss }) => {
  const { titleHeader, contentDescription, request } = data

  return (
    <StyledModal onDismiss={onDismiss}>
      <ModalHeader>
        <Box width="100%" pl={30} pr={20}>
          <Text fontSize="16px" bold>
            {titleHeader}
          </Text>
        </Box>
      </ModalHeader>

      <ModalBody p={['12px 0px !important', '', '24px 0px !important']} overflowX="hidden" position="relative">
        <Box overflow="auto">
          <Text px={['12px', '', '24px']} fontSize="12px" fontWeight="600" lineHeight="14.63px" color="textSubtle">
            {contentDescription}
          </Text>
          <Box maxHeight={'calc(var(--screen-height) - 25vh)'} pt={'24px'} px={['12px', '', '24px']}>
            <Grid
              position="relative"
              gridGap={['12px', '', '16px']}
              gridTemplateColumns={['1fr 1fr', '1fr 1fr', '1fr 1fr 1fr', '1fr 1fr 1fr']}
            >
              <HunnyLazyList
                uniqueKey="code"
                Item={LazyGameItem}
                noContent={
                  <Box position="absolute" top="64px" width="100%">
                    <EmptyContent justifyContent="center" width="100%" content="We couldn't find any games" />
                  </Box>
                }
                Loader={
                  <>
                    <GameItemLoader p="0px !important" />
                    <GameItemLoader p="0px !important" />
                    <GameItemLoader p="0px !important" />
                  </>
                }
                request={request}
                limit={20}
              />
            </Grid>
          </Box>
        </Box>
      </ModalBody>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    max-width: 448px;
    width: 100%;
    border-radius: ${({ theme }) => theme.radii.large};
  }
`

export default GameListModal
