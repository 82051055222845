import {
  PhantomWalletName,
  PhantomWalletAdapter,
  SlopeWalletName,
  SlopeWalletAdapter,
  SolletWalletName,
  SolletWalletAdapter,
  SolletExtensionWalletName,
  SolletExtensionWalletAdapter,
  SolflareWalletName,
  SolflareWalletAdapter,
  SolflareWebWalletName,
  SolflareWebWalletAdapter,
  BitKeepWalletName,
  BitKeepWalletAdapter,
  CloverWalletName,
  CloverWalletAdapter,
  Coin98WalletName,
  Coin98WalletAdapter,
  MathWalletName,
  MathWalletAdapter,
  SafePalWalletName,
  SafePalWalletAdapter,
} from '@solana/wallet-adapter-wallets'
import { WalletInfo, WalletType } from 'config/types/wallet'
import { ConnectorNameEnum } from 'packages/wagmi/types'
import { connectorsByName } from 'packages/wagmi/wagmi'

export const WALLET_INFO: WalletInfo[] = [
  // if udpate anything for this array, please also update for utils trackingWallet
  {
    name: 'Metamask',
    icon: 'MetamaskIcon',
    adapter: connectorsByName[ConnectorNameEnum.Injected],
    type: WalletType.EVM,
  },
  {
    name: 'WalletConnect',
    icon: 'WalletConnect',
    adapter: connectorsByName[ConnectorNameEnum.WalletConnect],
    type: WalletType.EVM,
  },
  {
    name: 'TrustWallet',
    icon: 'TrustWallet',
    adapter: connectorsByName[ConnectorNameEnum.TrustWallet],
    type: WalletType.EVM,
  },
  {
    name: 'MathWallet',
    icon: 'MathWallet',
    adapter: connectorsByName[ConnectorNameEnum.Injected],
    type: WalletType.EVM,
  },
  {
    name: 'SafePal',
    icon: 'SafePal',
    adapter: connectorsByName[ConnectorNameEnum.Injected],
    type: WalletType.EVM,
  },
  {
    name: 'Binance Wallet',
    icon: 'BSCWallet',
    adapter: connectorsByName[ConnectorNameEnum.BSC],
    type: WalletType.BSC,
  },
  {
    name: 'Coin98',
    icon: 'Coin98',
    adapter: connectorsByName[ConnectorNameEnum.Injected],
    type: WalletType.EVM,
  },
  {
    name: 'TokenPocket',
    icon: 'TokenPocket',
    adapter: connectorsByName[ConnectorNameEnum.Injected],
    type: WalletType.EVM,
  },

  {
    name: 'BitKeep',
    icon: 'BitKeepIcon',
    adapter: connectorsByName[ConnectorNameEnum.Injected],
    type: WalletType.EVM,
  },
  {
    name: PhantomWalletName,
    url: 'https://www.phantom.app',
    adapter: new PhantomWalletAdapter(),
    icon: 'PhantomWallet',
    type: WalletType.SOL,
  },
  {
    name: SolletWalletName,
    url: 'https://www.sollet.io',
    adapter: new SolletWalletAdapter({ provider: 'https://www.sollet.io' }),
    icon: 'SolletWallet',
    type: WalletType.SOL,
  },
  {
    name: SolflareWalletName,
    url: 'https://solflare.com',
    installUrl: 'https://addons.mozilla.org/en-US/firefox/addon/solflare-wallet',
    adapter: new SolflareWalletAdapter(),
    icon: 'SolflareWallet',
    type: WalletType.SOL,
  },

  {
    name: SlopeWalletName,
    url: 'https://slope.finance',
    adapter: new SlopeWalletAdapter(),
    icon: 'SlopeWallet',
    type: WalletType.SOL,
  },

  {
    name: SolletExtensionWalletName,
    url: '',
    adapter: new SolletExtensionWalletAdapter({
      provider: typeof window === 'undefined' ? null : (window as any).sollet,
    }),
    icon: 'SolletWallet',
    type: WalletType.SOL,
  },

  {
    name: SolflareWebWalletName,
    url: 'https://solflare.com',
    installUrl: 'https://addons.mozilla.org/en-US/firefox/addon/solflare-wallet',
    adapter: new SolflareWebWalletAdapter(),
    icon: 'SolflareWallet',
    type: WalletType.SOL,
  },
  {
    name: `${BitKeepWalletName} (Solana)`,
    url: 'https://bitkeep.com',
    adapter: new BitKeepWalletAdapter(),
    icon: 'BitKeepIcon',
    type: WalletType.SOL,
  },
  {
    name: CloverWalletName,
    url: 'https://clover.finance',
    adapter: new CloverWalletAdapter(),
    icon: 'CloverWallet',
    type: WalletType.SOL,
  },
  {
    name: `${Coin98WalletName} (Solana)`,
    url: 'https://www.coin98.com',
    installUrl: 'https://chrome.google.com/webstore/detail/coin98-wallet/aeachknmefphepccionboohckonoeemg',
    adapter: new Coin98WalletAdapter(),
    icon: 'Coin98',
    type: WalletType.SOL,
  },
  {
    name: `${MathWalletName} (Solana)`,
    url: 'https://mathwallet.org',
    adapter: new MathWalletAdapter(),
    icon: 'MathWallet',
    type: WalletType.SOL,
  },
  {
    name: `${SafePalWalletName} (Solana)`,
    url: 'https://www.safepal.io',
    adapter: new SafePalWalletAdapter(),
    icon: 'SafePal',
    type: WalletType.SOL,
  },
]
