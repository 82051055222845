import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import { useTiers } from 'state/app/hooks'
import { useAuth } from 'state/auth/hooks'
import { updateMyTier } from 'state/profile/actions'
import { fetchMyTierInfo } from 'state/profile/calls/fetchUserInfos'
import LevelUp from 'views/LevelUp'
import useModal from './useModal'

export const useFetchUserTier = () => {
  const tiers = useTiers()
  const tier = useAppSelector((state) => state.profile.tier)
  const { isSigned } = useAuth()

  const dispatch = useAppDispatch()
  const [onPressent] = useModal(LevelUp)

  const getUserLevelBonus = (
    oldLevel: number,
    newLevel: number,
    userBonusList: { level: number; value: string }[],
  ): number => {
    let bonusInUsd = 0
    if (newLevel === oldLevel + 1) {
      const bonusLevelUser = userBonusList?.find((tier) => {
        if (tier.level === newLevel) {
          return tier
        }
        return
      })

      bonusInUsd = parseInt(bonusLevelUser.value, 10)
    } else {
      bonusInUsd = userBonusList?.reduce((state, tier) => {
        if (tier.level > oldLevel && tier.level <= newLevel) {
          state += parseInt(tier.value, 10)
        }
        return state
      }, 0)
    }

    return bonusInUsd
  }

  const fetch = useCallback(async () => {
    if (!isSigned) return
    const result = await fetchMyTierInfo()
    if (!result) return

    const oldLevel = tier?.level
    const newLevel = result.tier?.level

    if (newLevel > oldLevel) {
      const bonusInUsd = getUserLevelBonus(
        oldLevel,
        newLevel,
        tiers.map((item) => ({ value: item.bonusUsd, level: item.level })),
      )

      onPressent({
        myTier: result.tier,
        bonusAmount: bonusInUsd.toString(),
      })
    }

    dispatch(updateMyTier({ data: result }))
    return result
  }, [tier, isSigned, tiers])

  return fetch
}
