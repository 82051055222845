import Modal, { ModalProps } from 'components/Modal/Modal'
import React from 'react'
import styled from 'styled-components'
import ModalBody from 'components/Modal/components/ModalBody'
import { Trans } from 'react-i18next'
import Image from 'components/Image'
import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Text from 'components/Text'
import { BoxProps } from 'components/Box/types'

const ConfirmationLayoutModal: React.FC<ModalProps & BoxProps> = ({ onDismiss, children, ...props }) => {
  return (
    <StyledModal onDismiss={onDismiss} {...props}>
      <StyledModalBody borderRadius="0px" height="100%">
        <Flex flexDirection="column" alignItems="center" overflow="hidden">
          <Box width="120px" height="120px">
            <Image src="/images/confirmation.png" width={300} height={300} />
          </Box>
          <Text mt="20px" fontWeight="700" fontSize="20px">
            <Trans>Confirmation</Trans>
          </Text>
        </Flex>
        {children}
      </StyledModalBody>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  padding: 24px;

  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 420px !important;
  }
`

const StyledModalBody = styled(ModalBody)`
  overflow-y: unset !important;
`

export default ConfirmationLayoutModal
