import { useWallet as useSolWallet } from '@solana/wallet-adapter-react'
import { WalletType } from 'config/types/wallet'
import { useMemo } from 'react'
import useWeb3React from 'packages/wagmi/hooks/useWeb3React'

const useWalletAccount = () => {
  const { publicKey } = useSolWallet()
  const { account } = useWeb3React()

  return useMemo(
    () => ({
      [WalletType.EVM]: account,
      [WalletType.BSC]: account,
      [WalletType.SOL]: publicKey?.toString(),
    }),
    [publicKey, account],
  )
}

export default useWalletAccount
