import Box from 'components/Box/Box'
import { BoxProps } from 'components/Box/types'
import Button from 'components/Button/Button'
import Text from 'components/Text/Text'
import { Game } from 'config/types/game'
import { RowCenter } from 'layout/Components/Row'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFeaturedGame, useToggleFavoriteGame } from 'state/profile/hooks'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'
import { abbreviateNumber } from 'utils'

interface FavoriteGameButtonProps {
  game: Game
  defaultFavoriteCount?: number
  onFavoriteCountUpdated?: (defaultFavoriteCount) => void
  size?: string
  fontSize?: string
}

const FavoriteGameButton: React.FC<FavoriteGameButtonProps & BoxProps> = React.memo(
  ({ game, defaultFavoriteCount, onFavoriteCountUpdated, size, fontSize, ...props }) => {
    const { t } = useTranslation()
    const { featuredGameInfo } = useFeaturedGame(game.code)
    const { toggleFavoriteGame } = useToggleFavoriteGame()
    const [favoriteCount, setFavoriteCount] = useState(defaultFavoriteCount || game.favoriteCount)

    const handleToggleLikeGame = (isFavoriteGame: boolean) => {
      toggleFavoriteGame(game.code, isFavoriteGame, () => {
        setFavoriteCount(isFavoriteGame ? favoriteCount + 1 : favoriteCount - 1)
      })

      if (onFavoriteCountUpdated) onFavoriteCountUpdated(isFavoriteGame ? favoriteCount + 1 : favoriteCount - 1)
    }

    return (
      <StyledContainer {...props}>
        <StyledButton
          variant="text"
          width="fit-content!important"
          height="fit-content !importnat"
          data-tooltip-id={'common-tip-top'}
          data-tooltip-content={t(featuredGameInfo.isFavorited ? 'Remove from my list' : 'Add to my list')}
          px="0px !important"
          $isLiked={featuredGameInfo.isFavorited}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            handleToggleLikeGame(!featuredGameInfo.isFavorited)
          }}
        >
          <RowCenter>
            {featuredGameInfo.isFavorited ? (
              <Icons.LikeIcon width={size} height={size} fill={colors.error} />
            ) : (
              <Icons.UnlikeIcon width={size} height={size} fill={colors.textSubtle} />
            )}
            <Text ml="2px" fontSize={fontSize} fontWeight={700} color="textSubtle">
              {abbreviateNumber(favoriteCount, favoriteCount <= 999 ? 0 : 2)}
            </Text>
          </RowCenter>
        </StyledButton>
      </StyledContainer>
    )
  },
)

const StyledContainer = styled(Box)`
  &:hover {
    ${Text} {
      color: ${({ theme: { colors } }) => colors.text} !important;
    }
  }
`

const StyledButton = styled(Button)<{ $isLiked: boolean }>`
  &:hover {
    svg {
      fill: ${({ theme, $isLiked }) => ($isLiked ? '#f05f51' : theme.colors.text)};
    }
  }
`

export default FavoriteGameButton
