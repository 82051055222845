import BigNumber from 'bignumber.js'
import { ChainIdEnum } from 'config/constants/network'
import tokens from 'config/constants/tokens'
import { CommonBonus, NoDepositCommonBonus } from 'config/types/bonus'
import {
  ApiBonusTypeEnums,
  DepositCommonBonus,
  FreeSpinCommonBonus,
  HUSDUnLockBoosterCommonBonus,
  HusdLockRewardCommonBonus,
  LevelUpCommonBonus,
  LuckSpinRewardCommonBonus,
  NoDepositFreeSpinCommonBonus,
} from 'config/types/bonus/index'
import {
  DepositBonus,
  FreeHUSDLockBonus,
  FreeLuckyspinBonus,
  FreespinBonus,
  HUSDUnLockBoosterBonus,
  IUserBonus,
  LevelUpBonus,
  NoDepositBonus,
  NoDepositFreespinBonus,
  UserBonus,
} from 'config/types/bonus/userBonus'
import { WelcomePackItemType } from 'config/types/bonus/welcomePackage'
import { BaseResponse, Paging } from 'services/types'
import { formatApiNetworkField } from 'utils'
import { IWageringCondition } from '../../config/types/bonus/userBonus'

export const parseDataWelcomePack = (data: any) => ({
  id: data.id,
  name: data.name,
  packages: data.config.packages.map((item: any): WelcomePackItemType => {
    return {
      code: item.code,
      thumbnail: `/images/bonusCenter/${item.code}-badge.png`,
      bonuses: item.vouchers.map(({ voucher }) => parsePublicBonus(voucher)),
    }
  }),
})

export const UserBonusMapper = (rawResponse: string): BaseResponse<Paging<UserBonus>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      ...response.data,
      items: response.data.items?.map((item) => parseUserBonus(item)).filter((item) => item) || [],
      selectedDepositBonusId: response.data.deposit_voucher_user_id,
    },
  }
}

export const PublicBonusDetailsMapper = (rawResponse: string): BaseResponse<CommonBonus> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)
  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && parsePublicBonus(response.data.voucher),
  }
}

export const UserBonusDetailsMapper = (rawResponse: string): BaseResponse<UserBonus> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && parseUserBonus(response.data.voucher),
  }
}

export const UserWageringBonusGameCodesMapper = (rawResponse: string): BaseResponse<string[]> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data?.list_code || [],
  }
}

export const UserBonusRedeemMapper = (rawResponse: string): BaseResponse<boolean> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.code === 'success',
  }
}

export const UserBonusApplyMapper = (rawResponse: string): BaseResponse<boolean> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.code === 'success',
  }
}

export const parseUserBonus = (apiBonus: any): UserBonus => {
  const bonus: IUserBonus = {
    id: apiBonus.id,
    info: {
      image: apiBonus.icon_url,
      name: apiBonus.title || '',
      badge: apiBonus.badge || '',
      instruction: apiBonus.voucher_description,
      redeemDate: apiBonus.redeem_time * 1000 || 0,
    },
    condition: {
      expiryDate: apiBonus.expire_time * 1000,
      maxUserLevel: apiBonus.max_level,
      minUserLevel: apiBonus.min_level || 0,
      userType: apiBonus.voucher_target_user_type,
    },
    apiStatus: apiBonus.status,
    depositId: apiBonus.data.result.deposit_result.deposit_id || null,
  }

  const buildWageringConditionData = (): IWageringCondition => ({
    maxBet: Number(apiBonus.data.setting.bet_condition.max_bet_usd),
    minBet: Number(apiBonus.data.setting.bet_condition.min_bet_usd),
    maxBonusAmountInUsd: apiBonus.data.setting.bonus_amount_condition.max_usd,
    minDeposit: apiBonus.data.setting.deposit_condition?.min_usd,
    wagerMultiplier: apiBonus.data.setting.bonus_amount_condition.wager_multiplier,
    activeDurationInDays:
      apiBonus.data.setting.deposit_condition?.day_duration ||
      apiBonus.data.setting.activate_frb_duration ||
      apiBonus.data.setting.bonus_amount_condition?.day_duration,
    wagerDurationInDays: apiBonus.data.setting.bet_condition.day_duration,
    gameCollectionId: apiBonus.data.setting.bet_condition.game_collection_id,

    bonusAmount: {
      amount: new BigNumber(apiBonus.data.result.bet_result.bonus_amount),
      token: apiBonus.data.result.bet_result.bonus_network
        ? tokens[ChainIdEnum[formatApiNetworkField(apiBonus.data.result.bet_result.bonus_network)]][
            apiBonus.data.result.bet_result.bonus_currency
          ]
        : null,
    },
    currentWager: apiBonus.data.result.bet_result.current_wager_usd,
    targetWager: apiBonus.data.result.bet_result.to_wager_usd,
  })

  switch (apiBonus.voucher_type) {
    case ApiBonusTypeEnums.HusdLockReward:
      return new FreeHUSDLockBonus(bonus, apiBonus.data.setting?.amount || '0')
    case ApiBonusTypeEnums.IncreaseVIP:
      return new LevelUpBonus(
        bonus,
        apiBonus.data.setting?.upgrade_count,
        apiBonus.data.setting?.max_level,
        apiBonus.data.setting?.duration,
      )
    case ApiBonusTypeEnums.LuckspinReward:
      return new FreeLuckyspinBonus(bonus, apiBonus.data.setting?.quantity)
    case ApiBonusTypeEnums.HusdUnlockBooster:
      // TODO integrate
      return new HUSDUnLockBoosterBonus(bonus, apiBonus.data.setting?.duration, apiBonus.data.setting?.percent)
    case ApiBonusTypeEnums.DepositBonus:
      return new DepositBonus(
        bonus,
        buildWageringConditionData(),
        Number(apiBonus.data.setting.bonus_amount_condition.percentage),
      )
    case ApiBonusTypeEnums.NoDepositBonus:
      return new NoDepositBonus(bonus, {
        ...buildWageringConditionData(),
        bonusAmount: {
          amount: new BigNumber(apiBonus.data.setting?.bonus_amount_condition?.amount),
          token: apiBonus.data.setting?.bonus_amount_condition?.network
            ? tokens[ChainIdEnum[formatApiNetworkField(apiBonus.data.setting?.bonus_amount_condition?.network)]][
                apiBonus.data.setting?.bonus_amount_condition?.currency
              ]
            : null,
        },
      })

    case ApiBonusTypeEnums.FreeSpinBonus:
      // TODO integrate
      return new FreespinBonus(bonus, buildWageringConditionData(), {
        freeSpinAmount: apiBonus.data.setting.free_round_condition.quota,
        spinDurationInDays: apiBonus.data.setting.free_round_condition.day_duration,
        spinGameIds: apiBonus.data.setting.free_round_condition.game_ids,
        spinPrice: Number(apiBonus.data.setting.free_round_condition.bet_amount_usd),
      })
    case ApiBonusTypeEnums.NoDepositFreeSpinBonus:
      // TODO integrate
      return new NoDepositFreespinBonus(bonus, buildWageringConditionData(), {
        freeSpinAmount: apiBonus.data.setting.free_round_condition.quota,
        spinDurationInDays: apiBonus.data.setting.free_round_condition.day_duration,
        spinGameIds: apiBonus.data.setting.free_round_condition.game_ids,
        spinPrice: Number(apiBonus.data.setting.free_round_condition.bet_amount_usd),
      })
    default:
      return null
  }
}

const parsePublicBonus = (apiBonus: any): CommonBonus => {
  const bonus: CommonBonus = {
    name: apiBonus.title || '',
    badge: apiBonus.badge || '',
    code: apiBonus.code,
    image: apiBonus.icon_url,
    userType: apiBonus.target_user_type,
    instruction: apiBonus.instruction,
    maxUserLevel: apiBonus.user_max_level,
    minUserLevel: apiBonus.user_min_level || 0,
    type: apiBonus.type,
    status: apiBonus.status,
  }

  const buildWageringConditionData = (): IWageringCondition => ({
    maxBet: Number(apiBonus.data.setting.bet_condition.max_bet_usd),
    minBet: Number(apiBonus.data.setting.bet_condition.min_bet_usd),
    maxBonusAmountInUsd: apiBonus.data.setting.bonus_amount_condition.max_usd,
    minDeposit: apiBonus.data.setting.deposit_condition?.min_usd,
    wagerMultiplier: apiBonus.data.setting.bonus_amount_condition.wager_multiplier,
    activeDurationInDays:
      apiBonus.data.setting.deposit_condition?.day_duration || apiBonus.data.setting.add_bonus_duration,
    wagerDurationInDays: apiBonus.data.setting.bet_condition.day_duration,
    gameCollectionId: apiBonus.data.setting.bet_condition.game_collection_id,

    bonusAmount: {
      amount: new BigNumber(apiBonus.data.setting?.bonus_amount_condition?.amount),
      token: apiBonus.data.setting?.bonus_amount_condition?.network
        ? tokens[ChainIdEnum[formatApiNetworkField(apiBonus.data.setting?.bonus_amount_condition?.network)]][
            apiBonus.data.setting?.bonus_amount_condition?.currency
          ]
        : null,
    },
  })

  switch (apiBonus.type) {
    case ApiBonusTypeEnums.HusdLockReward:
      return new HusdLockRewardCommonBonus(bonus, apiBonus.data.setting?.amount)
    case ApiBonusTypeEnums.IncreaseVIP:
      return new LevelUpCommonBonus(bonus, {
        upgradeCount: apiBonus.data.setting?.upgrade_count,
        maxLevel: apiBonus.data.setting?.max_level,
        duration: apiBonus.data.setting?.duration,
      })
    case ApiBonusTypeEnums.LuckspinReward:
      return new LuckSpinRewardCommonBonus(bonus, apiBonus.data.setting?.quantity)

    // TODO integrate
    case ApiBonusTypeEnums.HusdUnlockBooster:
      return new HUSDUnLockBoosterCommonBonus(bonus, apiBonus.data.setting?.duration, apiBonus.data.setting?.percent)

    case ApiBonusTypeEnums.DepositBonus:
      return new DepositCommonBonus(bonus, {
        ...buildWageringConditionData(),
        percent: Number(apiBonus.data.setting.bonus_amount_condition.percentage),
      })

    case ApiBonusTypeEnums.NoDepositBonus:
      return new NoDepositCommonBonus(bonus, {
        ...buildWageringConditionData(),
      })

    case ApiBonusTypeEnums.FreeSpinBonus:
      return new FreeSpinCommonBonus(bonus, {
        freeSpinAmount: apiBonus.data.setting.free_round_condition.quota,
        spinDurationInDays: apiBonus.data.setting.free_round_condition.day_duration,
        spinGameIds: apiBonus.data.setting.free_round_condition.game_ids,
        spinPrice: Number(apiBonus.data.setting.free_round_condition.bet_amount_usd),
        minDeposit: apiBonus.data.setting.deposit_condition.min_usd,
        maxBonusAmountInUsd: apiBonus.data.setting.bonus_amount_condition.max_usd,
        wagerMultiplier: apiBonus.data.setting.bonus_amount_condition.wager_multiplier,
      })
    case ApiBonusTypeEnums.NoDepositFreeSpinBonus:
      return new NoDepositFreeSpinCommonBonus(bonus, {
        freeSpinAmount: apiBonus.data.setting.free_round_condition.quota,
        spinDurationInDays: apiBonus.data.setting.free_round_condition.day_duration,
        spinGameIds: apiBonus.data.setting.free_round_condition.game_ids,
        spinPrice: Number(apiBonus.data.setting.free_round_condition.bet_amount_usd),
        minDeposit: apiBonus.data.setting.deposit_condition.min_usd,
        maxBonusAmountInUsd: apiBonus.data.setting.bonus_amount_condition.max_usd,
        wagerMultiplier: apiBonus.data.setting.bonus_amount_condition.wager_multiplier,
      })
    default:
      break
  }
}

