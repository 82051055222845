import { ReferralCampaign } from 'config/types/referral'
import { useRequest } from 'hooks/useRequest'
import { useEffect, useMemo, useState } from 'react'
import ReferralService from 'services/ReferralService'
import { useAuth } from 'state/auth/hooks'

export const useDefaultCampaign = () => {
  const { isSigned } = useAuth()
  const { execute } = useRequest()
  const [campaign, setCampaign] = useState<ReferralCampaign>()

  useEffect(() => {
    if (!isSigned) return
    const fetch = async () => {
      const response = await execute(ReferralService.getCampaign())
      if (response && response.data) setCampaign(response.data)
    }

    fetch()
  }, [isSigned])

  return useMemo(() => ({ campaign }), [campaign])
}
