import { WalletType } from 'config/types/wallet'
import useModal from 'hooks/useModal'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'state'
import { useAuth } from 'state/auth/hooks'
import { HunnyToast } from 'utils/toastify'
import LuckySpin from 'views/LuckySpin'

export function useOpenLuckySpinCallback() {
  const wallet = useAppSelector((state) => state.auth.wallet)
  const { isSigned } = useAuth()
  const { t } = useTranslation()

  const check = useCallback(
    (callback?: () => void, onFailed?: () => void) => {
      if (isSigned && wallet?.type === WalletType.SOL) {
        HunnyToast.error(
          t('Feature Not Supported!'),
          t(
            'Unfortunately, Solana network users are not currently supported for Lucky Spin. Please create an account using Metamask Wallet to enjoy the free spins.',
          ),
        )
        if (onFailed) onFailed()
      } else if (callback) callback()
    },
    [isSigned, wallet?.type],
  )

  return check
}

function useLuckySpinModal() {
  const [handlePresent, handleOnDimiss] = useModal(LuckySpin)

  const openCallback = useOpenLuckySpinCallback()

  const handlePresentWithValidateUser = useCallback(() => {
    openCallback(() => {
      handlePresent()
    })
  }, [openCallback])

  return [handlePresentWithValidateUser, handleOnDimiss]
}

export default useLuckySpinModal
