import tokens from 'config/constants/tokens'
import { Token } from 'config/types'

export const combineDepositHash = (hash: string, token: Token) => {
  return `${hash}__${token.code}__${token.network}`
}

export const getDepositHashInfo = (combinedHash: string) => {
  const [hash, tokenCode, network] = combinedHash.split('__')

  return { hash, token: tokens[network][tokenCode] as Token }
}
