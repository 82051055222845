import BigNumber from 'bignumber.js'
import { ChainIdEnum } from 'config/constants/network'
import { HUSD_TOKEN } from 'config/constants/tokens'
import { Token } from 'config/types'
import { useRequest } from 'hooks/useRequest'
import { useEffect, useMemo, useState } from 'react'
import PaymentService from 'services/PaymentService'
import { getChainCode } from 'utils'
import { isSolToken } from 'utils/token'

export const useWithdrawFee = (selectedToken: Token) => {
  const { execute } = useRequest()
  const [withdrawFee, setWithdrawFee] = useState<string>('')

  useEffect(() => {
    if (selectedToken === HUSD_TOKEN) return

    const fetchFee = async () => {
      const chainType = isSolToken(selectedToken) ? 'solana' : 'evm'

      const response = await execute(
        PaymentService.getWithdrawFee({
          chainType,
          chainCode: getChainCode(ChainIdEnum[selectedToken.network]),
          currency: selectedToken.code,
        }),
      )

      if (response?.data) setWithdrawFee(response.data.withdrawFee)
    }

    fetchFee()
  }, [selectedToken])

  return useMemo(() => {
    return new BigNumber(withdrawFee)
  }, [withdrawFee])
}
