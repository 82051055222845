export enum TransactionPageEnums {
  Deposit = 'deposit',
  Withdraw = 'withdraw',
  Reward = 'reward',
  Bonus = 'bonus',
  Swap = 'swap',
}

export const TransactionPages = [
  {
    content: 'Deposit',
    value: TransactionPageEnums.Deposit,
  },
  {
    content: 'Withdraw',
    value: TransactionPageEnums.Withdraw,
  },
  {
    content: 'Reward',
    value: TransactionPageEnums.Reward,
  },
  {
    content: 'Bonus',
    value: TransactionPageEnums.Bonus,
  },
  {
    content: 'Swap',
    value: TransactionPageEnums.Swap,
  },
]

export const QUERY_TRANSACTION_INTERVAL = 5000

export enum TransactionReceiptStatusEnum {
  SUCCESS = 1,
  FAILED = 0,
}

export enum QueryDepositStatusEnums {
  Init = 1,
  ScanFailed = -2,
  InvalidData = -3,
  InvalidMinimumFee = -4,
  Success = 10,
}

export enum HusdUnlockTxnStatusEnums {
  All = -2,
  Received = 1,
  Outcome = -1,
  Claimed = 1001,
  Promotion = 1000,
  LuckySpin = 1002,
  Reward = 10004,
  VoucherReward = 1006,
}
