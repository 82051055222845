import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import { BoxProps } from 'components/Box/types'
import Image from 'components/Image'
import OpenEffect from 'components/OpenEffect'
import Text from 'components/Text'
import TokenLogo from 'components/TokenLogo'
import { INIT_ROTATE_DEG, PRIZE_ITEM_ROTATE_DEG, SPINNING_TIME } from 'config/constants/luckyspin'
import configTokens from 'config/constants/tokens'
import { SpinPrizeInfo } from 'config/types/luckyspin'
import useToggle from 'hooks/useToggle'
import { useCallback, useEffect, useMemo } from 'react'
import { useAuth } from 'state/auth/hooks'
import { useUpdateHUSDBonus } from 'state/bonus/hooks'
import styled, { css, keyframes } from 'styled-components'
import { LOGO_WHEEL_DECOR } from 'config/constants/variables'
import { parseToLockedHusd } from '../utils'

interface WheelProps extends BoxProps {
  prizes: SpinPrizeInfo[]
  onSpinFinished: (selectedPrize: SpinPrizeInfo) => void
  onSpinStart: () => void
  selectedPrize?: SpinPrizeInfo
  spinFinishFinalDegree?: number
  isSpinning?: boolean
  disabled?: boolean
}

const Wheel = ({
  onSpinFinished,
  onSpinStart,
  prizes,
  selectedPrize,
  spinFinishFinalDegree,
  isSpinning: _isSpinning,
  disabled,
  ...props
}: WheelProps) => {
  const prizeItemOffset = useMemo(() => (prizes?.length ? Math.floor(180 / prizes.length) : 0), [prizes])
  const [isSpinning, toggleSpinning, setIsSpinning] = useToggle(false)
  const { isSigned } = useAuth()

  const { fetchHUSDUnlockableBalance } = useUpdateHUSDBonus()

  useEffect(() => {
    setIsSpinning(_isSpinning)
  }, [_isSpinning])

  const handleStartSpin = useCallback(() => {
    if (isSpinning || !isSigned) return
    onSpinStart()
  }, [isSpinning, isSigned, disabled])

  useEffect(() => {
    if (isSpinning) {
      const timer = setTimeout(() => {
        if (selectedPrize.currency === 'HUSD') fetchHUSDUnlockableBalance()
        toggleSpinning()
        onSpinFinished(selectedPrize)
      }, SPINNING_TIME)

      return () => clearTimeout(timer)
    }
  }, [isSpinning, selectedPrize])

  return (
    <StyledWheelContainer {...props}>
      <StyledWheelWrap $keepSpinning={isSpinning} $finalDegAfterSpin={spinFinishFinalDegree}>
        <Box position="relative" width={[300, 340, 380, 400]} height={[300, 340, 380, 400]}>
          <StyledWheel src="/images/luckyspin/spin-wheel.png" width={400} height={400} alt="Spin Wheel" blurLoader />
        </Box>
        <StyledPrizeList>
          {prizes.length
            ? prizes.map((prize, index) => {
                const token = parseToLockedHusd(configTokens[prize.network][prize.currency])

                if (token)
                  return (
                    <StyledPrizeItem
                      $rotateDeg={PRIZE_ITEM_ROTATE_DEG * index + prizeItemOffset}
                      key={`token-info-${prize.id}`}
                    >
                      <StyledRotatePrizeItem>
                        <OpenEffect openType="fade">
                          <Text fontSize="18px" bold>
                            {prize.amount.toString()}
                          </Text>
                        </OpenEffect>
                        <OpenEffect openType="fade">
                          <TokenLogo token={token} ml={1} tokenImageProps={{ width: 34, height: 34 }} />
                        </OpenEffect>
                      </StyledRotatePrizeItem>
                    </StyledPrizeItem>
                  )

                return <></>
              })
            : null}
        </StyledPrizeList>
      </StyledWheelWrap>

      <StyledHunnyFemaleGirl display={['none', 'none', 'none', 'none', 'block']}>
        <Image src={LOGO_WHEEL_DECOR} width={220} height={390} />
      </StyledHunnyFemaleGirl>

      <StyledSpinButton onClick={handleStartSpin} className={isSigned && !isSpinning ? '' : 'disabled'}>
        <Image src="/images/luckyspin/spin-button.png" width={90} height={90} />
      </StyledSpinButton>

      <StyledSpinterPointer>
        <Image src="/images/luckyspin/wheel-pointer.png" width={50} height={50} />
      </StyledSpinterPointer>
    </StyledWheelContainer>
  )
}

export const StyledWheelContainer = styled(Box)`
  position: absolute;

  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 10;

  ${({ theme: { mediaQueries } }) => mediaQueries.lg} {
    top: 55%;
    left: -30%;
    transform: translateY(-50%);
  }
`

const SpinningAnimation = (finalRotateVal: number) => keyframes`
0% {
  transform: rotate(15deg)
  
}
85% {
  transform: rotate(${INIT_ROTATE_DEG + 180 - finalRotateVal + 7.5}deg)
}
100% {
  transform: rotate(${INIT_ROTATE_DEG + 180 - finalRotateVal}deg)
}
`

const StyledWheelWrap = styled(Box)<{ $keepSpinning: boolean; $finalDegAfterSpin: number }>`
  position: relative;
  z-index: 2;

  transform-origin: center center;
  transform: rotate(15deg);

  animation: ${({ $keepSpinning, $finalDegAfterSpin }) => {
    if ($keepSpinning)
      return css`
        ${SpinningAnimation($finalDegAfterSpin)} ${SPINNING_TIME}ms ease forwards
      `
  }};
`

const StyledWheel = styled(Image)`
  z-index: 2;
  img {
    border-radius: 50%;
  }
`

const StyledPrizeList = styled(Box)`
  position: absolute;
  top: 0;
  z-index: 3;

  display: grid;
  grid-template-areas: 'spinner';
  align-items: center;

  width: 100%;
  height: 100%;

  & > ${Flex} {
    grid-area: spinner;
  }
`

const StyledPrizeItem = styled(Flex)<{ $rotateDeg: number }>`
  transform: ${({ $rotateDeg }) => `rotate(${$rotateDeg}deg)`};
  width: 50%;
  height: 50%;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  transform-origin: center right;
`

const StyledRotatePrizeItem = styled(Flex)`
  align-items: center;
  transform: rotate(180deg);
  width: 40%;
`

const StyledHunnyFemaleGirl = styled(Box)`
  position: absolute;
  top: -120px;
  left: 0;
  z-index: 1;
`

const StyledSpinButton = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  z-index: 3;
  background: transparent !important;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
`

const StyledSpinterPointer = styled(StyledSpinButton)`
  right: -10px;
  left: unset;
  transform: translateY(-50%);

  ${({ theme: { mediaQueries } }) => mediaQueries.lg} {
    right: -25px;
  }
`

export default Wheel

