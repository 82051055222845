import { parseServerTime } from 'utils/dateHelper'

export const displayCountdownTimeUnit = (timeDisplay: string, timeUnit: string, timeValue: number | string) => {
  return `<div class=${timeUnit?.toLowerCase()}>
    <div class='value'>${timeValue}</div>
    <p class='unit'>${timeDisplay}</p>
  </div>`
}

export const getCashbackEndDateTimestamp = (timestamps: number[], serverTimeMargin: number) => {
  /// Following logic need sever time margin so cannot leave it into mapper functions
  const rightJustNow = parseServerTime(new Date().getTime() - serverTimeMargin)
  const closestEndDate = timestamps
    ?.sort((timestampBefore, timestampAfter) => timestampBefore - timestampAfter)
    .find((timestamp) => timestamp > rightJustNow)
  const endDateTimestamp = new Date(closestEndDate * 1000).getTime() + serverTimeMargin
  return endDateTimestamp
}

