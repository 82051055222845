import Flex from 'components/Box/Flex'
import OpenEffect from 'components/OpenEffect'
import Text from 'components/Text'
import { useCountDown } from 'hooks/useCountdown'
import { Trans, useTranslation } from 'react-i18next'
import { useNextSpinTime, useUpdateUserLuckySpin, useUserLeftSpinTimes } from 'state/profile/hooks'
import styled from 'styled-components'
import { displaySpinCountdownUnit } from '../utils'

const SpinCountdown: React.FC = () => {
  const { t } = useTranslation()
  const amount = useUserLeftSpinTimes()
  const nextSpinTime = useNextSpinTime()
  const update = useUpdateUserLuckySpin()

  const spinExpireTimeRef = useCountDown(
    nextSpinTime,
    (s, m, h) => `
      ${displaySpinCountdownUnit(t('Hour'), 'Hour', h)}
      <div class='spacer'></div>
      ${displaySpinCountdownUnit(t('Min'), 'Min', m)}
      <div class='spacer'></div>
      ${displaySpinCountdownUnit(t('Sec'), 'Sec', s)}
    `,
    update,
  )

  return amount === 0 && nextSpinTime ? (
    <OpenEffect openType="slideBottomToTop">
      <Flex flexDirection="column" alignItems="center" position="relative" top={[-32, -32, -32, -32, 0]}>
        <Text
          fontSize={['16px', '16px', '16px', '16px', '18px']}
          color="textSubtle"
          bold
          mb={[0, 0, 0, 0, 2]}
          mt={[2, 2, 2, 2, 0]}
        >
          <Trans>Next Spin in</Trans>
        </Text>

        <StyledCountdownContent ref={spinExpireTimeRef} />
      </Flex>
    </OpenEffect>
  ) : null
}

const StyledCountdownContent = styled(Flex)`
  color: ${({ theme: { colors } }) => colors.text};

  display: flex;
  align-items: center;

  .day,
  .hour,
  .min,
  .sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .value {
      width: 50px;
      height: 60px;

      display: flex;
      align-items: center;
      justify-content: center;

      font-weight: bold;
      font-size: 48px;
      font-family: 'Montserrat';

      text-align: center;
      margin-bottom: 4px;

      ${({ theme: { mediaQueries } }) => mediaQueries.xs} {
        font-size: 40px;
      }
      ${({ theme: { mediaQueries } }) => mediaQueries.lg} {
        font-size: 48px;

        margin-bottom: 12px;
      }
    }

    .unit {
      font-size: 17px;
      font-weight: bold;
      font-family: 'Montserrat';
    }
  }

  .spacer {
    height: 60px;
    width: 2px;

    background: rgb(173, 173, 173);
    margin: 0 18px;

    ${({ theme: { mediaQueries } }) => mediaQueries.md} {
      margin: 0 26px;
    }
  }
`

export default SpinCountdown
