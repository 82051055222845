import { APIEndpointEnum } from 'config/constants/server'
import { WalletType } from 'config/types/wallet'
import { delayed } from 'utils'
import { parseServerTime } from 'utils/dateHelper'
import { HunnyPlayRequest } from './HunnyPlayRequest'
import {
  BaseResponse,
  HunnyRequest,
  LoginResponse,
  PasswordNonceResponse,
  PrepareSignMessagePayload,
  PrepareSignMessageResponse,
  RegisterResponse,
  RegisteredEmailPayload,
  SignInByEmailPayload,
  SignInByWalletPayload,
  TraditionalSignUpPreparePayload,
  Verification,
  VerificationResponse,
} from './types'

class AuthenticationService extends HunnyPlayRequest {
  public getTraditionalSignUpPrepare(
    payload: TraditionalSignUpPreparePayload,
  ): HunnyRequest<BaseResponse<PasswordNonceResponse>> {
    const result = this._post(
      APIEndpointEnum.SignUpPrepare,
      {
        username: payload.username,
        referral_code: payload.referralCode,
      },
      { excludeErrors: ['error_data_exists'] },
    )
    return result
  }

  public verifyRegisterEmail(email: string): HunnyRequest<BaseResponse<VerificationResponse>> {
    return this._post(APIEndpointEnum.VerifyRegisterUser, {
      subject: email,
      type: 'email',
      username: email,
    })
  }

  public verifyResetPassword(username: string): HunnyRequest<BaseResponse<VerificationResponse>> {
    return this._post(APIEndpointEnum.VerifyResetPassword, {
      username,
    })
  }

  public async registerEmail(payload: RegisteredEmailPayload): Promise<BaseResponse<RegisterResponse>> {
    let _payload = {
      username: payload.username,
      password: payload.password,
      nonce_id: payload.nonceId,
      verifications: payload.verifications.map((verification) => ({
        type: verification.type,
        data: {
          token: verification.data.token,
          otp: verification.data.otp,
          expire_time: parseServerTime(verification.data.expireTime),
          expired_time: parseServerTime(verification.data.expiredTime),
          next_resent_time: parseServerTime(verification.data.nextResentTime),
        },
      })),
      referral_code: payload.referralCode || '',
      stag: payload.affilkaCode || '',
      device_uid: payload.deviceUid,
      password_nonce: payload?.passwordNonce,
    }

    if (payload.husdBonusAmount) {
      _payload = Object.assign(_payload, { husd_bonus_amount: payload.husdBonusAmount })
    }

    const result: BaseResponse<any> = await this._request(APIEndpointEnum.Register, _payload, {
      excludeErrors: ['error_auth_input', 'error_data_expired'],
    })

    // Waiting assign new signup bonus
    await delayed(2000)

    return result
  }

  public async validateResetPassword(
    username: string,
    verification: Verification,
  ): Promise<BaseResponse<PasswordNonceResponse>> {
    const result = await this._request(
      APIEndpointEnum.ValidateResetPassword,
      {
        username,
        verification,
      },
      { excludeErrors: ['error_auth_input', 'error_data_expired'] },
    )
    return result
  }

  public async submitResetPassword(payload: {
    newPassword: string
    nonce: PasswordNonceResponse
    username: string
  }): Promise<BaseResponse<any>> {
    const result = await this._request(APIEndpointEnum.SubmitResetPassword, {
      username: payload.username,
      token: payload.nonce.token,
      nonce_id: payload.nonce.id,
      new_password: payload.newPassword,
    })
    return result
  }

  public async refresh(username: string, deviceUid: string, uid: number): Promise<BaseResponse<LoginResponse>> {
    const result = await this._request(APIEndpointEnum.Refresh, {
      // TODO waiting API change field name
      address: username,
      device_uid: deviceUid,
      uid,
    })

    return result
  }

  public getMessageToWalletSign(
    payload: PrepareSignMessagePayload,
  ): HunnyRequest<BaseResponse<PrepareSignMessageResponse>> {
    const parsedPayload = {
      address: payload.address,
      chain_code: 'mainet',
      chain_type: payload.walletType === WalletType.SOL ? 'solana' : 'evm',
    }
    const result = this._post(APIEndpointEnum.WalletLoginPrepare, parsedPayload)
    return result
  }

  public async signInByWallet(payload: SignInByWalletPayload): Promise<BaseResponse<LoginResponse>> {
    let _payload = {
      device_uid: payload.deviceUid,
      address: payload.address,
      accept_message: payload.acceptMessage,
      message_signature: payload.messageSignature,
      referral_code: payload.referralCode || '',
      stag: payload.affilkaCode || '',
    }

    if (payload.husdBonusAmount) {
      _payload = Object.assign(_payload, { husd_bonus_amount: payload.husdBonusAmount })
    }

    const result = await this._request(APIEndpointEnum.SignInByWallet, _payload)

    // Waiting assign new signup bonus
    if (result.data?.isSignUp) {
      await delayed(2000)
    }

    return result
  }

  public async getTraditionalSignInPrepare(username: string): Promise<BaseResponse<PasswordNonceResponse>> {
    const result = await this._request(APIEndpointEnum.PrepareTraditionalLogin, { username })
    return result
  }

  public async signInByEmail(payload: SignInByEmailPayload): Promise<BaseResponse<{ uid: number }>> {
    const result: BaseResponse<any> = await this._request(APIEndpointEnum.SignInByEmail, {
      device_uid: payload.deviceUid,
      username: payload.username,
      password: payload.password,
      nonce_id: payload.nonceId,
    })

    return result
  }

  public async logout(): Promise<boolean> {
    const result = await this._request('api/v1/auth/logout/')
    return result?.code === 'success'
  }
}

const instance = new AuthenticationService()
export default instance
