import { CashbackTransaction } from 'config/types/cashback'
import { BaseResponse, Paging } from 'services/types'
import { parseCashbackTransaction } from './utils'

export const CashbackTransactionMapper = (rawResponse: string): BaseResponse<Paging<CashbackTransaction>> => {
  const response: BaseResponse<Paging<any>> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: 'success',
    data: {
      paging: {
        ...response.data?.paging,
      },
      items: parseCashbackTransaction(response.data.items),
    },
  }
}

export const CashbackTotalEarnedMapper = (rawResponse: string): BaseResponse<Paging<CashbackTransaction>> => {
  const response: BaseResponse<Paging<any>> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: 'success',
    data: {
      paging: {
        ...response.data?.paging,
      },
      items: parseCashbackTransaction(response.data.items),
    },
  }
}
