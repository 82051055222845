import BigNumber from 'bignumber.js'
import { ChainIdEnum, NETWORK_MAP } from 'config/constants/network'
import { BIG_ZERO } from 'config/constants/number'
import tokens, { HUSD_TOKEN } from 'config/constants/tokens'
import { Network, Token } from 'config/types'
import { GameDetail } from 'config/types/game'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import { useRouter } from 'hooks/useRouter'
import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AppState, useAppDispatch, useAppSelector } from 'state'
import theme from 'theme'
import { breakpointMap } from 'theme/base'
import { getNetworkInfo } from 'utils/network'
import { buildResponsiveStyledSystemWithExceptedSidebar } from 'utils/styled'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import { FetchingStatus } from 'config/constants'
import { setMenuOpen, setPlayingGame, setSidebarOpen, updateTokenUsdPrices } from './actions'
import { fetchTokenInUsdPrices } from './calls/fetchPriceInUsd'

export const useSolNetworkInfo = () => {
  const networkIds = useAppSelector((state) => state.app.networkIds)
  const solChain = networkIds?.find((chain) => chain === 'SOL_TESTNET' || chain === 'SOL')

  return NETWORK_MAP[ChainIdEnum[solChain] || ChainIdEnum.SOL]
}

export const usePollTokenUsdPrices = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const fetch = async () => {
      const response = await fetchTokenInUsdPrices()
      if (response) dispatch(updateTokenUsdPrices({ data: response.data }))
    }

    const interval = setInterval(fetch, 30000)
    return () => {
      clearInterval(interval)
    }
  }, [tokens])
}

export const useTokenUsdPrices = () => {
  const tokenUsdPrices = useSelector((state: AppState) => state.app.tokenUsdPrices)

  return useMemo(() => ({ ...tokenUsdPrices, [HUSD_TOKEN.code]: '1' }), [tokenUsdPrices])
}

export const useTokenUsdPrice = (token: Token) => {
  const tokenUsdPrices = useSelector((state: AppState) => state.app.tokenUsdPrices)

  return token ? new BigNumber(tokenUsdPrices[token.code]) : BIG_ZERO
}

export const useMenuContainer = () => {
  const dispatch = useAppDispatch()
  const isMenuOpen = useAppSelector((state) => state.app.isMenuOpen)
  const toggleMenu = useCallback(() => {
    dispatch(setMenuOpen({ isOpen: !isMenuOpen }))
  }, [isMenuOpen])

  const menuWidth = useMemo(() => {
    if (window.document.body.offsetWidth > breakpointMap.xl) {
      return isMenuOpen ? theme.navigationWidth : theme.navigationSmallWidth
    }
    if (window.document.body.offsetWidth > breakpointMap.md) {
      return theme.navigationSmallWidth
    }

    return 0
  }, [isMenuOpen])

  return { toggleMenu, isMenuOpen, menuWidth }
}

export const useToggleSidebar = () => {
  const dispatch = useAppDispatch()
  const isSidebarOpen = useAppSelector((state) => state.app.isSidebarOpen)
  const toggleSidebar = useCallback(() => {
    dispatch(setSidebarOpen({ isOpen: !isSidebarOpen }))
  }, [isSidebarOpen])

  return useMemo(() => ({ toggleSidebar, isSidebarOpen }), [toggleSidebar, isSidebarOpen])
}

export const useResponsiveWithoutSidebar = () => {
  const isSidebarOpen = useAppSelector((state) => state.app.isSidebarOpen)

  return useCallback(
    (responsive: any[]) => buildResponsiveStyledSystemWithExceptedSidebar(responsive, isSidebarOpen),
    [isSidebarOpen],
  )
}

export const useSidebarInfo = () => {
  const { isSidebarOpen } = useToggleSidebar()
  const sidebarWidth = useMemo(() => (isSidebarOpen ? theme.sidebarWidth : 0), [isSidebarOpen])

  return useMemo(() => ({ sidebarWidth }), [sidebarWidth])
}

export const useMenuToggleUpdater = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { isXxl, isXxxl } = useMatchBreakpoints()

  useIsomorphicEffect(() => {
    if (!isXxl && !isXxxl) {
      dispatch(setMenuOpen({ isOpen: false }))
    } else {
      dispatch(setMenuOpen({ isOpen: true }))
    }
  }, [])

  useEffect(() => {
    if (!isXxxl && !isXxl) {
      dispatch(setMenuOpen({ isOpen: false }))
    }
  }, [router.pathname])
}

export const useListNetworks = (filter: { excludeChains?: ChainIdEnum[]; selectChains?: ChainIdEnum[] }): Network[] => {
  const { excludeChains, selectChains } = filter

  const networkIds = useAppSelector((state) => state.app.networkIds)

  return useMemo(() => {
    return networkIds
      ?.filter((network) => !selectChains || selectChains.includes(ChainIdEnum[network]))
      .filter((network) => !excludeChains || !excludeChains.includes(ChainIdEnum[network]))
      .map((network) => NETWORK_MAP[ChainIdEnum[network]])
  }, [networkIds])
}

export const useAllTokens = (
  condition: (token: Token, index?: number, array?: Token[]) => boolean = () => true,
  dependencies: any[] = [],
): Token[] => {
  const tokenInfoes = useAppSelector((state) => state.app.tokenInfoes)
  const tokenCodes = tokenInfoes?.map((token) => `${token.network}-${token.token}`).join(',')
  return useMemo(() => {
    return tokenInfoes
      ?.map((tokenInfo) => {
        return tokens[tokenInfo.network] && tokens[tokenInfo.network][tokenInfo.token]
      })
      .filter((token, index, array) => {
        return condition(token, index, array)
      })
  }, [tokenCodes, ...dependencies])
}

export const useTokenDetails = (network: ChainIdEnum, code: string) => {
  return useMemo(() => {
    return tokens[network] && tokens[network][code]
  }, [network, code])
}

export const useListTokens = (networkId?: ChainIdEnum): Token[] => {
  const tokenInfoes = useAllTokens((token: Token) => !networkId || token?.network === networkId, [networkId])

  return tokenInfoes
}

export const useNetwork = (chainId: ChainIdEnum): { network: Network; native: Token } => {
  const { network, native } = getNetworkInfo(chainId)

  return useMemo(() => {
    return { network, native }
  }, [network, native])
}

export const useConnectedSystem = () => {
  const serverTime = useAppSelector((state) => state.app.serverTime)

  return !!serverTime
}

export const useProviderInfo = (providerCode: string) => {
  const providers = useAppSelector((state) => state.app.providers)
  return useMemo(() => providers?.find((provider) => provider.code === providerCode), [providerCode, providers])
}

export const usePlayingGame = () => {
  const playingGame = useAppSelector((state) => state.app.playingGame)
  const dispatch = useAppDispatch()
  const update = useCallback((selectedGame: GameDetail) => dispatch(setPlayingGame({ game: selectedGame })), [])

  return useMemo(() => ({ playingGame, update }), [playingGame, update])
}

export const useAppStatus = () => {
  const initialSiteStatus = useAppSelector((state) => state.app.initialSiteStatus)

  const isFinishFetch =
    initialSiteStatus === FetchingStatus.Fetched ||
    initialSiteStatus === FetchingStatus.Failed ||
    initialSiteStatus === FetchingStatus.Banned ||
    initialSiteStatus === FetchingStatus.Maintenance

  return useMemo(() => ({ status: initialSiteStatus, isFinishFetch }), [initialSiteStatus, isFinishFetch])
}

export const useGetPromotionMenu = () => {
  const promotions = useSelector((state: AppState) => state.app.pinnedPromotions || [])
  return promotions.slice(0, 2)
}

export const useTiers = () => {
  const tiers = useSelector((state: AppState) => state.app.tiers)
  return tiers || []
}

