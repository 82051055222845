import { HunnyRequest } from 'services/types'

export const forkjoinRequest = async (promises: Promise<any>[]): Promise<any[]> => {
  const result = []

  for (let index = 0; index < promises.length; index++) {
    result.push(await promises[index])
  }

  return result
}

export async function enrichDataCollection<TSource>(
  data: TSource[],
  property: keyof TSource,
  request: (data: TSource) => Promise<any>,
): Promise<any> {
  if (!data.length) {
    return null
  }

  const enrichRequest = []
  for (let index = 0; index < data.length; index++) {
    const fetch = async () => {
      const dataItem = data[index]
      const response = await request(dataItem)
      dataItem[property] = response
    }

    enrichRequest.push(fetch())
  }

  await forkjoinRequest(enrichRequest)
  return data
}

export function transformHunnyRequest<S, T>(req: HunnyRequest<S>, mapFn: (value: S) => T): HunnyRequest<T> {
  const call = async () => {
    const result = await req.call()
    return mapFn(result)
  }

  return {
    call,
    cancel: req.cancel,
  }
}
