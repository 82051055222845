import { BaseResponse, HUSDClaimedTransaction, Paging } from 'services/types'

export const LockBalanceMapper = (rawResponse: string): BaseResponse<string> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && response.data.items[0]?.amount,
  }
}

export const ClaimableBalanceMapper = (rawResponse: string): BaseResponse<{ amount: string; wager: string }> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }
  return {
    code: response.code,
    message: response.message,
    data: response.data && { amount: response.data.amount, wager: response.data.after_wager },
  }
}

export const ClaimBonusMapper = (rawResponse: string): BaseResponse<boolean> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.code === 'success',
  }
}

export const SwapBonusMapper = (rawResponse: string): BaseResponse<boolean> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.code === 'success',
  }
}

export const UnlockFormulaMapper = (rawResponse: string): BaseResponse<{ houseEdge: number; const: number }> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      const: Number(response.data.const),
      houseEdge: Number(response.data.house_edge) / 100,
    },
  }
}

export const HUSDClaimedAmountMapper = (rawResponse: string): BaseResponse<string> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && response.data.amount,
  }
}

export const HUSDClaimedHistoryMapper = (rawResponse: string): BaseResponse<Paging<HUSDClaimedTransaction>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: response.data.paging,
      items: response.data.items.map((item) => ({
        createdAt: item.create_time * 1000,
        amount: Math.abs(item.value),
        status: item.type,
      })),
    },
  }
}
