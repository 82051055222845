import { APIEndpointEnum } from 'config/constants/server'
import { MyTierInfo as MyTierInfoResponse } from 'config/types/profile'
import { HunnyPlayRequest } from './HunnyPlayRequest'
import { BaseResponse, HunnyRequest, Paging, UserDataResponse } from './types'

class ProfileService extends HunnyPlayRequest {
  public async getUserData(): Promise<BaseResponse<UserDataResponse>> {
    const result = await this._request(APIEndpointEnum.User)
    return result || null
  }

  public getTierInfo(): HunnyRequest<BaseResponse<MyTierInfoResponse>> {
    const result = this._post(APIEndpointEnum.MyTier)

    return result
  }

  public getFavoriteGames(filter: { query?: string }, offset: number, limit: number) {
    const { query } = filter
    const params = { query, paging: { offset, limit } }

    const result = this._post(APIEndpointEnum.UserFavoriteGames, params)
    return result
  }

  public getFavoriteGameCodes(): HunnyRequest<BaseResponse<Paging<string>>> {
    const result = this._post(APIEndpointEnum.UserFavoriteGameCodes, { paging: { offset: 0, limit: 300 } })

    return result
  }

  public addFavoriteGame(gameCode: string): Promise<BaseResponse<any>> {
    const result = this._request(APIEndpointEnum.AddFavoriteGame, { game_code: gameCode })
    return result
  }

  public removeFavoriteGame(gameCode: string): Promise<BaseResponse<any>> {
    const result = this._request(APIEndpointEnum.RemoveFavoriteGame, { game_code: gameCode })
    return result
  }
}

const instance = new ProfileService()
export default instance
