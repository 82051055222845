import theme from 'theme'
import { breakpointMap, breakpointNumberList } from 'theme/base'
import { HunnyTheme } from 'theme/types'

export const buildResponsiveStyle = (theme: HunnyTheme, cssProperty: string, value: string | string[]) => {
  if (!Array.isArray(value)) return `${cssProperty}: ${value};`

  const mediaQueries = Object.values(theme.mediaQueries)

  return value.reduce((preValue, item, index) => {
    if (index === 0) {
      // xs
      return `${cssProperty}: ${item};`
    }

    if (!mediaQueries[index]) return preValue

    return `
    ${preValue}
    ${mediaQueries[index]} {
      ${cssProperty}: ${item};
    }`
  }, '')
}

export const buildResponsiveStyledSystemWithExceptedComponent = (
  responsive: any[],
  exceptedWidth: number,
  exceptedBreakPoint?: number,
  _breakpointMap?: number[],
) => {
  const windowWidth = typeof window != 'undefined' ? window.document.body.clientWidth : 0
  const breakpointMap = _breakpointMap || breakpointNumberList
  const remainedWidth = windowWidth - exceptedWidth

  const exceptedBreakPointIndex = breakpointMap.findIndex((item) => item >= exceptedBreakPoint)

  return breakpointMap.reduce((preValue, item, index) => {
    if (exceptedBreakPoint && index <= exceptedBreakPointIndex) {
      preValue.push(responsive[index])
    } else if (remainedWidth > item && responsive[index] !== undefined) preValue.push(responsive[index])
    return preValue
  }, [])
}

export const buildResponsiveStyledSystemWithExceptedSidebar = (responsive: any[], isSidebarOpen: boolean) => {
  if (!isSidebarOpen) return responsive

  return buildResponsiveStyledSystemWithExceptedComponent(
    responsive,
    isSidebarOpen ? theme.sidebarWidth : 0,
    breakpointMap.sm,
  )
}

export const detectResponsiveBreakpoint = (responsive: any[]) => {
  const windowWidth = typeof window != 'undefined' ? window.document.body.clientWidth : 0
  const breakPointIndex = breakpointNumberList.findIndex((item) => item >= windowWidth)

  return responsive[breakPointIndex] || responsive[responsive.length - 1]
}
