import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state'
import { removeSuggestedLoginInfo } from 'state/auth/action'

const useSuggestedLogin = () => {
  const previousUserEmail = useAppSelector((state) => state.auth.email)
  const previousLoginBy = useAppSelector((state) => state.auth.loginBy)
  const dispatch = useAppDispatch()

  const clearSuggessLoginInfo = useCallback(() => {
    dispatch(removeSuggestedLoginInfo())
  }, [])

  return useMemo(
    () => ({
      previousUserEmail,
      previousLoginBy,
      clearSuggessLoginInfo,
    }),
    [previousUserEmail, previousLoginBy, clearSuggessLoginInfo],
  )
}

export default useSuggestedLogin
