import { useRef, useEffect } from 'react'

const useCallbackValueOnDestroy = (value: any, callback: (value: any) => void) => {
  const valueRef = useRef(value)

  useEffect(() => {
    valueRef.current = value
  }, [value])

  useEffect(() => {
    return () => {
      callback(valueRef.current)
    }
  }, [])
}

export default useCallbackValueOnDestroy
