import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Select from 'components/Select'
import Text from 'components/Text'
import { HusdUnlockTxnStatusEnums } from 'config/constants/transactions'
import { Trans } from 'react-i18next'
import theme from 'theme'

interface SelectHusdHistoryStatusProps {
  value: HusdUnlockTxnStatusEnums
  onFilterChanged: (value: HusdUnlockTxnStatusEnums) => void
}

const SelectHusdHistoryStatus: React.FC<SelectHusdHistoryStatusProps> = ({ value, onFilterChanged }) => {
  return (
    <Flex alignItems="center" mb={24}>
      <Text fontSize="12px" color="textSubtle">
        <Trans>Filtered By</Trans>:
      </Text>

      <Box
        width={100}
        height={22}
        ml={2}
        background={theme.colors.buttonBackgroundAlt2}
        borderRadius={theme.radii.large}
        pl={1}
      >
        <Select
          options={[HusdUnlockTxnStatusEnums.All, HusdUnlockTxnStatusEnums.Received, HusdUnlockTxnStatusEnums.Claimed]}
          onSelect={onFilterChanged}
          OptionItemComponent={FilterOptionItem}
          dropdownContentProps={{
            style: {
              background: theme.colors.buttonBackgroundAlt2,
            },
          }}
        >
          <Text fontSize="12px" color="textTertiary" ml={2}>
            <Trans>{HusdUnlockTxnStatusEnums[value]}</Trans>
          </Text>
        </Select>
      </Box>
    </Flex>
  )
}

const FilterOptionItem = ({ item }) => {
  return (
    <Text fontSize="12px" color="textTertiary" textAlign="left">
      <Trans>{HusdUnlockTxnStatusEnums[item]}</Trans>
    </Text>
  )
}

export default SelectHusdHistoryStatus
