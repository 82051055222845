import Box from 'components/Box/Box'
import { BoxProps } from 'components/Box/types'
import { Checkbox } from 'components/Checkbox'
import { InputProps } from 'components/Input'
import React from 'react'

const FormCheckbox: React.FC<{ control: InputProps } & BoxProps> = ({ control, ...props }) => {
  return (
    <Box {...props}>
      <Checkbox {...control} height="100%" width="100%" />
    </Box>
  )
}

export default FormCheckbox
