import BigNumber from 'bignumber.js'
import { WelcomePackItemType } from 'config/types/bonus/welcomePackage'
import { range } from 'utils'

export const handleFetchSelectedReward = (prizes: { id: number; percent?: number; isDefault?: boolean }[]) => {
  const resultIds = prizes.reduce((result, value) => {
    return [...result, ...(value.percent ? range(0, value.percent).map((_) => value.id) : [])]
  }, [])
  const randomRewardIndex = Math.floor(Math.random() * resultIds.length)
  const randomRewardId = resultIds[randomRewardIndex]

  if (randomRewardId) return prizes.findIndex((prize) => prize.id === randomRewardId)

  return prizes.findIndex((prize) => prize.isDefault)
}

export const getWelcomePackageFreeSpinAmount = (welcomePackage: WelcomePackItemType) => {
  // TODO Waiting feature integration
  return welcomePackage.code === 'bronze' ? 600 : welcomePackage.code === 'gold' ? 1000 : 800

  // return packageInfo?.bonuses.reduce((freeSpinAmount, bonus) => {
  //   if (bonus instanceof FreeSpinCommonBonus) return freeSpinAmount + bonus.freeSpinAmount
  //   return freeSpinAmount
  // }, 0)
}

export const getWelcomePackageStats = (welcomePackage: WelcomePackItemType) => {
  let minDepositCondition = Number.MAX_SAFE_INTEGER

  welcomePackage?.bonuses.reduce((maxReceiveBonus, bonus: any) => {
    if (minDepositCondition > Number(bonus.minDeposit || 0)) minDepositCondition = Number(bonus.minDeposit || 0)
    return maxReceiveBonus + Number(bonus.maxBonusAmountInUsd || 0)
  }, 0)

  return {
    minDepositCondition: new BigNumber(minDepositCondition),
    // TODO Waiting feature integration
    // maxReceiveBonus: new BigNumber(maxReceiveBonus),
    maxReceiveBonus: new BigNumber(
      welcomePackage.code === 'bronze' ? 500 : welcomePackage.code === 'gold' ? 3500 : 1500,
    ),
  }
}
