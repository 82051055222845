import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Button from 'components/Button'
import Image from 'components/Image'
import Modal, { ModalProps } from 'components/Modal'
import OpenEffect from 'components/OpenEffect'
import Text from 'components/Text'
import TokenLogo from 'components/TokenLogo'
import configtokens from 'config/constants/tokens'
import { PRODUCT_NAME } from 'config/constants/variables'
import { Token } from 'config/types'
import { SpinPrizeInfo } from 'config/types/luckyspin'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import theme from 'theme'

interface PrizeDetailModalProps {
  selectedPrize: SpinPrizeInfo
  onModalClosed: (isReceivedLockedHusd: boolean) => void
}

const PrizeDetailModal: React.FC<ModalProps<PrizeDetailModalProps>> = ({
  onDismiss,
  data: { selectedPrize, onModalClosed },
}) => {
  const token: Token = configtokens[selectedPrize.network][selectedPrize.currency]
  const isLockedHusd = token.code === 'HUSD'

  if (token)
    return (
      <StyledModal onDismiss={onDismiss}>
        <OpenEffect openType="zoom">
          <StyledContainer
            borderRadius={theme.radii.extraLarge}
            width={['100%', '100%', '100%', 800]}
            height={['100%', '100%', 'fit-content']}
            p={[20, 20, 0]}
          >
            <StyledPrizeBackground src={'/images/luckyspin/prize-detail-bg.png'} width={800} height={585} />
            <Box
              position="absolute"
              zIndex={-1}
              top="76px"
              minWidth="420px"
              left="50%"
              style={{ transform: 'translateX(-50%)' }}
            >
              <Image src={'/images/luckyspin/prize-detail-bg-mobile.png'} width={600} height={279} />
            </Box>

            <Flex
              flexDirection="column"
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
              position={['static', 'static', 'absolute']}
              top={0}
            >
              <StyledContentContainer width="100%" mt="24px">
                <Text fontSize={'26px'} display={['block', 'block', 'none']} bold textAlign="center" mt={2}>
                  <Trans>YOU WIN</Trans>
                </Text>
                <Flex alignItems="center" width="100%" justifyContent="center" mt={[0, 0, 14]} mb={[3, 3, 0]}>
                  <TokenLogo token={token} tokenImageProps={{ width: 60, height: 60 }} />
                  <Flex position="relative" alignItems="center" ml={3}>
                    <Text fontSize={['14px', '14px', '26px', '32px', '32px']} bold textTransform="uppercase">
                      {selectedPrize.amount} {token.name}
                    </Text>
                  </Flex>
                </Flex>
              </StyledContentContainer>

              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                position={['static', 'static', 'absolute']}
                bottom={20}
                width="100%"
              >
                <Box width="70%" mx="auto">
                  <Text textAlign="center" fontSize={['12px', '12px', '14px']}>
                    <Trans>Let's play games on {{ productName: PRODUCT_NAME }} and enjoy winning more.</Trans>
                  </Text>
                </Box>

                <Button
                  variant="gradient"
                  onClick={() => {
                    onModalClosed(isLockedHusd)
                    onDismiss()
                  }}
                  mt={3}
                >
                  <Text fontSize="16px" bold>
                    <Trans>{isLockedHusd ? 'View My HUSD' : 'Play Now'}</Trans>
                  </Text>
                </Button>

                <Button
                  mt="12px"
                  onClick={onDismiss}
                  variant="text"
                  height="16px"
                  style={{
                    textDecoration: 'underline',
                  }}
                >
                  <Text small>
                    <Trans>Back to spin</Trans>
                  </Text>
                </Button>
              </Flex>
            </Flex>
          </StyledContainer>
        </OpenEffect>
      </StyledModal>
    )
}

const StyledModal = styled(Modal)`
  max-width: 800px;
  max-height: 585px;
  width: 100%;
  height: 100%;

  background-size: cover;
  background-repeat: no-repeat;

  overflow: unset;

  background: none;

  position: relative;
`

const StyledContainer = styled(Box)`
  background: transparent;
  backdrop-filter: blur(15px);

  position: relative;

  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
  }
`

const StyledContentContainer = styled(Box)`
  width: 100%;
  min-height: 270px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    background: none;

    min-height: unset;
    display: block;
  }
`

const StyledPrizeBackground = styled(Image)`
  display: none;

  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    display: block;
  }
`

export default PrizeDetailModal

