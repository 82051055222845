export enum APIEndpointEnum {
  Games = 'api/v1/game/list/',
  BonusGameCollection = 'api/v1/voucher/wager/game-collection/game/list/',
  WageringBonusGameCodes = 'api/v1/voucher/wager/game-code/list/',
  CreateGameSession = 'api/v1/game/session/create/',
  GameDetail = 'api/v1/game/get/',
  CreateGameSessionDemo = 'api/v1/game/demo/',

  SportGameInfo = 'api/v1/game/session/create/',

  BannerList = 'api/v1/homepage/banner/list/',
  HomeCategoryList = 'api/v1/menu/category/list/',
  ScreenTagList = 'api/v1/menu/tag/list/',
  GameCollectionList = 'api/v1/game/collection/list/',
  GameCollectionDetail = 'api/v1/game/collection/get/',
  ProviderList = 'api/v1/game/provider/list/',
  Provider = 'api/v1/game/provider/get/',
  TopWinList = 'api/v1/top/win/list/',

  UserFavoriteGameCodes = 'api/v1/user/favorite/list/',
  UserFavoriteGames = 'api/v1/user/favorite/game/list/',
  AddFavoriteGame = 'api/v1/user/favorite/game/add/',
  RemoveFavoriteGame = 'api/v1/user/favorite/game/remove/',

  MerchantBetby = 'api/v1/merchant/betby/game/jwt/gen/',

  Meta = 'api/v1/meta/get/',
  User = 'api/v1/user/get/',

  DepositBonus = 'api/v1/promotion/user/deposit/info/get/',
  TokenUsdPrices = 'api/v1/currency/list/',

  MyTier = 'api/v1/user/tier/get/',

  PromotionList = 'api/v1/promotion/content/list/',
  PromotionDetail = 'api/v1/promotion/content/get/',

  Posts = 'api/content/posts/',
  Tags = 'api/content/tags/',
  TagDetails = 'api/content/tags/slug/{{slug}}/',
  PostDetails = 'api/content/posts/slug/{{slug}}/',

  PrivateSocket = '/api/v1/socket.io/',
  PublicSocket = '/api/v1/socket.io/public',

  CashbackMetadata = '/api/v1/cashback/meta/get/',
  CashbackEndDate = '/api/v1/cashback/timeline/get/',
  CashbackTransaction = '/api/v1/cashback/list/',
  CashbackTotalEarned = '/api/v1/cashback/sum/',
  CashbackClaim = '/api/v1/cashback/claim/',

  WalletLoginPrepare = 'api/v1/auth/login/prepare/',
  SignInByWallet = 'api/v1/auth/login/web3/execute/',
  Register = 'api/v1/user/register/execute/',
  Refresh = 'api/v1/auth/refresh/',
  SignUpPrepare = 'api/v1/user/register/prepare/',
  VerifyRegisterUser = 'api/v1/user/register/verification/request/',
  VerifyResetPassword = 'api/v1/auth/login/input/password/reset/prepare/',
  ValidateResetPassword = 'api/v1/auth/login/input/password/reset/validate/',
  SubmitResetPassword = 'api/v1/auth/login/input/password/reset/execute/',
  PrepareTraditionalLogin = 'api/v1/auth/login/input/prepare/',
  SignInByEmail = 'api/v1/auth/login/input/execute/',

  Balances = 'api/v1/payment/balance/list/',

  TraditionalDeposit = 'api/v1/payment/deposit/transfer/prepare/',
  WithdrawFee = 'api/v1/payment/withdrawal/fee/get/',
  PrepareTraditionalWithdraw = '/api/v1/payment/withdrawal/traditional/prepare/',
  PrepareWalletWithdraw = '/api/v1/payment/withdrawal/prepare/',
  TraditionalWithdraw = '/api/v1/payment/withdrawal/traditional/submit/',
  WalletWithdraw = '/api/v1/payment/withdrawal/submit/',
  WithdrawalTransaction = '/api/v1/payment/withdrawal/get/',
  WithdrawalTransactions = '/api/v1/payment/withdrawal/list/',

  ReferralOverview = '/api/v1/referral/overview/get/',
  ReferralListCampaign = '/api/v1/referral/campaign/list/',
  ReferralDefaultCampaign = '/api/v1/referral/campaign/default/get/',
  ReferralCampaignDetails = '/api/v1/referral/campaign/get/',
  ReferralAvailableBalanceClaim = '/api/v1/referral/balance/list/',
  ReferralTotalEarned = '/api/v1/referral/balance/claim/sum/',
  ReferralPrepareClaim = 'api/v1/referral/balance/claim/prepare/',
  ReferralClaimBalance = 'api/v1/referral/balance/claim/',
  ReferralCreateCampaign = 'api/v1/referral/campaign/create/',
  ReferralSetDefaultCampaign = 'api/v1/referral/campaign/default/set/',
  ReferralUpdateCampaign = 'api/v1/referral/campaign/update/',
  RefereeList = 'api/v1/referral/referee/list/',
  DepositTransaction = 'api/v1/payment/deposit/get/hash/',
  DepositTransactions = 'api/v1/payment/deposit/list/',

  RewardTransactions = 'api/v1/reward/list/',
  BonusTransactions = '/api/v1/tier/bonus/list/',
  SwapTransactions = '/api/v1/bonus/balance/convert/list/',

  SubscribeDeposit = '/api/v1/payment/deposit/address/watcher/submit/',

  StakingPoolStats = 'api/v1/staking/stats/get/',
  StakingMyPoolStats = 'api/v1/staking/balance/list/',
  StakingExecuteStake = 'api/v1/staking/balance/stake/',
  StakingExecuteUnstake = 'api/v1/staking/balance/un-stake/',
  StakingAvailableClaim = 'api/v1/staking/reward/claimable/list/',
  StakingTotalEarned = 'api/v1/staking/reward/claim/total/list/',
  StakingTransaction = 'api/v1/staking/balance/txn/list/',
  StakingExecuteClaim = 'api/v1/staking/reward/claim/',
  HUSDLockBalance = '/api/v1/bonus/balance/list/',
  HUSDClaimableBalance = '/api/v1/bonus/balance/unlock/prepare/',
  ClaimHUSD = '/api/v1/bonus/balance/unlock/execute/',
  UnlockFormula = '/api/v1/bonus/balance/unlock/formula/get/',
  TotalHUSDClaimed = '/api/v1/bonus/balance/unlock/sum/',
  HUSDTxnList = '/api/v1/bonus/balance/txn/list/',
  SwapHUSD = '/api/v1/bonus/balance/convert/execute/',
  PrepareSwapData = '/api/v1/bonus/balance/convert/prepare/',

  SpinPrizeTransactions = '/api/v1/spin/txn/list/',
  SpinMyPrizeTransactions = '/api/v1/spin/txn/info/',
  SpinExternalPrizeList = '/api/v1/spin/get/external/',
  SpinInternalPrizeList = '/api/v1/spin/get/',
  SpinPlay = '/api/v1/spin/play/',
  SpinFreePlayTimes = '/api/v1/spin/balance/',

  SubscribeDepositByHash = '/api/v1/payment/deposit/watch/hash/submit/',
  QueryDepositByHash = '/api/v1/payment/deposit/watch/hash/get/',

  GetSystemNotifications = '/api/v1/notification/platform/list/',
  GetPrivateNotifications = '/api/v1/notification/personal/list/',
  MarkAsReadPrivateNotification = '/api/v1/notification/personal/mark-as-read/',
  MarkAllAsReadPrivateNotification = '/api/v1/notification/personal/mark-all-as-read/',
  MarkAsReadSystemNotification = '/api/v1/notification/platform/mark-as-read/',
  MarkAllAsReadSystemNotification = '/api/v1/notification/platform/mark-all-as-read/',

  BonusRedeem = '/api/v1/voucher/redeem/',
  FreespinBonusRegister = '/api/v1/user/voucher/free-round/register/',
  NoDepositBonusActive = '/api/v1/user/voucher/bonus-lock/add/',
  ActivatedBonuses = '/api/v1/user/voucher/active/list/',
  UserBonuses = '/api/v1/user/voucher/list/',
  BonusApply = '/api/v1/user/voucher/apply/',
  BonusCancel = '/api/v1/user/voucher/cancel/',
  BonusDetails = '/api/v1/voucher/get/',
  UserBonusDetails = '/api/v1/user/voucher/get/',
  BonusPrepareDeposit = '/api/v1/user/voucher/deposit/prepare/',
  ReselectWageringBonus = '/api/v1/user/voucher/wager/reselect/',
  MetaTag = '/api/v1/page/meta/tag/get/',
  WelcomePackage = '/api/v1/promotion/welcome/package/get/',
  UserWelcomePackageInfo = '/api/v1/promotion/welcome/package/user/stats/',
  UserChooseWelcomePackage = '/api/v1/promotion/welcome/package/choose/',
}

export const BASE_API = process.env.NEXT_PUBLIC_APP_API
export const BASE_GHOST_API = process.env.NEXT_PUBLIC_GHOST_API
export const GHOST_API_KEY = process.env.NEXT_PUBLIC_GHOST_API_KEY
export const SERVER_JSON = process.env.NEXT_PUBLIC_SERVER_JSON

export const BRAND_ID_BETBY = process.env.NEXT_PUBLIC_BRAND_ID_BETBY
export const GAME_CODE_BETBY = process.env.NEXT_PUBLIC_GAME_CODE_BETBY
export const URL_BETBY = process.env.NEXT_PUBLIC_URL_BETBY

export const CRISP_ID = process.env.NEXT_PUBLIC_CRISP_ID || '53c1e663-aab8-4ce4-b172-155328f494cf'

export const EXCLUDE_API_ERROR = ['error_auth', 'error_data_not_found', 'error_country_banned']
