import BigNumber from 'bignumber.js'
import DepositedActiveBonusModal from 'components/DepositedActiveBonusModal'
import ErrorModal from 'components/ErrorModal'
import FreespinBonusModal from 'components/FreespinBonusModal'
import { BIG_ZERO } from 'config/constants/number'
import { RouteConfig } from 'config/constants/route'
import { BonusStatusFilterEnums } from 'config/types/bonus'
import {
  ApiBonusStatusEnums,
  BonusStatusEnums,
  BonusTagEnums,
  FreeHUSDLockBonus,
  FreeLuckyspinBonus,
  HUSDUnLockBoosterBonus,
  LevelUpBonus,
  NoDepositBonus,
  UserBonus,
  WageringBonusStatusEnums,
  WageringConditionBonus,
} from 'config/types/bonus/userBonus'
import { UserWelcomePack } from 'config/types/bonus/welcomePackage'
import { WalletType } from 'config/types/wallet'
import { useOpenCrisp } from 'hooks/useCrisp'
import useModal from 'hooks/useModal'
import { usePrivateSocket } from 'hooks/usePrivateSocket'
import { useRequest } from 'hooks/useRequest'
import { useRouter } from 'hooks/useRouter'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BonusService from 'services/BonusService'
import { useAppDispatch, useAppSelector } from 'state'
import { useAuth } from 'state/auth/hooks'
import { useUpdateUserLuckySpin } from 'state/profile/hooks'
import { buildPluralizeText, delayed } from 'utils'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { isSolChain } from 'utils/network'
import { forkjoinRequest } from 'utils/requestHelper'
import { HunnyToast } from 'utils/toastify'
import { useFreespinGamesModal } from 'views/BonusCenter/hooks'
import Payment from 'views/Payment'
import {
  updateActivatedBonuses,
  updateBonusToActiveWagering,
  updateCurrentWagerInActivatedBonuses,
  updateUserClaimableHUSDAmount,
  updateUserHUSDBonus,
  updateUserUnlockableHUSDAmount,
} from './actions'

export const useHUSDLockBonus = (pollingData?: boolean) => {
  const { isSigned } = useAuth()
  const { fetchHUSDClaimableBalance } = useUpdateHUSDBonus()
  const { claimableBalance, extraClaimableBalance, unlockableBalance } = useAppSelector(
    (state) => state.bonus.HUSDBonus,
  )
  useEffect(() => {
    if (!isSigned) return
    fetchHUSDClaimableBalance()
  }, [isSigned])

  useEffect(() => {
    if (!isSigned || !pollingData) return

    const interval = setInterval(fetchHUSDClaimableBalance, 30000)

    return () => {
      clearInterval(interval)
    }
  }, [isSigned, pollingData])

  return useMemo(() => {
    return {
      claimableBalance: new BigNumber(claimableBalance),
      extraClaimableBalance: new BigNumber(extraClaimableBalance),
      unlockableBalance: new BigNumber(unlockableBalance),
    }
  }, [claimableBalance, extraClaimableBalance, unlockableBalance])
}

export const useHUSDLockBalance = () => {
  const { unlockableBalance } = useAppSelector((state) => state.bonus.HUSDBonus)

  return useMemo(() => {
    return new BigNumber(unlockableBalance)
  }, [unlockableBalance])
}

export const useUpdateHUSDBonus = () => {
  const { execute } = useRequest()
  const dispatch = useAppDispatch()

  const fetchHUSDBonusBalance = useCallback(async () => {
    const [claimBalanceRes, lockBalanceRes, bonusBonusRes] = await forkjoinRequest([
      execute(BonusService.getHUSDClaimableAmount()),
      execute(BonusService.getHUSDLockAmount()),
      execute(BonusService.getExtraHUSDBonusAmount()),
    ])

    const extraClaimableHUSD = new BigNumber(bonusBonusRes || 0)
    const claimableHUSD = new BigNumber(claimBalanceRes?.data?.amount || BIG_ZERO)
    const unlockableHUSD = new BigNumber(lockBalanceRes?.data || BIG_ZERO)

    dispatch(
      updateUserHUSDBonus({
        claimableBalance: claimableHUSD.toString(10),
        unlockableBalance: unlockableHUSD.toString(10),
        extraClaimableBalance: extraClaimableHUSD.toString(10),
      }),
    )
  }, [])

  const fetchHUSDClaimableBalance = useCallback(async () => {
    const [claimBalanceRes, bonusBonusRes] = await forkjoinRequest([
      execute(BonusService.getHUSDClaimableAmount()),
      execute(BonusService.getExtraHUSDBonusAmount()),
    ])

    const extraClaimableHUSD = new BigNumber(bonusBonusRes || 0)
    const claimableHUSD = new BigNumber(claimBalanceRes?.data?.amount || BIG_ZERO)

    dispatch(
      updateUserClaimableHUSDAmount({
        claimableBalance: claimableHUSD.toString(10),
        extraClaimableBalance: extraClaimableHUSD.toString(10),
      }),
    )
  }, [])

  const fetchHUSDUnlockableBalance = useCallback(async () => {
    const lockBalanceRes = await execute(BonusService.getHUSDLockAmount())

    const unlockableHUSD = new BigNumber(lockBalanceRes?.data || BIG_ZERO)

    dispatch(
      updateUserUnlockableHUSDAmount({
        unlockableBalance: unlockableHUSD.toString(10),
      }),
    )
  }, [])

  return { fetchHUSDBonusBalance, fetchHUSDClaimableBalance, fetchHUSDUnlockableBalance }
}

export const useActivatedBonuses = () => {
  const dispatch = useAppDispatch()
  const activatedBonuses = useAppSelector((state) => state.bonus.activatedBonuses)

  const fetchActivatedBonuses = useCallback(async () => {
    const result = await BonusService.getUserBonuses(BonusStatusFilterEnums.Active, 0, 100).call()
    if (result?.data?.items) {
      dispatch(
        updateActivatedBonuses({
          activatedBonuses: result?.data?.items,
        }),
      )
    }
  }, [dispatch])

  return { activatedBonuses: activatedBonuses || [], fetchActivatedBonuses }
}

export const useMenuDisplayActivatedBonuses = () => {
  const activatedBonuses = useAppSelector((state) => state.bonus.activatedBonuses)

  const choseBonuses = activatedBonuses.filter(
    (bonus) => bonus instanceof LevelUpBonus || bonus instanceof HUSDUnLockBoosterBonus,
  )

  return choseBonuses || []
}

export const useActivatedBonusesUpdater = () => {
  const { isSigned } = useAuth()
  const { activatedBonuses, fetchActivatedBonuses } = useActivatedBonuses()
  const activeBonusInWageringProcess = useActiveBonusInWageringProcess()
  const socket = usePrivateSocket()
  const dispatch = useAppDispatch()

  const [presentDepositedActiveBonusModal] = useModal(DepositedActiveBonusModal)
  const [presentFreespinBonusModal] = useModal(FreespinBonusModal)

  useEffect(() => {
    if (!activatedBonuses.length) return
    const timeouts = activatedBonuses
      .map((item) => {
        const maxTimeSetTimeOutAllowance = 2147483647
        let timeLeft = item.condition.expiryDate - Date.now()
        if (timeLeft < 0) return null
        if (timeLeft > maxTimeSetTimeOutAllowance) timeLeft = maxTimeSetTimeOutAllowance

        return setTimeout(() => {
          fetchActivatedBonuses()
        }, timeLeft)
      })
      .filter((item) => item)

    return () => {
      timeouts.forEach((timeout) => clearTimeout(timeout))
    }
  }, [activatedBonuses])

  useEffect(() => {
    if (!isSigned || !activeBonusInWageringProcess || !socket) {
      return
    }

    socket.on('voucher.bet.collection', (data) => {
      dispatch(updateCurrentWagerInActivatedBonuses({ currentWager: data.current_wager_usd, id: data.voucher_user_id }))
    })

    return () => {
      if (socket) {
        socket.off('voucher.bet.collection')
      }
    }
  }, [isSigned, socket, !!activeBonusInWageringProcess])

  useEffect(() => {
    if (!isSigned || !socket) {
      return
    }

    socket.on('voucher.freeround.close', async (data) => {
      const res = await BonusService.getUserBonusDetails(data.voucher_user_id).call()
      const bonus = res.data as WageringConditionBonus

      if (!bonus) return
      fetchActivatedBonuses()
      presentFreespinBonusModal({ bonus, onSubmitted: () => {} })
    })

    return () => {
      if (socket) {
        socket.off('voucher.freeround.close')
      }
    }
  }, [isSigned, socket])

  useEffect(() => {
    const wageringBonus = activatedBonuses.find(
      (bonus) =>
        bonus instanceof WageringConditionBonus &&
        bonus.wageringBonusStatus === WageringBonusStatusEnums.InProgressWagering,
    )

    if (!activeBonusInWageringProcess && wageringBonus) {
      const submit = async () => {
        const bonusId = await BonusService.reselectWageringBonus()
        if (bonusId) {
          dispatch(updateBonusToActiveWagering({ id: bonusId }))
        }
      }

      submit()
    }
  }, [activatedBonuses, !!activeBonusInWageringProcess])

  useEffect(() => {
    const waitRegisterBonuses = activatedBonuses.filter(
      (bonus) => bonus instanceof WageringConditionBonus && bonus.apiStatus === ApiBonusStatusEnums.WaitFRBResister,
    )

    if (waitRegisterBonuses.length > 0) {
      const register = async () => {
        await forkjoinRequest(waitRegisterBonuses.map((bonus) => BonusService.registerFreespinBonus(bonus.id)))
        fetchActivatedBonuses()
      }

      register()
    }
  }, [activatedBonuses])

  useEffect(() => {
    if (!isSigned || !socket) {
      return
    }

    socket.on('voucher.deposit.confirmed', async (data) => {
      const res = await BonusService.getUserBonusDetails(data.voucher_user_id).call()
      const bonus = res.data
      if (!bonus) return
      if (bonus.apiStatus === ApiBonusStatusEnums.WaitFRBResister) {
        await BonusService.registerFreespinBonus(bonus.id)
      }

      fetchActivatedBonuses()
      presentDepositedActiveBonusModal({ bonus })
    })

    return () => {
      if (socket) {
        socket.off('voucher.deposit.confirmed')
      }
    }
  }, [isSigned, socket])
}

export const useActiveLevelUpBonus = (): LevelUpBonus => {
  const activatedBonuses = useAppSelector((state) => state.bonus.activatedBonuses || [])
  const userLevel = useAppSelector((state) => state.profile.tier?.level || 1)

  return useMemo(() => {
    const levelupBonus = activatedBonuses.find((bonus) => bonus instanceof LevelUpBonus) as LevelUpBonus

    if (levelupBonus) {
      if (userLevel > levelupBonus.maxLevels) {
        return new LevelUpBonus(levelupBonus, 0, levelupBonus.maxLevels, levelupBonus.duration)
      }
      if (userLevel + levelupBonus.extraLevels > levelupBonus.maxLevels) {
        return new LevelUpBonus(
          levelupBonus,
          levelupBonus.maxLevels - userLevel,
          levelupBonus.maxLevels,
          levelupBonus.duration,
        )
      }

      return levelupBonus
    }
    return null
  }, [activatedBonuses, userLevel])
}

export const useActiveHUSDBoosterBonus = (): HUSDUnLockBoosterBonus => {
  const activatedBonuses = useAppSelector((state) => state.bonus.activatedBonuses)

  return useMemo(
    () => activatedBonuses.find((bonus) => bonus instanceof HUSDUnLockBoosterBonus),
    [activatedBonuses],
  ) as HUSDUnLockBoosterBonus
}

export const useExtraHUSDUnlockBonus = () => {
  const { isSigned } = useAuth()
  const { execute } = useRequest()
  const [amount, setAmount] = useState(null)

  const fetchAmount = useCallback(async () => {
    const result = await execute(BonusService.getExtraHUSDBonusAmount())

    if (result) {
      setAmount(result)
    }
  }, [])

  useEffect(() => {
    if (!isSigned) return
    fetchAmount()
  }, [isSigned])

  return { amount, fetchAmount }
}

export enum BonusActionTypeEnums {
  Deposit = 'deposit',
  Active = 'active',
  Register = 'resgister',
  Use = 'use',
  Spin = 'spin',
  Play = 'play',
  Claim = 'claim',
  AddBonusLock = 'add',
}

export const useBonusAction = (bonus: UserBonus) => {
  const { fetchActivatedBonuses } = useActivatedBonuses()
  const { fetchHUSDUnlockableBalance } = useUpdateHUSDBonus()
  const [presentPaymentModal] = useModal(Payment)
  const [presentErrorModal] = useModal(ErrorModal)
  const [presentFreespinGamesModal] = useFreespinGamesModal(bonus)

  // const onOpenSupportChatBox = useOpenCrisp()

  const wallet = useAppSelector((state) => state.auth.wallet)

  const router = useRouter()
  const { isSigned } = useAuth()
  const update = useUpdateUserLuckySpin()
  const [submitting, setSubmiting] = useState(false)
  const { t } = useTranslation()

  const actionType = useMemo(() => {
    if (!bonus) return null
    if (bonus.status === BonusStatusEnums.Available) {
      if (bonus instanceof WageringConditionBonus) {
        if (bonus.wageringBonusStatus === WageringBonusStatusEnums.WaitingDeposit) {
          return BonusActionTypeEnums.Deposit
        }

        if (bonus instanceof NoDepositBonus) {
          return BonusActionTypeEnums.AddBonusLock
        }

        if (bonus.wageringBonusStatus === WageringBonusStatusEnums.Available) {
          if (bonus.apiStatus === ApiBonusStatusEnums.WaitFRBResister) return BonusActionTypeEnums.Register
        }
      }

      if (bonus.tags.includes(BonusTagEnums.CanActive)) {
        return BonusActionTypeEnums.Active
      }

      return BonusActionTypeEnums.Use
    }

    if (bonus.status === BonusStatusEnums.Active) {
      if (bonus instanceof WageringConditionBonus) {
        if (bonus.wageringBonusStatus === WageringBonusStatusEnums.WaitingDeposit) {
          return BonusActionTypeEnums.Deposit
        }

        if (bonus.wageringBonusStatus === WageringBonusStatusEnums.WaitingSpin) {
          return BonusActionTypeEnums.Spin
        }

        if (bonus.wageringBonusStatus === WageringBonusStatusEnums.InProgressWagering) {
          if (bonus.progress >= 100) {
            return BonusActionTypeEnums.Claim
          }
          return BonusActionTypeEnums.Play
        }
      }
    }

    return null
  }, [bonus?.status, (bonus as any)?.wageringBonusStatus, bonus])

  const actionContent = useMemo(() => {
    switch (actionType) {
      case BonusActionTypeEnums.Active:
        return 'Active now'
      case BonusActionTypeEnums.Register:
        return 'Active now'
      case BonusActionTypeEnums.AddBonusLock:
        return 'Active now'
      case BonusActionTypeEnums.Deposit:
        return 'Deposit now'
      case BonusActionTypeEnums.Play:
        return 'Play games'
      case BonusActionTypeEnums.Spin:
        return 'Make spins'
      case BonusActionTypeEnums.Use:
        if (bonus instanceof FreeLuckyspinBonus) return 'Use spins'
        return 'Use now'
      case BonusActionTypeEnums.Claim:
        return 'Claim bonus'

      default:
        return null
    }
  }, [actionType])

  const submit = useCallback(async () => {
    if (!isSigned || !actionType) return

    switch (actionType) {
      case BonusActionTypeEnums.Register:
        setSubmiting(true)
        const isSucceed = await BonusService.registerFreespinBonus(bonus.id)
        if (isSucceed) {
          await fetchActivatedBonuses()
        }
        setSubmiting(false)
        return true

      case BonusActionTypeEnums.Deposit:
        setSubmiting(true)
        await BonusService.prepareDepositBonus(bonus.id)
        presentPaymentModal()
        setSubmiting(false)
        return true

      case BonusActionTypeEnums.Spin:
        presentFreespinGamesModal()
        break

      case BonusActionTypeEnums.Play:
        router.push({
          pathname: RouteConfig.Games,
          query: { gameCollection: (bonus as WageringConditionBonus).gameCollectionId },
        })
        return true

      case BonusActionTypeEnums.Claim:
        const isRewardSolanaToken = isSolChain((bonus as WageringConditionBonus).bonusAmount?.token?.network)
        const isSolanaUser = wallet?.type === WalletType.SOL
        if (!!isSolanaUser !== !!isRewardSolanaToken) {
          presentErrorModal({
            buttonContent: 'Contact Support',
            desc: 'Your wallet is not support to claim this bonus. Please contact our support team for alternative options.',
            buttonHandle: () => {
              // onOpenSupportChatBox()
            },
          })

          return false
        }

        setSubmiting(true)
        const res = await BonusService.apply(bonus.id)
        if (res.data) {
          // Wait API move claimed bonus to history list
          await delayed(1000)
          HunnyToast.success(
            t('Successful!'),
            t('{{amount}} {{token}} has been added to your balance', {
              amount: getFullDisplayBalance((bonus as WageringConditionBonus).bonusAmount.amount, 0, 6),
              token: (bonus as WageringConditionBonus).bonusAmount.token.code,
            }),
          )

          await fetchActivatedBonuses()
        }
        setSubmiting(false)
        return true

      case BonusActionTypeEnums.AddBonusLock:
        setSubmiting(true)
        const isAddSucceed = await BonusService.addBonusLock(bonus.id)
        if (isAddSucceed) {
          await fetchActivatedBonuses()
        }
        setSubmiting(false)
        return true

      default:
        setSubmiting(true)

        const [result] = await forkjoinRequest([BonusService.apply(bonus.id), delayed(700)])

        if (result.data) {
          if (bonus instanceof FreeLuckyspinBonus) {
            HunnyToast.success(
              t('Successful!'),
              t(
                buildPluralizeText('You have received {{amount}} lucky {{spin}}', [
                  {
                    number: bonus.amount,
                    key: 'spin',
                    word: ['spin', 'spins'],
                  },
                ]),
                { amount: bonus.amount },
              ),
            )
            await update()
          }

          if (bonus instanceof LevelUpBonus) {
            HunnyToast.success(
              t('Successful!'),
              t(
                buildPluralizeText('You have received {{extraLevels}} VIP {{level}} in {{time}} {{day}}', [
                  {
                    number: bonus.extraLevels,
                    key: 'level',
                    word: ['level', 'levels'],
                  },
                  {
                    number: bonus.duration,
                    key: 'day',
                    word: ['day', 'days'],
                  },
                ]),
                { extraLevels: bonus.extraLevels, time: bonus.duration },
              ),
            )
          }

          if (bonus instanceof FreeHUSDLockBonus) {
            fetchHUSDUnlockableBalance()
            HunnyToast.success(
              t('Successful!'),
              t('You have received {{amount}} locked HUSD', { amount: bonus.amount }),
            )
          }

          if (bonus instanceof HUSDUnLockBoosterBonus) {
            HunnyToast.success(
              t('Successful!'),
              t(
                buildPluralizeText('You have received {{percent}}% HUSD unlock booster in {{time}} {{day}}', [
                  {
                    number: bonus.duration,
                    key: 'day',
                    word: ['day', 'days'],
                  },
                ]),
                { percent: bonus.extraUnlockPercent, time: bonus.duration },
              ),
            )
          }

          await fetchActivatedBonuses()
          setSubmiting(false)
          return true
        }

        setSubmiting(false)
        HunnyToast.error(t('Failed!'), t("You don't meet the conditions of the promotion or the promotion has ended.!"))
        return false
    }
  }, [isSigned, bonus, actionType])

  return { submit, submitting, actionContent, actionType }
}

export const useActiveBonusInWageringProcess = (): WageringConditionBonus => {
  const activatedBonuses = useAppSelector((state) => state.bonus.activatedBonuses)

  return useMemo(() => {
    return activatedBonuses.find(
      (bonus) =>
        bonus instanceof WageringConditionBonus &&
        bonus.wageringBonusStatus === WageringBonusStatusEnums.InProgressWagering &&
        bonus.isActiveWagering,
    ) as WageringConditionBonus
  }, [activatedBonuses])
}

export const useAllWageringBonusesHaveAmount = (): WageringConditionBonus[] => {
  const activatedBonuses = useAppSelector((state) => state.bonus.activatedBonuses)

  return useMemo(() => {
    return activatedBonuses.filter(
      (bonus) => bonus instanceof WageringConditionBonus && bonus.bonusAmount?.amount.gt(0),
    ) as WageringConditionBonus[]
  }, [activatedBonuses])
}

export const useWelcomePackage = () => {
  const welcomePackage = useAppSelector((state) => state.app.welcomePackage)

  return { welcomePackage }
}

export const useUserWelcomePackage = (): UserWelcomePack => {
  const welcomePackage = useAppSelector((state) => state.app.welcomePackage)
  const welcomePackExpiredAt = useAppSelector((state) => state.profile.welcomePackExpiredAt)
  const isClaimed = useAppSelector((state) => state.profile.hasClaimedWelcomePackage)

  return useMemo(() => {
    return {
      ...welcomePackage,
      isClaimed,
      expiredAt: new Date(welcomePackExpiredAt),
    }
  }, [welcomePackage, welcomePackExpiredAt])
}

