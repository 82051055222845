export const targetBanner = {
  1: '_internal',
  2: '_blank',
}

export enum BannerTypeEnum {
  MainBanner = 1,
  SubBanner = 10,
  SmallBanner = 20,
}

export enum BannerTag {
  None = 1,
  WelcomePackage = 2,
  NonWelcomePackage = 3,
}
