export enum GamePlayMode {
  NORMAL = 10,
}

export class Game {
  code: string
  name: string
  providerCode: string
  thumbnail: string
  slug: string
  favoriteCount: number
}

export interface ResponsiveGameCollection {
  isFeature?: boolean
  itemCount?: number
  row?: number
  col?: number[] | number
  gap?: number[] | number
}

export interface GameSession {
  code: string
  gameUrl: string
}

export interface GameDetail extends Game {
  categoryId: string
  merchantCode: string
  type: number
}

export interface FeaturedGamesMap {
  wageringBonusGames: {
    [gameCode: string]: boolean
  }
  favoriteGames: {
    [gameCode: string]: boolean
  }
}
