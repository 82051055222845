import { WalletDisconnectionError, WalletNotReadyError } from '@solana/wallet-adapter-base'
import { useWallet as useSolWallet } from '@solana/wallet-adapter-react'
import { PRODUCT_NAME } from 'config/constants/variables'
import { WalletType } from 'config/types/wallet'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { logError } from 'utils/sentry'
import { HunnyToast } from 'utils/toastify'
import { ResourceUnavailableRpcError, UserRejectedRequestError } from 'viem'
import { ConnectorNotFoundError, useConnect, useDisconnect } from 'wagmi'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

export const useConnectWallet = (): {
  [type in WalletType]: {
    connect: (adapter: any) => Promise<any>
    disconnect: any
  }
} => {
  const { connectAsync: wagmiConnect, connectors } = useConnect()
  const { disconnectAsync: wagmiDisconnect } = useDisconnect()

  const { select, disconnect, connected } = useSolWallet()
  const { t } = useTranslation()

  const handleSolWalletConnect = async (adapter: any) => {
    try {
      select(adapter.name)
      return true
    } catch (e) {
      if (e instanceof WalletNotReadyError) {
        HunnyToast.error(t('Your wallet is not ready'), null, {
          toastId: 'not-ready-wallet',
        })
      } else {
        HunnyToast.error(t('Something went wrong'), null, {
          toastId: 'reject-connect-wallet',
        })
        logError(`Connect ${adapter.name} (SOL) Wallet`, e)
      }

      return true
    }
  }

  const handleEVMWalletConnect = async (adapter: any) => {
    if (!adapter) return

    const findConnector = connectors.find((c) => c.id === adapter.id)

    await handleEVMDisconnect()

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      try {
        const result = await wagmiConnect({ connector: findConnector })
        if (result?.account) {
          return resolve(true)
        }
      } catch (error: any) {
        if (error instanceof ConnectorNotFoundError) {
          HunnyToast.error(t('Wallet is not found!'))
        } else if (error instanceof UserRejectedRequestError) {
          HunnyToast.warn(t('Rejected.'), t('Your wallet connection was rejected. Please try to connect again.'))
        } else if (error instanceof ResourceUnavailableRpcError || error.code === -32002) {
          HunnyToast.error(
            t('Please confirm connect wallet.'),
            t(`Please check your wallet and confirm to connect ${PRODUCT_NAME}.`),
          )
        } else {
          if (adapter instanceof WalletConnectConnector) {
            handleEVMDisconnect()
            HunnyToast.error(t('Wrong chain'), t('Please switch to your selected network and connect again'))
          }
          logError('Connect EVM Wallet', error)
        }
      }
      resolve(false)
    })
  }

  const handleEVMDisconnect = async () => {
    await wagmiDisconnect()
    if (window.localStorage.getItem('walletconnect')) {
      window.localStorage.removeItem('walletconnect')
    }
  }

  const handleSOLDisconnect = async () => {
    if (connected) {
      try {
        await disconnect()
      } catch (error) {
        if (error instanceof WalletDisconnectionError)
          HunnyToast.error(error.name, error.message, {
            toastId: 'reject-disconnect-wallet',
          })
        logError('Disconnect SOL Wallet', error)
      }
    }
  }

  return useMemo(
    () => ({
      [WalletType.EVM]: {
        connect: handleEVMWalletConnect,
        disconnect: handleEVMDisconnect,
      },
      [WalletType.BSC]: {
        connect: handleEVMWalletConnect,
        disconnect: handleEVMDisconnect,
      },
      [WalletType.SOL]: {
        connect: handleSolWalletConnect,
        disconnect: handleSOLDisconnect,
      },
    }),
    [connected, wagmiConnect, wagmiDisconnect, select, disconnect],
  )
}

