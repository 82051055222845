import { LoginMethod } from 'config/constants/auth'
import { LoginPayload } from 'config/types/auth'
import { ActionQueryEnum } from 'hooks/useActionQueryListener'
import { useAnalytics } from 'hooks/useAnalytics'
import { useConnectWallet } from 'hooks/useConnectWallet'
import { useRouter } from 'hooks/useRouter'
import useSuggestedLogin from 'hooks/useSuggestedLogin'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import AuthenticationService from 'services/AuthenticationService'
import { useAppDispatch, useAppSelector } from 'state'
import { setIsSigned } from 'state/app/actions'
import { resetUserBonus } from 'state/bonus/actions'
import { updateAvailableCashbacks, updateTotalEarnedCashbacks } from 'state/cashback/actions'
import { useUnreadNotificationAmount } from 'state/notification/hooks'
import { resetProfile } from 'state/profile/actions'
import { useUpdateFavoriteGames, useUpdateWageringBonusFeaturedGames } from 'state/profile/hooks'
import { updateDeviceUid } from 'state/session/actions'
import { getID } from 'utils/fingerprint'
import { logError } from 'utils/sentry'
import { removeHUSDBonusStorage } from 'utils/signupBonus'
import { HunnyToast } from 'utils/toastify'
import { login, logout } from './action'

export const useLogout = () => {
  const clientLogout = useClientLogout()
  const wallet = useAppSelector((state) => state.auth.wallet)
  const disconnect = useConnectWallet()

  return useCallback(async () => {
    const isLogout = await AuthenticationService.logout()

    if (isLogout) {
      if (disconnect?.[wallet?.type]) {
        await disconnect[wallet.type].disconnect()
      }

      clientLogout()
    }
  }, [wallet, disconnect])
}

export const useClientLogin = () => {
  const dispatch = useAppDispatch()

  const { recordEvent } = useAnalytics()
  const { clearSuggessLoginInfo } = useSuggestedLogin()

  const handleLogin = useCallback((payload: LoginPayload) => {
    clearSuggessLoginInfo()

    recordEvent(
      'login',
      {
        loginType: payload.loginBy,
      },
      payload.uid,
    )
    dispatch(login({ payload }))
  }, [])

  return handleLogin
}

export const useClientLogout = () => {
  const dispatch = useAppDispatch()
  const { updateUnreadPrivateAmount, updateUnreadSystemAmount } = useUnreadNotificationAmount()
  const { update: updateUserFavoriteGames } = useUpdateFavoriteGames()
  const { update: updateWageringBonusGameCodes } = useUpdateWageringBonusFeaturedGames()

  return useCallback(async () => {
    await dispatch(logout())
    await dispatch(resetProfile())
    await dispatch(resetUserBonus())
    await dispatch(setIsSigned({ isSigned: false }))
    await dispatch(updateTotalEarnedCashbacks({ totalEarnedCashbacks: [] }))
    await dispatch(updateAvailableCashbacks({ availableCashbacks: [] }))
    await updateUnreadPrivateAmount(0)
    await updateUnreadSystemAmount(0)
    await removeHUSDBonusStorage()
    await updateUserFavoriteGames([])
    await updateWageringBonusGameCodes([])
  }, [])
}

export const useAuthRefresh = () => {
  const dispatch = useAppDispatch()
  const deviceUid = useAppSelector((state) => state.session.deviceUid)
  const { uid, username, loginBy } = useUserInfo()
  const logout = useClientLogout()
  const router = useRouter()

  const email = useAppSelector((state) => state.auth.email)
  const wallet = useAppSelector((state) => state.auth.wallet)

  const { t } = useTranslation()

  const refresh = async () => {
    let _deviceUid = deviceUid

    if (!_deviceUid) {
      _deviceUid = await getID()
    }
    const result = await AuthenticationService.refresh(username, _deviceUid, uid)

    if (!result || result.code === 'network_error') {
      const msg = 'Failed call refresh API'
      logError(msg, {
        message: msg,
        tags: ['session_expired'],
        extra: {
          errorCode: result?.code,
          isLoginByWallet: loginBy === LoginMethod.Wallet,
          wallet,
          email,
          deviceUid,
          _deviceUid,
        },
      })
      return
    }

    if (!result.data || !(loginBy === LoginMethod.Wallet ? wallet : email)) {
      const msg = 'Your session expired. Please login again'
      logError(msg, {
        message: msg,
        tags: ['session_expired'],
        extra: {
          errorCode: result?.code,
          isLoginByWallet: loginBy === LoginMethod.Wallet,
          wallet,
          email,
          deviceUid,
          _deviceUid,
        },
      })

      HunnyToast.warn(t(msg))
      await logout()
      router.push({
        ...router,
        query: {
          ...router.query,
          action: loginBy === LoginMethod.Wallet ? ActionQueryEnum.Login : ActionQueryEnum.LoginEmail,
        },
      })
    } else {
      if (!deviceUid) {
        dispatch(updateDeviceUid({ deviceUid: _deviceUid }))
      }
      dispatch(setIsSigned({ isSigned: true, atTime: new Date().getTime() }))
    }
  }

  return refresh
}

export const useRemainAccessTokenTimelife = () => {
  const refresh = useAuthRefresh()
  const { isSigned } = useAuth()

  useEffect(() => {
    if (!isSigned) return
    const interval = setInterval(refresh, 180000)

    return () => {
      clearInterval(interval)
    }
  }, [isSigned])
}

export const useAuth = () => {
  const { loginBy, uid, username, hasSession } = useUserInfo()
  const signedAtTime = useAppSelector((state) => state.app?.signedAtTime)
  const isSigned = useAppSelector((state) => state.app?.isSigned)

  return useMemo(
    () => ({ isSigned, username, uid, loginBy, hasSession, signedAtTime }),
    [isSigned, username, uid, loginBy, signedAtTime, hasSession],
  )
}

export const useUserInfo = () => {
  const loginBy = useAppSelector((state) => state.auth?.loginBy)
  const uid = useAppSelector((state) => state.auth?.uid)
  const username = useAppSelector((state) => state.auth?.username)

  return useMemo(() => ({ username, uid, loginBy, hasSession: !!(username && uid) }), [username, uid, loginBy])
}
