import { useRouter } from 'hooks/useRouter'
import { useMemo } from 'react'

export const useReferralCode = (): { refCode: string; type: 'hunnyplay' | 'affilka' | null } => {
  const router = useRouter()
  const hunnyplayRefCode = router.query?.join?.toString()
  const affilkaRefCode = router.query?.stag?.toString()

  return useMemo(
    () => ({
      refCode: affilkaRefCode || hunnyplayRefCode,
      type: affilkaRefCode ? 'affilka' : hunnyplayRefCode ? 'hunnyplay' : null,
    }),
    [hunnyplayRefCode, affilkaRefCode],
  )
}
