import BigNumber from 'bignumber.js'
import { ChainIdEnum } from 'config/constants/network'
import { CashbackTransaction } from 'config/types/cashback'
import { Notification, NotificationStatusEnums } from 'config/types/notification'
import { WagerInfo, ProfileTierInfo } from 'config/types/profile'
import { Referee, RefereeStats, ReferralCampaign } from 'config/types/referral'
import { StakingPoolStat } from 'config/types/staking'
import {
  BonusTransaction,
  RewardTransaction,
  SwapTransaction,
  Transaction,
  TransactionTypeEnum,
} from 'config/types/transaction'
import { formatApiNetworkField } from 'utils'

export const parseTierInfos = (tierInfos) => {
  if (tierInfos)
    return {
      id: tierInfos.id,
      code: tierInfos.code,
      level: tierInfos.level,
      logoUrl: tierInfos.icon_url,
      logoWithTextUrl: tierInfos.logo_url,
      logoSmallUrl: tierInfos.icon_small_url,
      mainCashbackPercentage: tierInfos.main_cashback_percentage,
      minWagerUsd: tierInfos.min_wager_usd,
      name: tierInfos.name,
      otherCashbackPercentage: tierInfos.other_cashback_percentage,
      bonusUsd: tierInfos.bonus_usd,
    } as ProfileTierInfo

  return null
}

export const getGameLevelStats = (tiers: ProfileTierInfo[], myTier: ProfileTierInfo, totalWager: string): WagerInfo => {
  const score = new BigNumber(totalWager)

  const targetOfCurrentLevel = myTier?.minWagerUsd || new BigNumber(0)
  const targetOfNextLevel: BigNumber =
    new BigNumber(tiers?.find((tier) => tier.level === myTier.level + 1)?.minWagerUsd) || new BigNumber(0)

  const initPercentage = score
    .minus(targetOfCurrentLevel)
    .dividedBy(targetOfNextLevel.minus(targetOfCurrentLevel))
    .multipliedBy(100)
  const checkPercentage = initPercentage?.gt(0) ? initPercentage : score?.gt(0) ? new BigNumber(1) : new BigNumber(0)
  const parseCheckPercentage = Number(checkPercentage.toString(10).split('.')[0])
  const percentage = parseCheckPercentage > 100 ? 100 : parseCheckPercentage

  return {
    score: score.toNumber(),
    percentage,
    target: targetOfNextLevel.toNumber(),
  }
}

export const parseCashbackTransaction = (transactions: any[]): CashbackTransaction[] => {
  if (transactions)
    return transactions.map((item) => {
      return {
        id: item.id,
        currency: item.currency,
        network: formatApiNetworkField(item.network) as any,
        status: item.status,
        fromTime: item.from_time * 1000,
        toTime: item.to_time * 1000,
        value: item.value,
        createTime: item.create_time * 1000,
      } as CashbackTransaction
    })

  return []
}

export const parseReferralCampaign = (campaign: any): ReferralCampaign => {
  if (campaign) {
    const parsedCampaign: ReferralCampaign = {
      id: campaign.id,
      uid: campaign.uid,
      code: campaign.code,
      createTime: campaign.create_time * 1000,
      name: campaign.name,
      refereeCount: campaign.referee_count,
      refereePercentage: campaign.referee_percentage,
      referrerPercentage: campaign.referrer_percentage,
      status: campaign.status,
      subject: campaign.subject,
      isDefault: false,
    }

    return parsedCampaign
  }

  return null
}

export const parseRefereeList = (data: any): Referee[] => {
  const campaigns: ReferralCampaign[] = data['campaigns']?.map((campaign) => parseReferralCampaign(campaign)) || []
  const stats: RefereeStats[] =
    data['stats_list']?.map((refereeStats) => ({
      currency: refereeStats['currency'],
      address: refereeStats['subject'],
      campaignId: refereeStats['campaign_id'],
      gameBetSum: new BigNumber(refereeStats['game_bet_sum']),
      gameCommissionShareSum: new BigNumber(refereeStats['game_commission_share_sum']),
      gameCommissionEarnSum: new BigNumber(refereeStats['game_commission_earn_sum']),
      depositSum: new BigNumber(refereeStats['deposit_sum']),
      createTime: new Date(refereeStats['create_time']),
    })) || []

  return data['items']?.map(
    (item) =>
      ({
        address: `${item.subject.substring(0, 6)}...${item.subject.substring(
          item.subject.length - 4,
          item.subject.length,
        )}`,
        createTime: item.join_time * 1000,
        statsList: stats.filter((stat) => stat.address === item.subject),
        campaign: campaigns.find((campaign) => item.referrer_campaign_id === campaign.id),
      } as Referee),
  )
}

export const parseStakingPoolStat = (data: any, valueField: string): StakingPoolStat[] => {
  return data.map(
    (item) =>
      ({
        currency: item.currency,
        network: ChainIdEnum[formatApiNetworkField(item.network)],
        value: item[valueField],
      } as StakingPoolStat),
  )
}
export const parseWithdrawalTransaction = (data: any): Transaction => ({
  id: data.id,
  status: data.status,
  txnHash: data.txn_hash,
  value: data.value,
  createTime: data.create_time * 1000,
  currency: data.currency,
  network: ChainIdEnum[formatApiNetworkField(data.network)],
  type: TransactionTypeEnum.Withdraw,
  fee: data.fee,
})

export const parseDepositTransaction = (data: any): Transaction => ({
  id: data.id,
  status: data.status,
  txnHash: data.txn_hash,
  value: data.value,
  createTime: data.create_time * 1000,
  currency: data.currency,
  network: ChainIdEnum[formatApiNetworkField(data.network)],
  type: TransactionTypeEnum.Deposit,
  fee: data.fee,
})

export const parseRewardTransaction = (data: any): RewardTransaction => ({
  id: data.id,
  value: data.value,
  createTime: data.create_time * 1000,
  currency: data.currency,
  network: ChainIdEnum[formatApiNetworkField(data.network)],
  note: data.note,
  subject: data.subject,
})

export const parseBonusTransaction = (data: any): BonusTransaction => ({
  id: data.id,
  value: data.value,
  createTime: data.create_time * 1000,
  currency: data.currency,
  network: ChainIdEnum[formatApiNetworkField(data.network)],
  subject: data.subject,
  level: data.level,
})

export const parseSwapTransaction = (data: any): SwapTransaction => ({
  id: data.id,
  createTime: data.create_time * 1000,
  fromCurrency: data.from_currency,
  fromNetwork: ChainIdEnum[formatApiNetworkField(data.from_network)],
  fromValue: data.from_value,
  toCurrency: data.to_currency,
  toNetwork: ChainIdEnum[formatApiNetworkField(data.to_network)],
  toValue: data.to_value,
})

export const parseNotificationItem = (data: any): Omit<Notification, 'type'> => ({
  id: data.id,
  title: data.title,
  bannerUrl: data.banner_url,
  description: data.message || '',
  createTime: data.publish_time * 1000,
  external: data.open_in_new_tab,
  url: data.open_in_new_tab ? data.url : `/${data.url}`,
  anchorText: data.button_text,
  status:
    data.read_time && new Date(data.read_time * 1000) < new Date()
      ? NotificationStatusEnums.READ
      : NotificationStatusEnums.UNREAD,
})
