export type WalletInfo = {
  name: string
  adapter: any
  icon: string
  type: WalletType

  url?: string
  installUrl?: string
}

export enum WalletType {
  EVM,
  BSC,
  SOL,
}
