import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Button from 'components/Button'
import { ButtonProps } from 'components/Button/types'
import ConfirmationModal from 'components/ConfirmationModal'
import CircleLoader from 'components/Loader/CircleLoader'
import Text from 'components/Text'
import { FetchingStatus } from 'config/constants'
import { ChainIdEnum, NETWORK_MAP } from 'config/constants/network'
import { WALLET_INFO } from 'config/constants/wallet'
import { Token } from 'config/types'
import { WageringConditionBonus } from 'config/types/bonus/userBonus'
import { WalletType } from 'config/types/wallet'
import { useAnalytics } from 'hooks/useAnalytics'
import useApproveDepositContract from 'hooks/useApproveDepositContract'
import { useConnectWallet } from 'hooks/useConnectWallet'
import useDeposit from 'hooks/useDeposit'
import useDepositTokenAllowance from 'hooks/useDepositTokenAllowance'
import useModal from 'hooks/useModal'
import useWalletAccount from 'hooks/useWalletAccount'
import { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useAppSelector } from 'state'
import { useTokenUsdPrice } from 'state/app/hooks'
import styled from 'styled-components'
import { Icons } from 'svgs'
import theme from 'theme'
import { colors } from 'theme/colors'
import { getDecimalAmount, getFullDisplayBalance } from 'utils/formatBalance'
import { formatUsername } from 'utils/stringHelper'
import { HunnyToast } from 'utils/toastify'
import useWeb3React from 'packages/wagmi/hooks/useWeb3React'
import { useSwitchNetwork } from 'wagmi'
import { emptyBonus } from './hooks'

type DepositButtonProps = {
  selectedToken: Token
  value: string
  loading: boolean
  validateAll: () => Promise<boolean>
  onSucceed: (hash: string) => void
  onStart?: () => void
  onFailed?: () => void
  selectedBonus: WageringConditionBonus
  handleSelectedBonus: (bonus: WageringConditionBonus) => Promise<void>
}

const DepositButton: React.FC<DepositButtonProps & ButtonProps> = ({
  selectedToken,
  validateAll,
  onStart,
  loading,
  value,
  selectedBonus,
  onSucceed,
  onFailed,
  handleSelectedBonus,
  disabled,
  ...props
}) => {
  const { t } = useTranslation()
  const priceInUsd = useTokenUsdPrice(selectedToken)
  const walletConnector = useConnectWallet()
  const { wallet } = useAppSelector((state) => state.auth)
  const walletInfo = useMemo(
    () => wallet && WALLET_INFO.find((item) => item.name === wallet.name && item.type === wallet.type),
    [wallet?.name, wallet?.type],
  )
  const { chainId } = useWeb3React()
  const { switchNetworkAsync } = useSwitchNetwork()

  const accounts = useWalletAccount()
  const account = accounts[wallet?.type]
  const isWrongAddress = account !== wallet.address
  const isWrongNetwork = walletInfo.type !== WalletType.SOL && chainId != selectedToken.network
  const { allowance, refresh } = useDepositTokenAllowance(!isWrongNetwork && !isWrongAddress && selectedToken)
  const isApproved = value ? allowance?.gte(getDecimalAmount(value, selectedToken.decimals)) : allowance?.gt(0)
  const { recordEvent } = useAnalytics()
  const { approve, approveProcess } = useApproveDepositContract(selectedToken)
  const { deposit, submiting } = useDeposit(selectedToken)

  const [isSwichingNetwork, setIsSwichingNetwork] = useState<boolean>(false)
  const [presentConfirmModal] = useModal(ConfirmationModal)

  const handleSwitchNetwork = async () => {
    setIsSwichingNetwork(true)
    await switchNetworkAsync(selectedToken.network).catch(() => {})
    setIsSwichingNetwork(false)
  }

  const handleApproval = async () => {
    const hash = await approve()
    if (hash) {
      refresh()
      HunnyToast.success(t('Approve {{token}} successfully!', { token: selectedToken.code }))
    }
  }
  const handleDeposit = async () => {
    const isValid = await validateAll()
    if (!isValid) {
      return
    }

    const _deposit = async () => {
      if (onStart) onStart()
      const hash = await deposit(value)
      if (hash) {
        recordEvent('deposit', {
          [ChainIdEnum[selectedToken.network]]: selectedToken.name,
        })
        onSucceed(hash)
      } else if (onFailed) onFailed()
    }

    if (selectedBonus.id !== 0 && selectedBonus.minDepositBigAmount.gt(priceInUsd.multipliedBy(value))) {
      presentConfirmModal({
        onAccept: () => {},
        onCancel: async () => {
          await handleSelectedBonus(emptyBonus)
          _deposit()
        },
        content: t(
          'The min deposit amount to activate your selected bonus is {{value}} {{token}}. If you want to deposit a lower amount, please choose the “Without Bonus” option',
          {
            value: getFullDisplayBalance(selectedBonus.minDepositBigAmount.dividedBy(priceInUsd), 0, 6),
            token: selectedToken.code,
          },
        ),
        acceptButtonContent: t('Input other amount'),
        cancelButtonContent: t('Deposit without bonus'),
      })
    } else {
      _deposit()
    }
  }

  if (!account) {
    return (
      <Button
        {...props}
        onClick={() => {
          walletConnector[wallet.type].connect(walletInfo.adapter)
        }}
      >
        <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'}>
          <Trans>Connect Wallet</Trans>
        </Text>
      </Button>
    )
  }

  if (isWrongAddress) {
    return (
      <>
        <Flex
          alignItems="center"
          border={`1px solid ${colors.warning}`}
          padding="12px"
          borderRadius={theme.radii.small}
          {...props}
        >
          <Box mr="12px">
            <Icons.WarningIcon width="24px" fill={colors.warning} />
          </Box>

          <Text bold fontSize="12px" color="warning" textAlign="center" lineHeight="16px">
            <Trans>
              It seems your wallet connected is active different address, please switch to account{' '}
              {{ account: formatUsername(wallet.address) }}
            </Trans>
          </Text>
        </Flex>

        {walletInfo.adapter instanceof WalletConnectConnector && (
          <Button
            mt="12px"
            width="100%"
            onClick={() => {
              walletConnector[wallet.type].connect(walletInfo.adapter)
            }}
          >
            <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'}>
              <Trans>Switch account</Trans>
            </Text>
          </Button>
        )}
      </>
    )
  }

  if (isWrongNetwork) {
    return (
      <>
        <Button disabled={isSwichingNetwork} onClick={handleSwitchNetwork} {...props}>
          {isSwichingNetwork && (
            <Box mr="12px">
              <CircleLoader />
            </Box>
          )}

          <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'}>
            <Trans>Switch To {{ chainName: NETWORK_MAP[selectedToken.network].networkInfo.displayName }}</Trans>
          </Text>
        </Button>
        {walletInfo.adapter instanceof WalletConnectConnector && isSwichingNetwork && (
          <Flex mt="12px" alignItems="center" justifyContent="center">
            <Text fontSize="14px" color="textSubtle">
              <Trans>If you can't switch network. Try</Trans>
            </Text>

            <StyledReconnect
              fontSize="14px"
              ml="4px"
              onClick={() => {
                walletConnector[wallet.type].connect(walletInfo.adapter)
              }}
            >
              <Trans>Reconnect</Trans>
            </StyledReconnect>
          </Flex>
        )}
      </>
    )
  }

  return (
    <Flex flexDirection="column" alignItems="center" {...props}>
      <Flex width="100%">
        {(approveProcess === FetchingStatus.Fetched || !isApproved) && (
          <Button
            disabled={approveProcess === FetchingStatus.Fetching || isApproved}
            onClick={handleApproval}
            style={{ flex: 1 }}
            mr="12px"
          >
            {approveProcess === FetchingStatus.Fetching && (
              <Box mr="12px">
                <CircleLoader />
              </Box>
            )}

            <Text
              bold
              fontSize="14px"
              color={approveProcess === FetchingStatus.Fetching || isApproved ? 'textSubtle' : 'text'}
            >
              <Trans>Approve</Trans>
            </Text>
          </Button>
        )}

        <Button disabled={!isApproved || submiting || loading || disabled} onClick={handleDeposit} style={{ flex: 1 }}>
          {(submiting || loading) && (
            <Box mr="12px">
              <CircleLoader />
            </Box>
          )}

          <Text bold fontSize="14px" color={disabled ? 'textSubtle' : 'text'}>
            <Trans>Deposit</Trans>
          </Text>
        </Button>
      </Flex>

      {(approveProcess === FetchingStatus.Fetched || !isApproved) && (
        <StyledStepContainer>
          <StyledStepPoint className={account && allowance?.gt(0) ? 'completed' : 'active'}>
            {isApproved ? <Icons.SuccessIcon /> : '1'}
          </StyledStepPoint>
          <StyledStepBar className={account && allowance?.gt(0) && 'active'} />
          <StyledStepPoint className={account && allowance?.gt(0) && 'active'}>2</StyledStepPoint>
        </StyledStepContainer>
      )}
    </Flex>
  )
}

const StyledReconnect = styled(Text)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
`

const StyledStepContainer = styled.div`
  margin-top: 12px;
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  margin-bottom: 24px;
`

const StyledStepBar = styled.div`
  width: 100%;
  height: 2px;
  flex: 1;
  background: linear-gradient(90deg, #e960af 0%, #e960af20 100%);

  &.active {
    background: linear-gradient(90deg, #06c270 0%, #f3c622 100%);
  }
`

const StyledStepPoint = styled.div`
  z-index: 1;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 15px;
  color: white;
  transition: 0.3;
  background: #e960af20;

  &.active {
    background: linear-gradient(270deg, #e960af -10.61%, #f3c622 112.88%);
    opacity: 1;
  }

  &.completed {
    background: transparent;
    border: 4px solid #06c270;
    opacity: 1;

    svg {
      min-width: 28px;
      fill: #06c270;
    }
  }
`

export default DepositButton
