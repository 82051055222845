import {
  Referee,
  ReferralBalanceTokenAmount,
  ReferralCampaign,
  ReferralCampaignStats,
  ReferralOverview,
  ReferralStats,
} from 'config/types/referral'
import { BaseResponse, Paging, PrepareReferralClaimRes, ReferralCampaignDetailRes } from 'services/types'
import { formatApiNetworkField } from 'utils'
import { parseRefereeList, parseReferralCampaign } from './utils'

export const ReferralOverviewMapper = (rawResponse: string): BaseResponse<ReferralOverview> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      refereeAmount: response.data.referee_count,
      referrer: response.data.referrer,
      stats: response.data.stats_list.map(
        (stats) =>
          ({
            currency: stats.currency,
            network: stats.network,
            gameBetSum: Number(stats.game_bet_sum),
            gameComissionSum: Number(stats.game_commission_sum),
          } as ReferralStats),
      ),
    },
  }
}

export const ReferralBalanceMapper = (rawResponse: string): BaseResponse<Paging<ReferralBalanceTokenAmount>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: {},
      items: response.data.items
        .filter((item) => Number(item.value) > 0)
        .map(
          (item) =>
            ({
              currency: item.currency,
              network: formatApiNetworkField(item.network),
              value: item.value,
            } as ReferralBalanceTokenAmount),
        ),
    },
  }
}

export const ReferralPrepareClaimMapper = (rawResponse: string): BaseResponse<PrepareReferralClaimRes> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      items: response.data.items.map(
        (item) =>
          ({
            currency: item.currency,
            network: formatApiNetworkField(item.network),
            value: item.value,
            convertedValue: item.converted_value,
          } as ReferralBalanceTokenAmount),
      ),
      sum: response.data.sum,
    },
  }
}

export const ReferralListCampaignMapper = (rawResponse: string): BaseResponse<Paging<ReferralCampaign>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: response.data.paging,
      items: response.data.items.map((item) => parseReferralCampaign(item)),
    },
  }
}
export const ReferralCampaignMapper = (rawResponse: string): BaseResponse<ReferralCampaign> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      code: response.data.code,
      createTime: response.data.create_time * 1000,
      id: response.data.id,
      name: response.data.name,
      refereePercentage: response.data.referee_percentage,
      referrerPercentage: response.data.referrer_percentage,
      uid: response.data.uid,
      status: response.data.status,
      subject: response.data.subject,
    },
  }
}

export const ReferralCampaignDetailMapper = (rawResponse: string): BaseResponse<ReferralCampaignDetailRes> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  let responseData: ReferralCampaignDetailRes = {
    campaign: parseReferralCampaign(response.data?.campaign),
  }

  if (response.data?.stats_time_range)
    responseData = {
      ...responseData,
      statsTimeRange: {
        fromTime: (response.data?.stats_time_range.from_time || 0) * 1000,
        toTime: (response.data?.stats_time_range.to_time || 0) * 1000,
      },
    }

  if (response.data?.stats_list)
    responseData = {
      ...responseData,
      statsList: response.data.stats_list?.map(
        (item) =>
          ({
            currency: item.currency,
            network: item.network,
            depositTime: item.deposit_count,
            depositSum: item.deposit_sum,
            depositFirstSum: item.deposit_first_sum,
            depositFirstTime: item.deposit_first_count,
            gameBetSum: item.game_bet_sum,
            gameComissionSum: item.game_commission_sum,
          } as ReferralCampaignStats),
      ),
    }

  return {
    code: response.code,
    message: response.message,
    data: responseData,
  }
}

export const RefereeListMapper = (rawResponse: string): BaseResponse<Paging<Referee>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)
  const items = parseRefereeList(response.data) || []

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: response.data.paging,
      items,
    },
  }
}
