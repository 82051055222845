export class PromiseQueue {
  private _limit: number

  private _queue: any[] = []
  private _processingQueue: any[] = []

  constructor(processLimitAmount: number) {
    this._limit = processLimitAmount
  }

  private _trigger() {
    if (this._processingQueue.length < this._limit && this._queue.length > 0) {
      const request = this._queue.pop()
      this._processingQueue.push(request)
      request()
    }
  }

  private _push(request: () => Promise<any>) {
    this._queue.push(request)
    this._trigger()
  }

  public call<T>(request: () => Promise<T>): Promise<T> {
    const requestInfo = {
      isCalling: true,
      data: null,
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const parent = this
    async function _request() {
      requestInfo.isCalling = true
      requestInfo.data = await request()
      requestInfo.isCalling = false

      parent._processingQueue = parent._processingQueue.filter((r) => r !== _request)

      if (parent._processingQueue.length < parent._limit) {
        parent._trigger()
      }
    }

    this._push(_request)

    return new Promise((resolve) => {
      const loop = () => (!requestInfo.isCalling ? resolve(requestInfo.data) : setTimeout(loop, 10))
      loop()
    })
  }
}
