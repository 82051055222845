import { ChainIdEnum } from 'config/constants/network'
import { APIEndpointEnum } from 'config/constants/server'
import { Token } from 'config/types'
import { BonusStatusFilterEnums, CommonBonus } from 'config/types/bonus/index'
import { ApiBonusStatusEnums, UserBonus } from 'config/types/bonus/userBonus'
import { formatToApiNetworkField } from 'utils'
import { logError } from 'utils/sentry'
import { HunnyPlayRequest } from './HunnyPlayRequest'
import { BaseResponse, HUSDClaimedTransaction, HunnyRequest, Paging } from './types'

class BonusService extends HunnyPlayRequest {
  public getHUSDLockAmount(): HunnyRequest<BaseResponse<string>> {
    const result = this._post(APIEndpointEnum.HUSDLockBalance)
    return result
  }

  public getHUSDClaimableAmount(): HunnyRequest<BaseResponse<{ amount: string; wager: string }>> {
    const result = this._post(APIEndpointEnum.HUSDClaimableBalance, { currency: 'HUSD', network: 'HPN' })
    return result
  }

  public async claimHUSD(amount: string, wager: string): Promise<BaseResponse<boolean>> {
    const result = await this._request(APIEndpointEnum.ClaimHUSD, { currency: 'HUSD', network: 'HPN', amount, wager })
    return result
  }

  public getUnlockFormula(): HunnyRequest<BaseResponse<{ houseEdge: number; const: number }>> {
    const result = this._post(APIEndpointEnum.UnlockFormula)
    return result
  }

  public getTotalHUSDClaimed(): HunnyRequest<BaseResponse<string>> {
    const result = this._post(APIEndpointEnum.TotalHUSDClaimed, { currency: 'HUSD', network: 'HPN' })
    return result
  }

  public getHUSDClaimedHistory(
    offset: number,
    limit: number,
    filterOptions: {
      in_out_status: number
      type?: number
    } | null,
  ): HunnyRequest<BaseResponse<Paging<HUSDClaimedTransaction>>> {
    const result = this._post(APIEndpointEnum.HUSDTxnList, {
      filter: filterOptions || {},
      paging: { offset, limit },
    })
    return result
  }

  public async swap(amount: string, receiveToken: Token): Promise<boolean> {
    try {
      const prepareResponse = await this._request(APIEndpointEnum.PrepareSwapData, {
        from_token_info: { currency: 'HUSD', network: 'HPN', value: amount },
        to_token_info: {
          currency: receiveToken.name,
          network: formatToApiNetworkField(ChainIdEnum[receiveToken.network]),
        },
      })

      const result = await this._request(APIEndpointEnum.SwapHUSD, prepareResponse.data)

      return result.data
    } catch (e) {
      logError('Swap failed', e)
      return false
    }
  }

  // Bonus API which migrated from voucher
  public getUserBonuses(
    statusFilter: BonusStatusFilterEnums,
    offset = 0,
    limit = 1000,
  ): HunnyRequest<BaseResponse<Paging<UserBonus>>> {
    const params: any = { paging: { limit, offset }, filter: { list_type: statusFilter } }

    const result = this._post(APIEndpointEnum.UserBonuses, params)

    return result
  }

  public getWaitingDepositUserBonuses(
    offset = 0,
    limit = 1000,
  ): HunnyRequest<BaseResponse<Paging<UserBonus> & { selectedDepositBonusId: number }>> {
    const params: any = {
      paging: { limit, offset },
      filter: { status: ApiBonusStatusEnums.WaitDeposit, is_not_expired: 1 },
    }

    const result = this._post(APIEndpointEnum.UserBonuses, params)

    return result
  }

  public getUserDepositBonuses(depositIds: number[]): HunnyRequest<BaseResponse<Paging<UserBonus>>> {
    const params: any = {
      filter: { deposit_ids: depositIds },
    }

    const result = this._post(APIEndpointEnum.UserBonuses, params)
    return result
  }

  public async prepareDepositBonus(bonusId: number): Promise<boolean> {
    const result = await this._request(APIEndpointEnum.BonusPrepareDeposit, { user_voucher_id: bonusId })

    return result.code === 'success'
  }

  public async reselectWageringBonus(): Promise<number> {
    const result = await this._request(APIEndpointEnum.ReselectWageringBonus)

    return result.code === 'success' ? result.data?.id : 0
  }

  public getExtraHUSDBonusAmount(): HunnyRequest<string> {
    // const result = await this._post(APIEndpointEnum.ActivatedVouchers)
    // TODO Integration
    return {
      call: async () => '0',
      cancel: () => {},
    }
  }

  public async apply(id: number): Promise<BaseResponse<boolean>> {
    const result = await this._request(
      APIEndpointEnum.BonusApply,
      {
        id,
      },
      {
        disabledToast: true,
      },
    )

    return result
  }

  public async cancel(id: number): Promise<boolean> {
    const result = await this._request(APIEndpointEnum.BonusCancel, {
      id,
    })

    return result.code === 'success'
  }

  public getUserBonusDetails(id: number): HunnyRequest<BaseResponse<UserBonus>> {
    const result = this._post(APIEndpointEnum.UserBonusDetails, { id })
    return result
  }

  public getBonusDetails(code: string): HunnyRequest<BaseResponse<CommonBonus>> {
    const result = this._post(APIEndpointEnum.BonusDetails, { code })
    return result
  }

  public async getUserWelcomePackageInfo(): Promise<{ expiredAt: number; isClaimed: boolean }> {
    const result = await this._request(APIEndpointEnum.UserWelcomePackageInfo)
    const endTime = result.data && (result.data.received_package ? 0 : result.data.end_time * 1000)

    return { expiredAt: endTime ? (endTime > Date.now() ? endTime : 0) : 0, isClaimed: result.data.received_package }
  }

  public async chooseUserWelcomePackage(welcomePackCode: string): Promise<boolean> {
    const result = await this._request(APIEndpointEnum.UserChooseWelcomePackage, { package_code: welcomePackCode })

    return result.code === 'success'
  }

  public async redeem(code: string): Promise<BaseResponse<boolean>> {
    const result = await this._request(
      APIEndpointEnum.BonusRedeem,
      {
        code,
      },
      { disabledToast: true },
    )
    return result
  }

  public async registerFreespinBonus(id: number): Promise<boolean> {
    const result = await this._request(APIEndpointEnum.FreespinBonusRegister, {
      id,
    })
    return result.code === 'success'
  }

  public async addBonusLock(id: number): Promise<boolean> {
    const result = await this._request(APIEndpointEnum.NoDepositBonusActive, {
      id,
    })
    return result.code === 'success'
  }

  public getAllGameCodesInBonusCollection(collectionGameId: number): HunnyRequest<BaseResponse<string[]>> {
    const result = this._post(APIEndpointEnum.WageringBonusGameCodes, { id: collectionGameId })
    return result
  }
}

const instance = new BonusService()
export default instance
