import { ModalProps } from 'components/Modal/Modal'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Button from 'components/Button'
import Heading from 'components/Heading'
import Text from 'components/Text'
import Box from 'components/Box/Box'
import { Icons } from 'svgs'
import FormValidator, { commomErrorMessage } from 'config/constants/formValidator'
import useForm from 'hooks/useForm'
import AuthenticationService from 'services/AuthenticationService'
import { transformHunnyRequest } from 'utils/requestHelper'
import FormControl from 'components/FormControl'
import FormInput from 'components/FormControl/FormInput'
import CircleLoader from 'components/Loader/CircleLoader'
import { ValidationError } from 'config/types/validator'
import useModal from 'hooks/useModal'
import { useRequest } from 'hooks/useRequest'
import EmailVerificationModal from './EmailVerificationModal'
import ForgotPasswordModal from './ForgotPasswordModal'
import ResetPasswordFormModal from './ResetPasswordFormModal'

export interface PasswordRecoveryModalProps {
  email?: string
  switchSignUpPage: (draftEmail?: string) => void
}

export const emailErrorMessages = {
  [ValidationError.Email]: commomErrorMessage.Email,
}

const buildEmailNotExistedFn = (value: string) => {
  const request = AuthenticationService.getTraditionalSignUpPrepare({ username: value, referralCode: null })
  return transformHunnyRequest(request, (res) => {
    return !!res?.data
  })
}

const PasswordRecoveryModal: React.FC<ModalProps<PasswordRecoveryModalProps>> = ({
  onDismiss,
  data: { email, switchSignUpPage },
}) => {
  const { t } = useTranslation()
  const [submiting, setSubmiting] = useState(false)
  const { execute } = useRequest()
  const [handlePresentEmailVerificationModal] = useModal(EmailVerificationModal)
  const [handlePresentResetPasswordModal] = useModal(ResetPasswordFormModal)

  const { states, controls, validateAll, isValid } = useForm({
    email: {
      validators: [FormValidator.email, FormValidator.existEmail(buildEmailNotExistedFn)],
      value: email,
    },
  })

  const formatErrorMessage = (errors: ValidationError[]) => {
    // TODO set key translation
    //  Ref: https://stackoverflow.com/questions/61268001/react-i18n-trans-component-with-translations-that-contain-html-tags-not-working

    if (errors[0] === ValidationError.EmailNotExist) {
      return (
        <Trans>
          Email not registered. Please enter a different email or{' '}
          <Text
            id="footer-signup"
            color="primary"
            display="inline"
            fontSize="12px"
            bold
            style={{ cursor: 'pointer' }}
            onClick={() => {
              switchSignUpPage(states.email.value)
              onDismiss()
            }}
          >
            Sign up
          </Text>{' '}
          new account.
        </Trans>
      )
    }

    return t(emailErrorMessages[errors[0]])
  }

  const handleSubmit = async () => {
    setSubmiting(true)

    const isValid = await validateAll()
    if (!isValid) {
      setSubmiting(false)
      return
    }

    const verification = await execute(AuthenticationService.verifyResetPassword(states.email.value))

    if (!verification?.data) {
      setSubmiting(false)
      // TODO Show error
      return
    }

    handlePresentEmailVerificationModal({
      email: states.email.value,
      initialVerifiedResult: verification.data,
      verifiedHunnyRequestFn: () =>
        transformHunnyRequest(AuthenticationService.verifyResetPassword(states.email.value), (res) => res?.data),
      onSubmit: async (verification, otp) => {
        const passwordNonce = await AuthenticationService.validateResetPassword(states.email.value, {
          type: 'email',
          data: {
            token: verification.token,
            otp,
          },
        })
        if (passwordNonce?.data) {
          handlePresentResetPasswordModal({ nonce: passwordNonce.data, username: states.email.value })
        }

        return passwordNonce
      },
    })

    onDismiss()
  }

  return (
    <ForgotPasswordModal id="popup-resetpassword" onDismiss={onDismiss} label={t('Reset Password')}>
      <Heading>
        <Trans>Please enter your email</Trans>
      </Heading>

      <FormControl mt="20px" state={states.email} label={t('Email')} formatErrorMessage={formatErrorMessage}>
        <FormInput
          tabIndex={1}
          control={controls.email}
          placeholder={t('Email')}
          type="email"
          name="email"
          icon={<Icons.EmailIcon />}
          adornment={
            states.email.isValidating ? (
              <Box className="hide-when-focus" height="20px">
                <CircleLoader />
              </Box>
            ) : null
          }
        />
      </FormControl>

      <Button
        id="submit-button"
        mt={['24px', '24px', '40px']}
        disabled={!isValid || !states.email.value || submiting}
        onClick={handleSubmit}
      >
        {submiting && (
          <Box mr="12px">
            <CircleLoader />
          </Box>
        )}

        <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'} tabIndex={1}>
          <Trans>Reset Password</Trans>
        </Text>
      </Button>
    </ForgotPasswordModal>
  )
}

export default PasswordRecoveryModal
