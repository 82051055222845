import Flex from 'components/Box/Flex'
import TableCell from 'components/Table/TableCell'
import TableRow from 'components/Table/TableRow'
import Text from 'components/Text'
import TokenLogo from 'components/TokenLogo'
import { TokenAmount } from 'config/types'
import { Trans } from 'react-i18next'
import theme from 'theme'
import { formatDisplayDateTime } from 'utils/dateHelper'
import { parseToLockedHusd } from '../utils'

export interface RowProps {
  createTime: number
  address: string
  prize: TokenAmount
}

const PrizeWinnerRow: React.FC<RowProps> = ({ prize, createTime, address }) => {
  return (
    <TableRow>
      <TableCell
        height="52px"
        borderLeft="none !important"
        borderRight="none !important"
        borderBottom={`1px solid ${theme.colors.stroke}`}
      >
        <Text fontSize={['12px', '14px']}>{formatDisplayDateTime(new Date(createTime))}</Text>
      </TableCell>

      <TableCell
        height="52px"
        borderLeft="none !important"
        borderRight="none !important"
        borderBottom={`1px solid ${theme.colors.stroke}`}
      >
        <Text fontSize={['12px', '14px']}>
          <Trans>{address}</Trans>
        </Text>
      </TableCell>

      <TableCell
        height="52px"
        borderLeft="none !important"
        borderRight="none !important"
        borderBottom={`1px solid ${theme.colors.stroke}`}
      >
        <Flex alignItems="center">
          <TokenLogo token={parseToLockedHusd(prize.token)} width="24px" height="24px" />
          <Text fontSize={['12px', '14px']} ml="10px" bold>
            {prize.amount.toString()}
          </Text>
        </Flex>{' '}
      </TableCell>
    </TableRow>
  )
}

export default PrizeWinnerRow
