import { Notification, NotificationTypeEnums } from 'config/types/notification'
import { BaseResponse, Paging } from 'services/types'
import { parseNotificationItem } from './utils'

export const PrivateNotificationMapper = (rawResponse: string): BaseResponse<Paging<Notification>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response)
    return {
      code: 'network_error',
      data: null,
    }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: response.data.paging,
      items: response.data.items.map((item) => ({
        ...parseNotificationItem(item),
        type: NotificationTypeEnums.PRIVATE,
      })),
    },
  }
}
export const SystemNotificationMapper = (rawResponse: string): BaseResponse<Paging<Notification>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response)
    return {
      code: 'network_error',
      data: null,
    }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: response.data.paging,
      items: response.data.items.map((item) => ({
        ...parseNotificationItem(item),
        type: NotificationTypeEnums.SYSTEM,
      })),
    },
  }
}
