import { BIG_ZERO } from 'config/constants/number'
import { MyTierInfo } from 'config/types/profile'
import { parseTierInfos } from 'services/mapper/utils'
import { BaseResponse, Paging } from 'services/types'

export const UserTierMapper = (rawResponse: string): BaseResponse<MyTierInfo> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response)
    return {
      code: 'network_error',
      data: null,
    }

  const tierInfos = response.data?.tier || {}
  const statsListInfos = response.data?.stats_list || []

  const tier = parseTierInfos(tierInfos)

  const totalWager =
    statsListInfos.reduce((state, stats) => {
      return state.plus(stats?.game_bet_usd_sum || 0)
    }, BIG_ZERO) || BIG_ZERO

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      tier,
      totalWager: totalWager.toString(),
    },
  }
}

export const UserFavoriteGameCodesMapper = (rawResponse: string): BaseResponse<Paging<string>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response)
    return {
      code: 'network_error',
      data: null,
    }

  return {
    code: response.code,
    message: response.message,
    data: {
      paging: {
        limit: response.data.paging.limit,
        offset: response.data.paging.offset,
        total: response.data.paging.total,
      },
      items: response.data.game_codes,
    },
  }
}
