import Box from 'components/Box/Box'
import Grid from 'components/Box/Grid'
import Button from 'components/Button'
import { WALLET_INFO } from 'config/constants/wallet'
import useModal from 'hooks/useModal'
import useWalletAccount from 'hooks/useWalletAccount'
import Column from 'layout/Components/Column'
import { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'
import hunnyKeyframes from 'theme/keyframes'
import { AuthModalPageEnums } from 'views/Authentication/types'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'
import { ConditionAcccess } from '../Template'
import CheckCondition from '../Traditional/CheckCondition'
import WalletCard from '../WalletCard'
import SignIn2FA from './SignWallet'
import BonusCountdown from '../BonusCountdown'
import ReferralCode from '../ReferralCode'

const SignWithWallet: React.FC<{
  isShowMore: boolean
  setShowMore: (more: boolean) => void
  page: AuthModalPageEnums
}> = ({ isShowMore, setShowMore, page }) => {
  const displayWallets = isShowMore ? WALLET_INFO : WALLET_INFO.slice(0, 10)
  const [isConfirmed, setIsConfirmed] = useState(true)
  const accounts = useWalletAccount()
  const [selectedWallet, setSelectedWallet] = useState(null)
  const [handlePresent] = useModal(SignIn2FA)

  useEffect(() => {
    if (!selectedWallet || !accounts[selectedWallet?.type]) {
      return
    }
    setSelectedWallet(null)
    handlePresent({ wallet: selectedWallet })
  }, [accounts, selectedWallet])

  return (
    <Wrapper $more={isShowMore}>
      <WrapListWallet confirmed={isConfirmed}>
        <WrapGridWallet $more={isShowMore}>
          {displayWallets.map((wallet) => (
            <WalletCard
              id="wallet-select"
              key={wallet.name}
              wallet={wallet}
              onSelect={isConfirmed ? setSelectedWallet : () => {}}
            />
          ))}
        </WrapGridWallet>
      </WrapListWallet>
      <StyledToggleButton id={isShowMore ? 'wallet-less' : 'wallet-more'} onClick={() => setShowMore(!isShowMore)}>
        {isShowMore ? <Trans>Show less</Trans> : <Trans>Show more</Trans>}
        <Box>
          <StyledIconWrapper $more={isShowMore} width="18px" height="18px">
            <Icons.ArrowDownIconAlt width="18px" height="18px" fill={colors.textSubtle} />
          </StyledIconWrapper>
        </Box>
      </StyledToggleButton>
      <ReferralCode mt="36px" />
      {page === AuthModalPageEnums.SIGN_UP ? (
        <Box mt="12px">
          <Box height="50px">
            <CheckCondition value={Boolean(isConfirmed)} onValueChanged={setIsConfirmed} />
          </Box>

          <BonusCountdown onExpired={() => {}} display={['block', '', '', 'none']} mt="36px" />
        </Box>
      ) : (
        <ConditionAcccess />
      )}
    </Wrapper>
  )
}

const Wrapper = styled(Column)<{ $more: boolean }>`
  max-height: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const WrapListWallet = styled(Column)<{
  confirmed?: boolean
}>`
  overflow: hidden;
  box-sizing: border-box;
  padding-bottom: 10px;
  position: relative;

  ${({ confirmed }) =>
    !confirmed &&
    css`
      opacity: 0.4;

      &:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        user-select: none;
        width: 100%;
        height: 100%;
      }
    `};
`

const WrapGridWallet = styled(Grid).attrs({ gridTemplateColumns: ['1fr 1fr'] })<{
  $more: boolean
}>`
  animation: ${({ $more }) => ($more ? hunnyKeyframes.slideTopToBottom : hunnyKeyframes.slideBottomToTop)} 0.5s ease-out
    forwards;
  margin-right: -12px;
  padding-right: 12px;
  overflow-y: scroll;
  grid-column-gap: 8px;
  grid-row-gap: 12px;

  ::-webkit-scrollbar {
    width: 2px !important;
  }
`

const StyledToggleButton = styled(Button).attrs({ variant: 'text' })`
  display: flex;
  align-items: center;
  justify-content: center;

  height: auto !important;

  font-size: 12px;
  font-weight: 400;

  margin: 0px auto;
  margin-top: 10px;

  color: ${({ theme: { colors } }) => colors.textSubtle};
`

const StyledIconWrapper = styled(Box)<{ $more: boolean }>`
  transform: ${({ $more }) => ($more ? 'rotate(180deg)' : '')};
  transform-origin: center center;
  transition: 0.2s transform linear;
`

export default SignWithWallet
