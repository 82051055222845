import BigNumber from 'bignumber.js'
import { ChainIdEnum } from 'config/constants/network'
import { RouteConfig } from 'config/constants/route'
import { Token, TokenAmount } from 'config/types'
import { ProfileTierInfo, WagerInfo } from 'config/types/profile'
import { WalletType } from 'config/types/wallet'
import useDebounce from 'hooks/useDebounce'
import useDebounceCallback from 'hooks/useDebounceCallback'
import { useFetchUserTier } from 'hooks/useFetchUserTier'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import { usePrivateSocket } from 'hooks/usePrivateSocket'
import { useRequest } from 'hooks/useRequest'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import BonusService from 'services/BonusService'
import LuckySpinService from 'services/LuckySpinService'
import ProfileService from 'services/ProfileService'
import { getGameLevelStats } from 'services/mapper/utils'
import { BalanceResponse } from 'services/types'
import { AppState, useAppDispatch, useAppSelector } from 'state'
import { useListTokens, useTiers } from 'state/app/hooks'
import { useAuth } from 'state/auth/hooks'
import { useActiveBonusInWageringProcess, useActiveLevelUpBonus, useUserWelcomePackage } from 'state/bonus/hooks'
import { selectPlayBalance } from 'state/session/actions'
import { useTokenSelected } from 'state/session/hooks'
import { formatApiNetworkField, getBalanceTokenKey } from 'utils'
import { getCurrencyBalance } from 'utils/infura'
import { forkjoinRequest } from 'utils/requestHelper'
import { isSolToken } from 'utils/token'
import {
  updateBalances,
  updateEligibleWageringBonusGames,
  updateFavoriteGame,
  updateFavoriteGames,
  updateMyGameLevelStats,
  updateUserFreeLuckySpin,
  updateUserWelcomePackageInfo,
} from './actions'

export const useInitializeUserFavoriteGames = () => {
  const { isSigned } = useAuth()
  const { fetchFavoriteGames } = useUpdateFavoriteGames()

  useEffect(() => {
    if (!isSigned) return
    fetchFavoriteGames()
  }, [isSigned])
}

export const useUpdateFavoriteGames = () => {
  const dispatch = useAppDispatch()
  const { execute } = useRequest()

  const fetchFavoriteGames = useCallback(async () => {
    const response = await execute(ProfileService.getFavoriteGameCodes())
    if (response && !!response.data?.items?.length) update(response.data.items)
    else update([])
  }, [])

  const update = useCallback((gameCodes: string[]) => {
    const favoriteGames = gameCodes.reduce((prevObject, gameCode) => {
      prevObject[gameCode] = true
      return prevObject
    }, {})

    dispatch(updateFavoriteGames({ favoriteGames }))
  }, [])

  return useMemo(() => ({ update, fetchFavoriteGames }), [update, fetchFavoriteGames])
}

export const useToggleFavoriteGame = () => {
  const dispatch = useAppDispatch()
  const debounce = useDebounceCallback()

  const toggleFavoriteGame = useCallback(async (gameCode: string, isFavorited: boolean, update: () => void) => {
    dispatch(updateFavoriteGame({ gameCode, isFavorited }))
    update()

    debounce(async () => {
      if (isFavorited) {
        ProfileService.addFavoriteGame(gameCode)
      } else {
        ProfileService.removeFavoriteGame(gameCode)
      }
    }, 500)
  }, [])

  return useMemo(() => ({ toggleFavoriteGame }), [toggleFavoriteGame])
}

export const usePollUserBalances = () => {
  const { isSigned } = useAuth()
  const dispatch = useAppDispatch()
  const socket = usePrivateSocket()

  useEffect(() => {
    if (!isSigned || !socket) {
      return
    }

    socket.on('balance.changed', (data) => {
      const balance: BalanceResponse = {
        amount: data['after_balance'],
        currency: data.currency,
        network: ChainIdEnum[formatApiNetworkField(data.network)],
      }
      dispatch(updateBalances({ data: [balance] }))
    })

    return () => {
      if (socket) {
        socket.off('balance.changed')
      }
    }
  }, [isSigned, socket])
}

export const useUpdateUserLuckySpin = () => {
  const dispatch = useAppDispatch()
  const { execute } = useRequest()

  const update = useCallback(async () => {
    const response = await execute(LuckySpinService.getSpinsLeft())
    if (response?.data) {
      dispatch(
        updateUserFreeLuckySpin({
          nextSpinTime: response.data.nextSpinTime,
          amount: response.data.amount,
        }),
      )
    }
  }, [])

  return update
}

export const useNextSpinTime = () => {
  const nextSpinTime = useAppSelector((state) => state.profile.luckySpin?.nextSpinTime)
  return nextSpinTime
}

export const useUserLeftSpinTimes = () => {
  const amount = useAppSelector((state) => state.profile.luckySpin?.amount)
  return amount
}

export const useInitializeUserLuckySpin = () => {
  const update = useUpdateUserLuckySpin()
  const { isSigned } = useAuth()

  useEffect(() => {
    if (isSigned) update()
  }, [isSigned])
}

export const useUserProfileUpdater = () => {
  const dispatch = useAppDispatch()
  const { isSigned } = useAuth()
  const tiers = useTiers()
  const { percentage } = useUserWager()
  const tier = useSelector((state: AppState) => state.profile.tier)
  const socket = usePrivateSocket()
  const welcomePackage = useUserWelcomePackage()
  const fetchUserTier = useFetchUserTier()

  useEffect(() => {
    if (!isSigned || percentage < 100) return

    const fetch = async () => {
      const result = await fetchUserTier()
      if (result) {
        const { percentage, score, target } = getGameLevelStats(tiers, result.tier, result.totalWager)

        dispatch(
          updateMyGameLevelStats({
            data: {
              percentage,
              score,
              target,
            },
          }),
        )
      }
    }

    fetch()
  }, [isSigned, tiers, percentage])

  useEffect(() => {
    if (!isSigned || welcomePackage?.isClaimed || !welcomePackage.expiredAt) return

    const remainTime = welcomePackage.expiredAt.getTime() - Date.now()

    if (remainTime > 0) {
      const timeout = setTimeout(() => {
        dispatch(
          updateUserWelcomePackageInfo({
            expiredAt: 0,
            isClaimed: welcomePackage.isClaimed,
          }),
        )
      }, remainTime)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [welcomePackage])

  useEffect(() => {
    if (!isSigned || !socket || !tier) {
      return
    }

    socket.on('user.stats', async (data) => {
      if (data?.totalWager) {
        const { percentage, score, target } = getGameLevelStats(tiers, tier, data?.totalWager)

        dispatch(
          updateMyGameLevelStats({
            data: {
              percentage,
              score,
              target,
            },
          }),
        )
      }
    })

    return () => {
      if (socket) {
        socket.off('user.stats')
      }
    }
  }, [isSigned, socket, tier])
}

export const useUpdateUserToken = () => {
  const dispatch = useAppDispatch()
  const wallet = useAppSelector((state) => state.auth.wallet)
  const supportedTokens = useListTokens()
  const selectedToken = useTokenSelected()
  const { isSigned } = useAuth()

  useEffect(() => {
    if (!isSigned) return

    if (wallet?.type === WalletType.SOL) {
      if (!selectedToken || !isSolToken(selectedToken)) {
        const token = supportedTokens.find(
          (token) => token.network === ChainIdEnum.SOL || token.network === ChainIdEnum.SOL_TESTNET,
        )
        dispatch(selectPlayBalance({ token }))
      }
    } else if (!selectedToken || isSolToken(selectedToken)) {
      dispatch(selectPlayBalance({ token: supportedTokens[0] }))
    }
  }, [isSigned, wallet])
}

export const useUserWager = (): WagerInfo => {
  const wager = useSelector((state: AppState) => state.profile.wager)

  return useMemo(() => {
    return wager
  }, [wager])
}

export const useProfileTier = (): ProfileTierInfo => {
  const tiers = useTiers()
  const profile = useSelector((state: AppState) => state.profile)
  const levelUpBonus = useActiveLevelUpBonus()

  return useMemo(() => {
    if (!profile.tier) return null

    if (levelUpBonus) {
      return tiers.find((tier) => tier.level === profile.tier.level + levelUpBonus.extraLevels) || profile.tier
    }

    return profile.tier
  }, [profile, levelUpBonus])
}

export const useTokenWalletBalance = (token: Token, address: string): TokenAmount => {
  const tokens = useMemo(() => [token], [token])
  const [tokenBalance] = useTokenWalletBalances(tokens, address)

  return useMemo(() => {
    return tokenBalance
  }, [tokenBalance])
}

export const useTokenWalletBalances = (tokens: Token[], address: string): TokenAmount[] => {
  const [tokenBalances, setTokenBalances] = useState<TokenAmount[]>([])

  useEffect(() => {
    if (!tokens || !address) return
    const fetch = async () => {
      const getTokenBalanceRequests = tokens.map((token) => {
        return getCurrencyBalance(token, address)
      })

      const tokenBalances: TokenAmount[] = await forkjoinRequest(getTokenBalanceRequests)
      if (tokenBalances) setTokenBalances(tokenBalances)
    }

    fetch()
    const interval = setInterval(fetch, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [tokens, address])

  return useMemo(() => {
    return tokenBalances
  }, [tokenBalances])
}

export const useTokenAppBalance = (token: Token): TokenAmount => {
  const amounts = useTokenAppBalances([token])
  return useMemo(() => {
    return amounts[0]
  }, [amounts, token])
}

export const useTokenAppBalances = (tokens: Token[]): TokenAmount[] => {
  const tokenBalances = useAppSelector((state) => state.profile.balances)

  return useMemo(() => {
    if (!tokens) return []
    return tokens
      .filter((token) => token)
      .map((token) => {
        const tokenBalance = tokenBalances[getBalanceTokenKey(token.network, token.code)]
        return {
          amount: new BigNumber(tokenBalance || 0),
          token,
        }
      })
  }, [tokenBalances, tokens])
}

export const useAuthPage = () => {
  const router = useRouter()
  const { hasSession } = useAuth()

  useIsomorphicEffect(() => {
    if (!hasSession) {
      router.push(RouteConfig.Home)
    }
  }, [hasSession])

  return { isAuth: hasSession }
}

export const useFeaturedGame = (gameCode: string) => {
  const isFavorited = useAppSelector((state) => state.profile.featuredGames.favoriteGames[gameCode]) || false
  const isWageringBonusGame =
    useAppSelector((state) => state.profile.featuredGames.wageringBonusGames[gameCode]) || false

  const featuredGameInfo = useMemo(() => {
    return { isFavorited, isWageringBonusGame }
  }, [isFavorited, isWageringBonusGame])

  return useMemo(() => ({ featuredGameInfo }), [featuredGameInfo])
}

export const useUpdateWageringBonusFeaturedGames = () => {
  const dispatch = useAppDispatch()
  const { execute } = useRequest()

  const fetchAllGameCodeInBonusCollection = useCallback(async (gameCollectionId) => {
    const response = await execute(BonusService.getAllGameCodesInBonusCollection(gameCollectionId))
    if (response?.data) update(response.data)
  }, [])

  const update = useCallback(async (gameCodes: string[]) => {
    const eligibleWageringBonusGames = gameCodes.reduce((prevObject, gameCode) => {
      prevObject[gameCode] = true
      return prevObject
    }, {})

    dispatch(updateEligibleWageringBonusGames({ eligibleWageringBonusGames }))
  }, [])

  return useMemo(() => ({ update, fetchAllGameCodeInBonusCollection }), [update, fetchAllGameCodeInBonusCollection])
}

export const useInitializeWageringBonusGames = () => {
  const activateBonus = useActiveBonusInWageringProcess()
  const gameColelctionId = useDebounce(activateBonus?.gameCollectionId, 1000)

  const { fetchAllGameCodeInBonusCollection, update } = useUpdateWageringBonusFeaturedGames()
  useEffect(() => {
    if (gameColelctionId) fetchAllGameCodeInBonusCollection(gameColelctionId)
    else update([])
  }, [gameColelctionId])
}
