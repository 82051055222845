import { useCallback, useEffect, useRef } from 'react'
import { HunnyRequest } from 'services/types'

export const useRequest = () => {
  const requests = useRef([])

  const cancelAllRequest = useCallback(() => {
    while (requests.current.length) {
      const request = requests.current.pop()
      request.cancel()
    }
  }, [])

  useEffect(() => {
    return () => {
      // Cancel all pending request before component destroyed
      cancelAllRequest()
    }
  }, [cancelAllRequest])

  async function execute<S>(request: HunnyRequest<S>): Promise<S> {
    requests.current.push(request)
    return request.call()
  }

  return { execute, cancelAllRequest }
}
