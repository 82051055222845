import { PRODUCT_NAME } from 'config/constants/variables'

export const REFERRAL_SOCIAL_SHARE_CONTENT = `Follow me on ${PRODUCT_NAME}. Let's hunt for $$$ together! Get an exciting experience on %231 Crypto iGaming platform with daily payouts, fastest deposits and withdrawals!`
export const REFERRAL_PROMO_MATERIAL_URL =
  'https://drive.google.com/drive/folders/1pTW_Mc0CcRQwzK6x3pLmxoDdgMley0eC?usp=sharing'
export const REFERRAL_COMMISION_MAX_PERCENT = 1.5

export const getReferralShareUrl = ({
  content,
  appSiteUrl,
  type,
}: {
  content: string
  appSiteUrl: string
  type: 'facebook' | 'telegram' | 'twitter' | 'reddit'
}): string => {
  if (type === 'facebook')
    return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(appSiteUrl)}&quote=${content}`
  if (type === 'twitter')
    return `https://twitter.com/intent/tweet?url=${encodeURIComponent(appSiteUrl)}&text=${content}`
  if (type === 'telegram') return `https://telegram.me/share/url?url=${encodeURIComponent(appSiteUrl)}&text=${content}`
  if (type === 'reddit') return `https://www.reddit.com/submit?url=${encodeURIComponent(appSiteUrl)}&text=${content}`
}

