import { ChainIdEnum } from 'config/constants/network'
import { APIEndpointEnum } from 'config/constants/server'
import { Token } from 'config/types'
import {
  BonusTransaction,
  QueryDepositInfo,
  RewardTransaction,
  SwapTransaction,
  Transaction,
} from 'config/types/transaction'
import BonusService from 'services/BonusService'
import { formatToApiNetworkField, getChainCode } from 'utils'
import { isSolToken } from 'utils/token'
import { UserBonus } from 'config/types/bonus/userBonus'
import { HunnyPlayRequest } from './HunnyPlayRequest'
import {
  BalanceResponse,
  BaseResponse,
  HunnyRequest,
  Paging,
  PrepareSignMessageResponse,
  PrepareTokenNetworkPayload,
  PrepareWithdrawPayload,
  VerificationResponse,
} from './types'

class PaymentService extends HunnyPlayRequest {
  public getBalances(): HunnyRequest<BaseResponse<Paging<BalanceResponse>>> {
    const result = this._post(APIEndpointEnum.Balances)

    return result
  }

  public traditionalDeposit(params: {
    currency: string
    network: string
  }): HunnyRequest<BaseResponse<{ address: string; minAmount: string }>> {
    const result = this._post(APIEndpointEnum.TraditionalDeposit, params)

    return result
  }

  public getWithdrawFee({
    chainType,
    chainCode,
    currency,
  }: PrepareTokenNetworkPayload): HunnyRequest<BaseResponse<{ withdrawFee: string }>> {
    const result = this._post(APIEndpointEnum.WithdrawFee, {
      chain_type: chainType,
      chain_code: chainCode,
      currency,
    })

    return result
  }

  public prepareTraditionalWithdraw(params: PrepareWithdrawPayload): HunnyRequest<BaseResponse<VerificationResponse>> {
    const result = this._post(APIEndpointEnum.PrepareTraditionalWithdraw, {
      chain_code: params.chainCode,
      to_address: params.toAddress,
      chain_type: params.chainType,
      currency: params.currency,
      fee: params.fee,
      value: params.value,
    })
    return result
  }

  public prepareWalletWithdraw(
    value: string,
    account: string,
    currency: Token,
  ): HunnyRequest<BaseResponse<PrepareSignMessageResponse>> {
    const payload = {
      value,
      to_address: account,
      currency: currency.code,
      chain_type: isSolToken(currency) ? 'solana' : 'evm',
      chain_code: getChainCode(ChainIdEnum[currency.network]),
      chain_id: isSolToken(currency) ? null : currency.network,
    }

    const result = this._post(APIEndpointEnum.PrepareWalletWithdraw, payload)

    return result
  }

  public async traditionalWithdraw(params: PrepareWithdrawPayload): Promise<BaseResponse<string>> {
    const result = await this._request(APIEndpointEnum.TraditionalWithdraw, {
      chain_code: params.chainCode,
      chain_type: params.chainType,
      currency: params.currency,
      value: params.value,
      fee: params.fee,
      otp: params.otp,
      to_address: params.toAddress,
      verification_code: params.verificationCode,
    })

    return result
  }

  public async walletWithdraw(sign: string, acceptMessage: any): Promise<BaseResponse<string>> {
    const result = await this._request(APIEndpointEnum.WalletWithdraw, {
      accept_message: acceptMessage,
      message_signature: sign,
    })
    return result
  }

  public getWithdrawal(code: string): HunnyRequest<BaseResponse<Transaction>> {
    const result = this._post(APIEndpointEnum.WithdrawalTransaction, {
      id: Number(code),
    })
    return result
  }

  public getWithdrawals(offset: number, limit: number): HunnyRequest<BaseResponse<Paging<Transaction>>> {
    const result = this._post(APIEndpointEnum.WithdrawalTransactions, {
      paging: {
        offset,
        limit,
      },
    })
    return result
  }

  public getDeposit(hash: string, currency: string, network: ChainIdEnum): HunnyRequest<BaseResponse<Transaction>> {
    const result = this._post(APIEndpointEnum.DepositTransaction, {
      network: formatToApiNetworkField(ChainIdEnum[network]),
      txn_hash: hash,
      currency,
    })

    return result
  }

  public getDeposits(
    offset: number,
    limit: number,
  ): HunnyRequest<BaseResponse<Paging<{ item: Transaction; bonusInfo: UserBonus }>>> {
    const _source = this.AxiosCancelToken

    const call = async (): Promise<BaseResponse<Paging<{ item: Transaction; bonusInfo: UserBonus }>>> => {
      let depositResponse = await this._post(APIEndpointEnum.DepositTransactions, {
        paging: {
          offset,
          limit,
        },
      }).call()

      const depositBonusRecord: Record<string, UserBonus> = {}
      if (depositResponse?.data && !!depositResponse.data.items?.length) {
        const depositIds: number[] = depositResponse.data.items.reduce(
          (listDepositIds, currenntTransaction) => listDepositIds.concat(currenntTransaction.id),
          [],
        )

        const userDepositBonus = await BonusService.getUserDepositBonuses(depositIds).call()
        if (userDepositBonus.data && !!userDepositBonus.data.items.length) {
          userDepositBonus.data.items.forEach((bonusInfo) => {
            if (bonusInfo.depositId) depositBonusRecord[bonusInfo.depositId] = bonusInfo
          })
        }

        depositResponse = {
          ...depositResponse,
          data: {
            ...depositResponse.data,
            items: depositResponse.data.items.map((item: Transaction) => {
              return {
                id: item.id,
                item,
                bonusInfo: depositBonusRecord[item.id],
              }
            }),
          },
        }
      }

      return depositResponse
    }

    return {
      call,
      cancel: () => {
        _source.cancel()
      },
    }
  }

  public getRewards(offset: number, limit: number): HunnyRequest<BaseResponse<Paging<RewardTransaction>>> {
    const result = this._post(APIEndpointEnum.RewardTransactions, {
      paging: {
        offset,
        limit,
      },
    })

    return result
  }

  public getBonuses(): HunnyRequest<BaseResponse<Paging<BonusTransaction>>> {
    const result = this._post(APIEndpointEnum.BonusTransactions)

    return result
  }

  public getSwaps(offset: number, limit: number): HunnyRequest<BaseResponse<Paging<SwapTransaction>>> {
    const result = this._post(APIEndpointEnum.SwapTransactions, {
      paging: {
        offset,
        limit,
      },
    })

    return result
  }

  public async subscribeDeposit(chainId: ChainIdEnum): Promise<void> {
    await this._request(APIEndpointEnum.SubscribeDeposit, {
      network: formatToApiNetworkField(ChainIdEnum[chainId]),
    })
  }

  public async subscribeDepositByHash(
    currency: string,
    chainId: ChainIdEnum,
    txnHash: string,
  ): Promise<BaseResponse<{ id: number }>> {
    const response = await this._request(APIEndpointEnum.SubscribeDepositByHash, {
      currency,
      network: formatToApiNetworkField(ChainIdEnum[chainId]),
      txn_hash: txnHash,
    })

    return response
  }

  public queryDepositByHash(id: number): HunnyRequest<BaseResponse<QueryDepositInfo>> {
    const result = this._post(APIEndpointEnum.QueryDepositByHash, { id })

    return result
  }
}

const instance = new PaymentService()
export default instance

