import { NotificationTypeEnums } from 'config/types/notification'
import { usePrivateSocket } from 'hooks/usePrivateSocket'
import { usePublicSocket } from 'hooks/usePublicSocket'
import NotificationItem from 'layout/Components/Notification/NotificationItem'
import { useCallback, useEffect, useMemo } from 'react'
import { parseNotificationItem } from 'services/mapper/utils'
import { useAppDispatch, useAppSelector } from 'state'
import { useAuth } from 'state/auth/hooks'
import { showToast } from 'utils/toastify'
import {
  updateOpenNotification,
  updateUnreadPrivateNotificationAmount,
  updateUnreadSystemNotificationAmount,
} from './actions'

export const useListenPrivateNotification = () => {
  const privateSocket = usePrivateSocket()
  const { isSigned } = useAuth()
  const { updateUnreadPrivateAmount, unreadPrivateAmount } = useUnreadNotificationAmount()

  useEffect(() => {
    if (!isSigned || !privateSocket) {
      return
    }

    privateSocket.on('notification.personal', (data) => {
      if (data) {
        updateUnreadPrivateAmount(unreadPrivateAmount + 1)
        showToast(
          <NotificationItem
            notification={{
              ...parseNotificationItem(data),
              type: NotificationTypeEnums.PRIVATE,
            }}
            minWidth={320}
            background="transparent !important"
            hideExtendDescButton
          />,
          {
            toastId: 'new-notification-toast-message',
            className: 'Toastify__notification-container',
            bodyClassName: 'Toastify__notification-body-container',
          },
        )
      }
    })

    return () => {
      if (privateSocket) privateSocket.off('notification.personal')
    }
  }, [isSigned, privateSocket, unreadPrivateAmount])
}

export const useListenSystemNotification = () => {
  const publicSocket = usePublicSocket()
  const { isSigned } = useAuth()
  const { updateUnreadSystemAmount, unreadSystemAmount } = useUnreadNotificationAmount()

  useEffect(() => {
    if (!isSigned || !publicSocket) {
      return
    }

    publicSocket.on('notification.platform', (data) => {
      if (data) updateUnreadSystemAmount(unreadSystemAmount + 1)

      showToast(
        <NotificationItem
          notification={{
            ...parseNotificationItem(data),
            type: NotificationTypeEnums.SYSTEM,
          }}
          minWidth={320}
          background="transparent !important"
          hideExtendDescButton
        />,
        {
          toastId: 'new-notification-toast-message',
          className: 'Toastify__notification-container',
          bodyClassName: 'Toastify__notification-body-container',
        },
      )
    })

    return () => {
      if (publicSocket) publicSocket.off('notification.platform')
    }
  }, [isSigned, publicSocket, unreadSystemAmount])
}

export const useToggleDisplayNotification = () => {
  const dispatch = useAppDispatch()
  const isOpened = useAppSelector((state) => state.notification.isNotificationOpened)

  const toggle = useCallback(
    (toggleDisplay: boolean) => dispatch(updateOpenNotification({ isOpen: toggleDisplay })),
    [],
  )

  return useMemo(
    () => ({
      isOpened,
      toggle,
    }),
    [isOpened, toggle],
  )
}

export const useUnreadNotificationAmount = () => {
  const unreadPrivateAmount = useAppSelector((state) => state.notification.unreadPrivateAmount)
  const unreadSystemAmount = useAppSelector((state) => state.notification.unreadSystemAmount)
  const unreadAmount = useMemo(
    () => unreadPrivateAmount + unreadSystemAmount,
    [unreadPrivateAmount, unreadSystemAmount],
  )

  const dispatch = useAppDispatch()
  const updateUnreadPrivateAmount = useCallback(
    (unreadAmount: number) => dispatch(updateUnreadPrivateNotificationAmount({ unreadAmount })),
    [],
  )
  const updateUnreadSystemAmount = useCallback(
    (unreadAmount: number) => dispatch(updateUnreadSystemNotificationAmount({ unreadAmount })),
    [],
  )

  return useMemo(
    () => ({
      unreadAmount,
      updateUnreadPrivateAmount,
      updateUnreadSystemAmount,
      unreadPrivateAmount,
      unreadSystemAmount,
    }),
    [unreadAmount, unreadPrivateAmount, unreadSystemAmount, updateUnreadPrivateAmount, updateUnreadSystemAmount],
  )
}
