import Box from 'components/Box/Box'
import { BoxProps } from 'components/Box/types'
import useSuggestedLogin from 'hooks/useSuggestedLogin'
import { useMemo, useState } from 'react'
import { AuthModalPageEnums } from 'views/Authentication/types'
import TraditionalLoginForm from './TraditionalLoginForm'
import TraditionalSignUpForm from './TraditionalSignUpForm'

type TraditionalAuthProps = {
  page: AuthModalPageEnums
  hide?: boolean
  setPage: (page: AuthModalPageEnums) => void
}

const TraditionalAuth: React.FC<TraditionalAuthProps & BoxProps> = ({ page, setPage, hide, ...props }) => {
  const { previousUserEmail } = useSuggestedLogin()
  const [draftEmail, setDraftEmail] = useState('')

  const initialEmail = useMemo(() => {
    if (previousUserEmail && !draftEmail && page === AuthModalPageEnums.LOG_IN) {
      return previousUserEmail
    }
    return draftEmail
  }, [draftEmail, page])

  return (
    !hide && (
      <Box {...props}>
        {page === AuthModalPageEnums.SIGN_UP && (
          <TraditionalSignUpForm saveEmail={setDraftEmail} initialEmail={initialEmail} setPage={setPage} />
        )}
        {page === AuthModalPageEnums.LOG_IN && (
          <TraditionalLoginForm saveEmail={setDraftEmail} initialEmail={initialEmail} setPage={setPage} />
        )}
      </Box>
    )
  )
}

export default TraditionalAuth
