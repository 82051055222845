import React from 'react'
import styled from 'styled-components'
import OpenEffect from 'components/OpenEffect'
import Skeleton from 'components/Skeleton'
import { BoxProps } from 'components/Box/types'

const GameItemLoader: React.FC<BoxProps> = ({ ...props }) => {
  return (
    <StyledWrapper {...props}>
      <StyledSkeleton width="100%" paddingBottom="100% !important" />
    </StyledWrapper>
  )
}

const StyledWrapper = styled(OpenEffect).attrs({ openType: 'fade' })`
  width: 100%;
  padding-bottom: 100%;
  padding: 12px;
`

const StyledSkeleton = styled(Skeleton)`
  background-color: ${({ theme }) => theme.colors.background};
`
export default GameItemLoader
