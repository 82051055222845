import { TokenAmount } from '..'
import { BonusStatusEnums, BonusUserTypeEnums } from './userBonus'

export enum BonusStatusFilterEnums {
  Available = 1,
  History = -1,
  Active = 2,
}

export enum ApiBonusTypeEnums {
  HusdLockReward = 1,
  HusdUnlockBooster = 2,
  IncreaseVIP = 10,
  LuckspinReward = 20,
  DepositBonus = 30,
  FreeSpinBonus = 31,
  NoDepositFreeSpinBonus = 32,
  NoDepositBonus = 33,
}

export abstract class CommonBonus {
  code: string
  image: string
  name?: string
  badge?: string
  type?: ApiBonusTypeEnums
  instruction?: string
  maxUserLevel?: number
  minUserLevel?: number
  userType?: BonusUserTypeEnums

  status?: BonusStatusEnums

  constructor(bonus: CommonBonus) {
    this.image = bonus.image
    this.badge = bonus.badge
    this.name = bonus.name
    this.code = bonus.code
    this.instruction = bonus.instruction
    this.maxUserLevel = bonus.maxUserLevel
    this.userType = bonus.userType
    this.minUserLevel = bonus.minUserLevel

    this.status = bonus.status
    this.type = bonus.type
  }
}

// ---------------------- Husd Lock Reward -------------------------- HusdLockReward

export class HusdLockRewardCommonBonus extends CommonBonus {
  amount: string

  constructor(bonus: CommonBonus, amount: string) {
    super(bonus)
    this.amount = amount
  }
}

// ----------------------  HUSD Unlock Booster  -------------------------- HusdUnlockBooster

export class HUSDUnLockBoosterCommonBonus extends CommonBonus {
  duration?: number
  extraUnlockPercent?: number

  constructor(bonus: CommonBonus, duration: number, percent: number) {
    super(bonus)
    this.duration = duration
    this.extraUnlockPercent = percent
  }
}

// ---------------------- Level Up -------------------------- IncreaseVIP
export interface ILevelUpCommonBonus {
  duration: number
  maxLevel: number
  upgradeCount: number
}

export class LevelUpCommonBonus extends CommonBonus {
  duration?: number
  maxLevel?: number
  extraLevels?: number

  constructor(bonus: CommonBonus, levelUpCommonBonus: ILevelUpCommonBonus) {
    super(bonus)
    this.duration = levelUpCommonBonus.duration
    this.maxLevel = levelUpCommonBonus.maxLevel
    this.extraLevels = levelUpCommonBonus.upgradeCount
  }
}

// ---------------------- Lucky Spin Reward -------------------------- LuckspinReward

export class LuckSpinRewardCommonBonus extends CommonBonus {
  amount?: number

  constructor(bonus: CommonBonus, quantity: number) {
    super(bonus)
    this.amount = quantity
  }
}

// ---------------------- Deposit Common Bonus -------------------------- DepositBonus
export interface IDepositCommonBonus {
  // Deposit Condition
  minDeposit?: string
  activeDurationInDays: number

  // Bonus Amount Condition
  percent?: number
  maxBonusAmountInUsd: string
  wagerMultiplier: number

  // Bet condition
  wagerDurationInDays: number
  maxBet: number
  minBet: number
  gameCollectionId: number

  // Bonus amount
  bonusAmount?: TokenAmount
}

export class DepositCommonBonus extends CommonBonus {
  // Deposit Condition
  minDeposit: string
  activeDurationInDays: number

  // Bonus Amount Condition
  percent: number
  maxBonusAmountInUsd: string
  wagerMultiplier: number

  // Bet condition
  wagerDurationInDays: number
  maxBet: number
  minBet: number
  gameCollectionId: number

  constructor(bonus: CommonBonus, depositCommonBonus: IDepositCommonBonus) {
    super(bonus)
    this.minDeposit = depositCommonBonus.minDeposit
    this.activeDurationInDays = depositCommonBonus.activeDurationInDays

    this.percent = depositCommonBonus.percent
    this.maxBonusAmountInUsd = depositCommonBonus.maxBonusAmountInUsd
    this.wagerMultiplier = depositCommonBonus.wagerMultiplier

    this.wagerDurationInDays = depositCommonBonus.wagerDurationInDays
    this.maxBet = depositCommonBonus.maxBet
    this.minBet = depositCommonBonus.minBet
    this.gameCollectionId = depositCommonBonus.gameCollectionId
  }
}

export class NoDepositCommonBonus extends CommonBonus {
  // Deposit Condition
  minDeposit: string
  activeDurationInDays: number

  // Bonus Amount Condition
  maxBonusAmountInUsd: string
  wagerMultiplier: number

  // Bet condition
  wagerDurationInDays: number
  maxBet: number
  minBet: number
  gameCollectionId: number
  bonusAmount: TokenAmount

  constructor(bonus: CommonBonus, depositCommonBonus: IDepositCommonBonus) {
    super(bonus)
    this.minDeposit = depositCommonBonus.minDeposit
    this.activeDurationInDays = depositCommonBonus.activeDurationInDays

    this.maxBonusAmountInUsd = depositCommonBonus.maxBonusAmountInUsd
    this.wagerMultiplier = depositCommonBonus.wagerMultiplier

    this.wagerDurationInDays = depositCommonBonus.wagerDurationInDays
    this.maxBet = depositCommonBonus.maxBet
    this.minBet = depositCommonBonus.minBet
    this.gameCollectionId = depositCommonBonus.gameCollectionId
    this.bonusAmount = depositCommonBonus.bonusAmount
  }
}

// ---------------------- Free Spin Common Bonus -------------------------- FreeSpinBonus
export interface IFreeSpinCommonBonus {
  freeSpinAmount: number
  spinDurationInDays: number
  spinGameIds: number
  spinPrice: number

  minDeposit: string
  maxBonusAmountInUsd: string
  wagerMultiplier: number
}

export class FreeSpinCommonBonus extends CommonBonus {
  freeSpinAmount: number
  spinDurationInDays: number
  spinGameIds: number
  spinPrice: number
  minDeposit: string
  maxBonusAmountInUsd: string
  wagerMultiplier: number

  constructor(bonus: CommonBonus, freeSpinCommonBonus: IFreeSpinCommonBonus) {
    super(bonus)
    this.freeSpinAmount = freeSpinCommonBonus.freeSpinAmount
    this.spinDurationInDays = freeSpinCommonBonus.spinDurationInDays
    this.spinGameIds = freeSpinCommonBonus.spinGameIds
    this.spinPrice = freeSpinCommonBonus.spinPrice
    this.minDeposit = freeSpinCommonBonus.minDeposit
    this.maxBonusAmountInUsd = freeSpinCommonBonus.maxBonusAmountInUsd
    this.wagerMultiplier = freeSpinCommonBonus.wagerMultiplier
  }
}

// ---------------------- No Deposit Free Spin Common Bonus -------------------------- NoDepositFreeSpinBonus

export class NoDepositFreeSpinCommonBonus extends CommonBonus {
  freeSpinAmount: number
  spinDurationInDays: number
  spinGameIds: number
  spinPrice: number
  minDeposit: string
  maxBonusAmountInUsd: string
  wagerMultiplier: number

  constructor(bonus: CommonBonus, noDepositFreeSpin: IFreeSpinCommonBonus) {
    super(bonus)
    this.freeSpinAmount = noDepositFreeSpin.freeSpinAmount
    this.spinDurationInDays = noDepositFreeSpin.spinDurationInDays
    this.spinGameIds = noDepositFreeSpin.spinGameIds
    this.spinPrice = noDepositFreeSpin.spinPrice
    this.minDeposit = noDepositFreeSpin.minDeposit
    this.maxBonusAmountInUsd = noDepositFreeSpin.maxBonusAmountInUsd
    this.wagerMultiplier = noDepositFreeSpin.wagerMultiplier
  }
}
