import { APIEndpointEnum, BASE_API } from 'config/constants/server'
import { ManagerOptions, SocketOptions } from 'socket.io-client'
import { BaseSocket } from './base'

class PublicSocket extends BaseSocket {
  constructor() {
    super(`${BASE_API}public`)
  }

  protected _buildConfig(): Partial<ManagerOptions & SocketOptions> {
    return {
      path: APIEndpointEnum.PublicSocket,
      withCredentials: true,
      transports: ['websocket', 'polling'],
      reconnection: false,
    }
  }
}

const publicSocket = new PublicSocket()
export default publicSocket
