import Box from 'components/Box/Box'
import { BoxProps } from 'components/Box/types'
import Image from 'components/Image'
import { ImageProps } from 'components/Image/types'
import { NETWORK_MAP } from 'config/constants/network'
import { Token } from 'config/types'

const TokenLogo: React.FC<
  { token: Token } & BoxProps & { tokenImageProps?: ImageProps; networkImageProps?: ImageProps; hideNetwork?: boolean }
> = ({ token, tokenImageProps, networkImageProps, hideNetwork, ...props }) => (
  <Box {...props} position="relative">
    <Image src={token.logo} alt={`${token.name} LOGO`} width={24} height={24} {...tokenImageProps} />
    {!token.isNative && !hideNetwork && (
      <Box position="absolute" top="-3px" right="-2px">
        <Image
          src={NETWORK_MAP[token.network].networkInfo.icon}
          alt={`${token.name} LOGO`}
          width={12}
          height={12}
          {...networkImageProps}
        />
      </Box>
    )}
  </Box>
)

export default TokenLogo
