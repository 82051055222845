import Box from 'components/Box/Box'
import { InputProps } from 'components/Input'
import InputAdornment from 'components/Input/InputAdornment'
import RemoveValueInputAdornment from 'components/Input/InputAdornment/RemoveValueInputAdornment'
import { InputContainer, StyledInput } from 'components/Input/styled'
import React from 'react'

export interface FormInputProps {
  control: InputProps
  placeholder?: string
  type?: string
  name?: string
  adornment?: React.ReactElement
  icon?: React.ReactElement
  autoComplete?: string
  maxLength?: number
  size?: number
  min?: number
  max?: number
  pattern?: string
  tabIndex?: number
  disabled?: boolean
}

export interface FormCheckboxProps {
  control: InputProps
}

const addRemoveValueAdornment = (adornment: React.ReactElement, value: string, setValue: (value: string) => void) => {
  return (
    <>
      {adornment}
      <RemoveValueInputAdornment
        value={value}
        onRemove={() => {
          setValue('')
        }}
      />
    </>
  )
}

const FormInput: React.FC<FormInputProps> = ({ control, adornment, icon, ...props }) => {
  return (
    <InputContainer>
      <InputAdornment
        endAdornment={addRemoveValueAdornment(adornment, control.value, control.onValueChanged)}
        p="0px 12px"
      >
        {icon && (
          <Box width="20px" mr="6px">
            {icon}
          </Box>
        )}
        <StyledInput {...control} {...props} />
      </InputAdornment>
    </InputContainer>
  )
}

export default FormInput
