import {
  BaseResponse,
  LoginResponse,
  PasswordNonceResponse,
  PrepareSignMessageResponse,
  RegisterResponse,
  UserDataResponse,
  VerificationResponse,
} from 'services/types'
import { parseBalances } from './Payment'
import { parseUserBonus } from './Voucher'

export const UserDataMapper = (rawResponse: string): BaseResponse<UserDataResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  const data = response?.data

  if (!response || response?.code !== 'success') {
    return {
      code: response?.code || 'error',
      data: null,
    }
  }

  const balances = data?.balances.map((balance) => parseBalances(balance))
  const activeBonus = data?.activate_vouchers.map((voucher) => parseUserBonus(voucher)).filter((item) => item)

  const tier = data?.tier_id
  const totalWager = data?.total_wager || 0

  const sevenDays = 60 * 60 * 24 * 7 * 1000
  const isClaimed = data?.is_received_welcome_package
  const endTime = data?.register_time ? data.register_time * 1000 + sevenDays : 0
  const endTimeAt = isClaimed ? 0 : endTime

  return {
    code: response.code,
    message: response.message,
    data: {
      balances,
      profile: {
        tier,
        totalWager,
      },
      welcomePackage: {
        isClaimed,
        expiredAt: endTimeAt ? (endTimeAt > Date.now() ? endTimeAt : 0) : 0,
      },
      activeBonus,
      notificationUnreadCount: {
        personal: data.noti_unread_count?.personal || 0,
        system: data.noti_unread_count?.platform || 0,
      },
      bonusBalances: {
        HUSD: data.bonus_balances?.find((item) => item.currency === 'HUSD')?.amount || '0',
      },
    },
  }
}

export const PrepareSignMessageMapper = (rawResponse: string): BaseResponse<PrepareSignMessageResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      address: response.data.address,
      acceptMessageText: response.data['accept_message_text'],
      acceptMessage: response.data['accept_message'],
    },
  }
}

export const LoginResponseMapper = (rawResponse: string): BaseResponse<LoginResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    errors: response.errors,
    data: response.data && {
      accessToken: response.data['access_token'],
      refreshToken: response.data['refresh_token'],
      uid: response.data['uid'] || null,
      isSignUp: !response.data['is_login'],
      husdBonusAmount: response.data['husd_bonus_amount'] || undefined,
    },
  }
}

export const PasswordNonceResponseMapper = (rawResponse: string): BaseResponse<PasswordNonceResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  return {
    code: response.code,
    message: response.message,
    errors: response.errors,
    data: response.data && {
      ...response.data.password_nonce,
    },
  }
}

export const VerificationResponseMapper = (rawResponse: string): BaseResponse<VerificationResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  return {
    code: response.code,
    message: response.message,
    errors: response.errors,
    data: response.data && {
      expireTime: response.data.expire_time * 1000,
      nextResentTime: response.data.next_resent_time * 1000,
      token: response.data.token,
    },
  }
}

export const RegisterMapper = (rawResponse: string): BaseResponse<RegisterResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  return {
    code: response.code,
    message: response.message,
    errors: response.errors,
    data: response.data && {
      uid: response.data.uid,
      husdBonusAmount: response.data.husd_bonus_amount || undefined,
    },
  }
}

export const ResetPasswordVerificationResponseMapper = (rawResponse: string): BaseResponse<VerificationResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  return {
    code: response.code,
    message: response.message,
    errors: response.errors,
    data: response.data && {
      expireTime: response.data.verification.data.expired_time * 1000,
      nextResentTime: response.data.verification.data.next_resent_time * 1000,
      token: response.data.verification.data.token,
    },
  }
}

export const NewPasswordNonceMapper = (rawResponse: string): BaseResponse<PasswordNonceResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  return {
    code: response.code,
    message: response.message,
    errors: response.errors,
    data: response.data && {
      ...response.data.new_password_nonce,
      token: response.data.token,
    },
  }
}
