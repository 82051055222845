import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Button from 'components/Button/Button'
import BackgroundImage from 'components/Image/BackgroundImage'
import { ModalBody, ModalProps } from 'components/Modal'
import Modal from 'components/Modal/Modal'
import OpenEffect from 'components/OpenEffect'
import Tab from 'components/Tab'
import Tabs from 'components/Tabs'
import Text from 'components/Text/Text'
import { RouteConfig } from 'config/constants/route'
import { WelcomePackItemType } from 'config/types/bonus/welcomePackage'
import useAuthenticationModal from 'hooks/useAuthenticationModal'
import { useRouter } from 'hooks/useRouter'
import { ColumnCenter } from 'layout/Components/Column'
import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import BonusService from 'services/BonusService'
import { useAppDispatch } from 'state'
import { useAuth } from 'state/auth/hooks'
import { useActivatedBonuses, useUserWelcomePackage, useWelcomePackage } from 'state/bonus/hooks'
import { updateUserWelcomePackageInfo } from 'state/profile/actions'
import styled from 'styled-components'
import theme from 'theme'
import { HunnyToast } from 'utils/toastify'
import { AuthModalPageEnums } from 'views/Authentication/types'
import UserWelcomePackageCountdown from 'views/BonusCenter/components/WelcomePackageSection/UserWelcomePackageCountdown'
import WelcomePackageItem from 'views/BonusCenter/components/WelcomePackageSection/WelcomePackModal/WelcomePackItem'
import WelcomePackageBonusRow from './WelcomePackageBonusRow'

const WelcomePackModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const userWelcomePackages = useUserWelcomePackage()
  const { welcomePackage: welcomePackages } = useWelcomePackage()
  const [activePackage, setActivePackage] = useState<WelcomePackItemType>(
    welcomePackages?.packages.length ? welcomePackages.packages[1] : null,
  )
  const { fetchActivatedBonuses } = useActivatedBonuses()
  const dispatch = useAppDispatch()
  const { hasSession } = useAuth()
  const { t } = useTranslation()

  const [presentAuthenticationModal] = useAuthenticationModal()
  const router = useRouter()

  const handleChooseWelcomePack = useCallback(async () => {
    if (userWelcomePackages.expiredAt.getTime() === 0) {
      HunnyToast.error(t('Failed!'), t('Sorry, the promotion has been ended. Please check again later!'))
      onDismiss()
      return
    }

    const isSucceed = await BonusService.chooseUserWelcomePackage(activePackage.code)

    if (isSucceed) {
      await fetchActivatedBonuses()
      HunnyToast.success(t('Congrats! You have received {{amount}} bonuses', { amount: activePackage.bonuses.length }))
      router.push(RouteConfig.BonusCenter)
      dispatch(
        updateUserWelcomePackageInfo({
          expiredAt: 0,
          isClaimed: true,
        }),
      )

      onDismiss()
    }
  }, [activePackage, userWelcomePackages])

  const Animation = useMemo(() => {
    return (props) => <OpenEffect {...props} />
  }, [activePackage])

  return (
    <Modal
      onDismiss={onDismiss}
      minWidth={['100%', '100%', 'min(100vw, 665px) !important', '665px !important']}
      height="100%"
    >
      <ModalBody>
        <Box position="absolute" width="100%" height="100%" zIndex={0}>
          <BackgroundImage src="/images/bonusCenter/choose-welcome-package-bg.png" width="100%" height="100%" />
        </Box>

        <StyledContainer p={[10, 20, 20, 40]} overflow="auto" zIndex={1}>
          <ColumnCenter height={['unset', '', '100%']}>
            <Text fontSize={['18px', '20px', '32px']} fontWeight={800} textAlign="center" mt={['24px', '24px', 0]}>
              <Trans>Choose your Welcome pack</Trans>
            </Text>

            <Text
              fontSize={['12px', '12px', '14px']}
              textAlign="center"
              mt={2}
              mb={28}
              color="textTertiary"
              lineHeight="17px"
              maxWidth="560px"
            >
              <Trans>
                Upon selecting the pack with Free Spins and Deposit Bonuses, it will be automatically added to your
                Bonus Center
              </Trans>
            </Text>

            <StyledPackageTabs
              value={activePackage?.code}
              onTagChanged={(packageCode) => {
                welcomePackages.packages.map((welcomePackage) =>
                  welcomePackage.code === packageCode ? setActivePackage(welcomePackage) : null,
                )
              }}
              width={['100%', '100%', 'auto']}
              minHeight={[180, 180, 202]}
              maxHeight={[180, 180, 202]}
              hideIndicator
            >
              {welcomePackages &&
                welcomePackages.packages.map((packageInfo) => {
                  return (
                    <StyledPackageTab
                      value={packageInfo.code}
                      id={`welcomePackageModal-packageTab-${packageInfo.code}`}
                    >
                      <WelcomePackageItem
                        welcomePackage={packageInfo}
                        isActive={packageInfo.code === activePackage?.code}
                        borderRadius={packageInfo.code === activePackage.code ? theme.radii.large : ''}
                      />
                    </StyledPackageTab>
                  )
                })}
            </StyledPackageTabs>
            <StyledWelcomePackageContent p={[10, 20, 20]}>
              <Box minHeight={hasSession ? ['480px', , '220px'] : ['480px', , '260px']} width="100%">
                {activePackage?.bonuses?.map((bonus, index) => {
                  return (
                    <Animation key={index} openType="grow" duration={0.3} delay={(index + 1) * 0.1}>
                      <WelcomePackageBonusRow
                        bonus={bonus}
                        order={
                          (index + 1).toString() +
                          (index == 2
                            ? `-${activePackage.code === 'bronze' ? 8 : activePackage.code === 'silver' ? 10 : 12} (x${
                                activePackage.code === 'bronze' ? 6 : activePackage.code === 'silver' ? 8 : 10
                              })`
                            : '')
                        }
                        key={index}
                        borderBottom={
                          activePackage.bonuses && index !== activePackage.bonuses.length - 1
                            ? `1px solid ${theme.colors.stroke}`
                            : ''
                        }
                      />
                    </Animation>
                  )
                })}
              </Box>
              {hasSession ? (
                <StyledButtonGroup bottom="0px" width="100%">
                  <ColumnCenter>
                    <Button
                      id={'welcomePackageModal-chooseBonusBtn'}
                      variant="primary"
                      width={[240, 240, 360]}
                      height={[48]}
                      mt={[3, 3, 2]}
                      disabled={userWelcomePackages.expiredAt.getTime() === 0}
                      onClick={handleChooseWelcomePack}
                    >
                      {userWelcomePackages.expiredAt.getTime() > 0 ? (
                        <Text fontSize="14px" fontWeight={700}>
                          <Trans>Choose bonus</Trans>
                        </Text>
                      ) : userWelcomePackages.isClaimed ? (
                        <Text fontSize="14px" fontWeight={700} color="textSubtle">
                          <Trans>You've chosen the bonus</Trans>
                        </Text>
                      ) : (
                        <Text fontSize="14px" fontWeight={700} color="textSubtle">
                          <Trans>For new users only</Trans>
                        </Text>
                      )}
                    </Button>

                    {userWelcomePackages?.expiredAt.getTime() > 0 && (
                      <UserWelcomePackageCountdown background="transparent !important" mt={2} />
                    )}
                  </ColumnCenter>
                </StyledButtonGroup>
              ) : (
                <StyledButtonGroup bottom="0px" width="100%">
                  <ColumnCenter>
                    <Button
                      id={'welcomePackageModal-signUpNChooseBonusBtn'}
                      variant="primary"
                      width={[240, 240, 360]}
                      height={[48]}
                      mt={[3, 3, 2]}
                      mb={[2, 2, 1]}
                      onClick={() => presentAuthenticationModal({ page: AuthModalPageEnums.SIGN_UP })}
                    >
                      <Text fontSize="14px" fontWeight={700}>
                        <Trans>Sign up & Choose bonus</Trans>
                      </Text>
                    </Button>
                  </ColumnCenter>
                </StyledButtonGroup>
              )}
            </StyledWelcomePackageContent>
          </ColumnCenter>
        </StyledContainer>
      </ModalBody>
    </Modal>
  )
}

const StyledContainer = styled(Box)`
  ::-webkit-scrollbar {
    width: 0px !important;
  }

  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    ::-webkit-scrollbar {
      width: 4px !important;
    }
  }
`
const StyledButtonGroup = styled(Box)`
  width: 100vw;
  left: 0px;
  bottom: 0px;
  padding-bottom: 8px;
  background: ${({ theme: { colors } }) => colors.cardBackground}ee;
  border-radius: 0px 0px;
  position: fixed;

  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    backdrop-filter: unset;
    background: unset;
    position: static;
    width: unset;
    padding-bottom: unset;
  }
`

const StyledWelcomePackageContent = styled(ColumnCenter)`
  background: linear-gradient(
    360deg,
    rgba(255, 255, 255, 0) -86.45%,
    rgba(255, 255, 255, 0.0086288) -55.98%,
    rgba(255, 255, 255, 0.025605) 21.33%,
    rgba(255, 255, 255, 0.0701883) 98.71%,
    rgba(255, 255, 255, 0.12) 118.93%
  );
  border-radius: 12px;
  width: 100%;
`

const StyledPackageTab = styled(Tab)`
  display: flex;
  align-items: flex-end;
`
const StyledPackageTabs = styled(Tabs)`
  display: flex;
  align-items: flex-end;

  ${StyledPackageTab} {
    margin: 0 3px;
    & > ${Flex} {
      border-radius: ${({ theme: { radii } }) => radii.default};
    }

    ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
      margin: 0px;
      & > ${Flex} {
        border-radius: 0px;
      }
    }

    &:first-child {
      & > ${Flex} {
        border-top-left-radius: ${({ theme: { radii } }) => radii.default};
      }
    }

    &:last-child {
      & > ${Flex} {
        border-top-right-radius: ${({ theme: { radii } }) => radii.default};
      }
    }
  }
`

export default WelcomePackModal
