import { FetchingStatus } from 'config/constants'
import { Token } from 'config/types'
import { useSigner } from 'packages/wagmi/hooks/useSigner'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppSelector } from 'state'
import { approve } from 'utils/infura'

function useApproveDepositContract(token: Token) {
  const signer = useSigner()
  const [approveProcess, setApproveProcess] = useState<FetchingStatus>(FetchingStatus.Unknown)

  const tokenInfoes = useAppSelector((state) => state.app.tokenInfoes)
  const tokenDepositInfo = useMemo(() => {
    if (!token) return null

    const spender = tokenInfoes.find(
      (tokenInfo) => tokenInfo.network === token.network && tokenInfo.token === token.code,
    )?.contractAddress

    if (!spender) return null

    return {
      spender,
      token,
    }
  }, [token])

  useEffect(() => {
    setApproveProcess(FetchingStatus.Unknown)
  }, [tokenDepositInfo])

  const approveToken = useCallback(async () => {
    if (!tokenDepositInfo) {
      return null
    }

    if (tokenDepositInfo.token.isNative) {
      return null
    }

    setApproveProcess(FetchingStatus.Fetching)
    const hash = await approve(signer, tokenDepositInfo.token, tokenDepositInfo.spender)
    setApproveProcess(FetchingStatus.Fetched)

    return hash
  }, [tokenDepositInfo, signer])

  return {
    approve: approveToken,
    approveProcess,
  }
}

export default useApproveDepositContract
