import { keyframes } from 'styled-components'
import theme from 'theme'

export const hunnyKeyframes = {
  fade: keyframes`
		0% {
			opacity: 0.5;
		}
		100% {
			opacity: 1;
		};
	`,
  fadeOut: keyframes`
		0% {
			opacity: 1
		}
		100% {
			opacity: 0
		}
	`,
  zoom: keyframes`
		0% {
			transform: scale(0.9);
			opacity: 0.5;
		}
		100% {
			transform: scale(1);
			opacity: 1;
		};
	`,
  grow: keyframes`
		0% {
			transform: translateY(20px);	
			opacity: 0;
		}
		100% {
			transform: translateY(0px);
			opacity: 1;
		};
	`,
  slideX: keyframes`
		0% {
			transform: translateX(-90px);	
			opacity: 0.5;
		}
		100% {
			transform: translateX(0px);
			opacity: 1;
		};
	`,
  slideTopToBottom: keyframes`
		0% {
			transform: translateY(-20px);	
			opacity: 0.5;
		}
		100% {
			transform: translateY(0px);
			opacity: 1;
		};
	`,
  slideBottomToTop: keyframes`
		0% {
			transform: translateY(20px);	
			opacity: 0.5;
		}
		100% {
			transform: translateY(0px);
			opacity: 1;
		};
	`,
  fullSlideRightToLeft: keyframes`
		0% {
			transform: translateX(100%);	
		}
		100% {
			transform: translateX(0);	
		};
	`,
  fullSlideLeftToRight: keyframes`
		 0% {
			 transform: translateX(0);	
		 }
	 100% {
		 transform: translateX(100%);	
	 };
 `,
  rotate: keyframes`
		 	0% { 
    -webkit-transform: rotate(0deg);
     transform: rotate(0deg);
		}
		100% { 
			-webkit-transform: rotate(360deg); 
			transform: rotate(360deg);
		}
	`,
  dash: keyframes`
	 0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
	`,
  dashChecked: keyframes`
	  0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
 `,
  ripple: keyframes`
  0% {
    box-shadow: 0px 0px 0px 1px transparent;
  }

  50% { 
    box-shadow: 0px 0px 0px 2.5px ${theme.colors.primary};
  }

  100% {
    box-shadow: 0px 0px 0px 2.5px transparent;
  } 
 `,
  slideOutFadeDown: keyframes`
	0% {}
	100% {
		opacity: 0;
		transform: translateY(10px);
	}
 `,
} as const

export default hunnyKeyframes
