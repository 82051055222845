import Flex from 'components/Box/Flex'
import { FlexProps } from 'components/Box/types'
import Text from 'components/Text'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface EmptyContentProps extends FlexProps {
  content: React.ReactNode | string
  suffixIcon?: React.ReactNode
  prefixIcon?: React.ReactNode
}

const EmptyContent: React.FC<EmptyContentProps> = ({ suffixIcon, prefixIcon, content, ...props }) => {
  const { t } = useTranslation()

  return (
    <Flex alignItems="center" {...props}>
      {suffixIcon || null}
      {typeof content === 'string' ? (
        <Text fontSize="14px" color="textSubtle" bold mx={1}>
          {t(content)}
        </Text>
      ) : (
        content
      )}
      {prefixIcon || null}
    </Flex>
  )
}

export default EmptyContent
