import { ChainIdEnum, NETWORK_MAP } from 'config/constants/network'
import configTokens from 'config/constants/tokens'
import { Token } from 'config/types'
import { formatApiNetworkField } from 'utils'
import { formatDisplayDateTime } from 'utils/dateHelper'

export const parseToLockedHusd = (token: Token): Token => {
  if (!token) return null
  if (token?.code === configTokens[ChainIdEnum.HPN]['HUSD'].code)
    return { ...token, logo: '/images/token/locked-husd.png' }
  return token
}

export const parseToHusd = (token: Token): Token => {
  if (!token) return null
  if (token?.code === configTokens[ChainIdEnum.HPN]['HUSD'].code) return { ...token, logo: '/images/token/hpd.png' }
  return token
}

export const createElement = (domType: string, className: string) => {
  const element = document.createElement(domType)
  element.classList.add(className)

  return element
}

export const displaySpinCountdownUnit = (timeDisplay: string, timeUnit: string, timeValue: number) => {
  return `<div class=${timeUnit?.toLowerCase()}>
    <div class='value'>${timeValue}</div>
    <p class='unit'>${timeDisplay}</p>
  </div>`
}

export const renderNewPrizeIntoTable = (newPrize) => {
  const token = parseToLockedHusd(configTokens[ChainIdEnum[formatApiNetworkField(newPrize.network)]][newPrize.currency])
  const container = createElement('tr', 'container')

  if (token) {
    const createTime = createElement('td', 'create-time')
    createTime.innerHTML = formatDisplayDateTime(new Date(newPrize.createTime))

    const address = createElement('td', 'address')
    address.innerHTML = newPrize.address

    const tokenLogo: any = createElement('img', 'token-logo')
    tokenLogo.src = token.logo

    let networkLogo: any = null
    if (!token.isNative) {
      networkLogo = createElement('img', 'network-logo')
      networkLogo.src = NETWORK_MAP[ChainIdEnum[formatApiNetworkField(newPrize.network)]].networkInfo.icon
    }
    const logoContainter = createElement('div', 'logo-container')
    logoContainter.appendChild(tokenLogo)
    if (networkLogo) logoContainter.appendChild(networkLogo)

    const value = createElement('div', 'prize-value')
    value.innerHTML = newPrize.amount
    const valueContainer = createElement('td', 'prize-value-container')
    valueContainer.appendChild(logoContainter)
    valueContainer.appendChild(value)

    container.appendChild(createTime)
    container.appendChild(address)
    container.appendChild(valueContainer)

    const prizeTableBody = document.querySelector('.prize-table-body')

    if (prizeTableBody) prizeTableBody.prepend(container)
  }
}

export const renderNewPrizeIntoList = (newPrize) => {
  const token = parseToLockedHusd(configTokens[ChainIdEnum[formatApiNetworkField(newPrize.network)]][newPrize.currency])

  if (token) {
    const prizeListContainer = document.querySelector('.prize-list-container')
    const container = createElement('div', 'containter')

    const tokenLogo: any = createElement('img', 'token-logo')
    tokenLogo.src = token.logo

    let networkLogo: any = null
    if (!token.isNative) {
      networkLogo = createElement('img', 'network-logo')
      networkLogo.src = NETWORK_MAP[ChainIdEnum[formatApiNetworkField(newPrize.network)]].networkInfo.icon
    }
    const logoContainter = createElement('div', 'logo-container')
    logoContainter.appendChild(tokenLogo)
    if (networkLogo) logoContainter.appendChild(networkLogo)

    const value = createElement('div', 'prize-value')
    value.innerHTML = newPrize.amount.toString()

    container.appendChild(logoContainter)
    container.appendChild(value)

    if (prizeListContainer) prizeListContainer.appendChild(container)
  }
}
