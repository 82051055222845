import Box from 'components/Box/Box'
import React from 'react'
import styled, { keyframes } from 'styled-components'
import { SkeletonProps, variant as VARIANT } from './types'

const waves = keyframes`
   from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
`

const Root = styled(Box)<SkeletonProps>`
  min-height: 20px;
  display: block;
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
  border-radius: ${({ variant, theme }) => (variant === VARIANT.CIRCLE ? '50%' : theme.radii.small)};
`

const Waves = styled(Root)`
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  &:before {
    content: '';
    position: absolute;
    background-image: linear-gradient(90deg, transparent, rgb(67 80 98 / 50%), transparent);
    top: 0;
    left: -150px;
    height: 100%;
    width: 150px;
    animation: ${waves} 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
`

const Skeleton: React.FC<SkeletonProps> = ({ variant = VARIANT.RECT, ...props }) => {
  return <Waves variant={variant} {...props} />
}

export default Skeleton
