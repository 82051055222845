import { APIEndpointEnum } from 'config/constants/server'
import { Referee, ReferralBalanceTokenAmount, ReferralCampaign, ReferralOverview } from 'config/types/referral'
import { MAX_LIMIT_CREATE_CAMPAIGN } from 'views/Referral/components/ReferralCreateCampaignModal'
import { HunnyPlayRequest } from './HunnyPlayRequest'
import {
  BaseResponse,
  HunnyRequest,
  Paging,
  PrepareReferralClaim,
  PrepareReferralClaimRes,
  ReferralCampaignDetailRes,
} from './types'

class ReferralService extends HunnyPlayRequest {
  public getOverview(): HunnyRequest<BaseResponse<ReferralOverview>> {
    const result = this._post(APIEndpointEnum.ReferralOverview)

    return result
  }

  public getCampaign(): HunnyRequest<BaseResponse<ReferralCampaign>> {
    const result = this._post(APIEndpointEnum.ReferralDefaultCampaign)

    return result
  }

  public getListCampaign(): HunnyRequest<BaseResponse<Paging<ReferralCampaign>>> {
    const result = this._post(APIEndpointEnum.ReferralListCampaign, {
      paging: {
        offset: 0,
        limit: MAX_LIMIT_CREATE_CAMPAIGN,
      },
    })

    return result
  }

  public getReferralCampaignDetails(
    id: number,
    statsTimeRange?: { fromTime: number; toTime: number },
  ): HunnyRequest<BaseResponse<ReferralCampaignDetailRes>> {
    let payload: any = { id }
    if (statsTimeRange)
      payload = {
        ...payload,
        stats_time_range: {
          from_time: statsTimeRange.fromTime,
          to_time: statsTimeRange.toTime,
        },
      }

    const result = this._post(APIEndpointEnum.ReferralCampaignDetails, payload)

    return result
  }

  public getAvailableBalanceClaim(): HunnyRequest<BaseResponse<Paging<ReferralBalanceTokenAmount>>> {
    const result = this._post(APIEndpointEnum.ReferralAvailableBalanceClaim)

    return result
  }

  public getTotalEarned(): HunnyRequest<BaseResponse<Paging<ReferralBalanceTokenAmount>>> {
    const result = this._post(APIEndpointEnum.ReferralTotalEarned)

    return result
  }

  public prepareAvailableBalanceClaim(
    payload: PrepareReferralClaim,
  ): HunnyRequest<BaseResponse<PrepareReferralClaimRes>> {
    const result = this._post(APIEndpointEnum.ReferralPrepareClaim, {
      chain_type: payload.chainType,
      chain_code: payload.chainCode,
      address: payload.address,
      items: payload.items,
    })

    return result
  }

  public claimAvailableBalance(payload: ReferralBalanceTokenAmount[]): HunnyRequest<BaseResponse<any>> {
    const result = this._post(APIEndpointEnum.ReferralClaimBalance, { items: payload })

    return result
  }

  public createReferralCampaign(payload: {
    campaignName: string
    myCommisionPercent: string
    refereeCommisionPercent: string
  }): HunnyRequest<BaseResponse<ReferralCampaign>> {
    const result = this._post(APIEndpointEnum.ReferralCreateCampaign, {
      name: payload.campaignName,
      referrer_percentage: payload.myCommisionPercent,
      referee_percentage: payload.refereeCommisionPercent,
    })

    return result
  }

  public setDefaultCampaign(id: number): HunnyRequest<BaseResponse<any>> {
    const result = this._post(APIEndpointEnum.ReferralSetDefaultCampaign, { id })

    return result
  }

  public deleteCampaign(id: number): HunnyRequest<BaseResponse<any>> {
    const result = this._post(APIEndpointEnum.ReferralUpdateCampaign, {
      id,
      status: -1,
    })

    return result
  }

  public updateCampaign(id: number, name: string): HunnyRequest<BaseResponse<any>> {
    const result = this._post(APIEndpointEnum.ReferralUpdateCampaign, { id, name })

    return result
  }

  public getReferees(offset: number, limit: number): HunnyRequest<BaseResponse<Paging<Referee>>> {
    const result = this._post(APIEndpointEnum.RefereeList, {
      paging: {
        limit,
        offset,
      },
    })

    return result
  }
}

const instance = new ReferralService()
export default instance
