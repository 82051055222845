import { ChainIdEnum } from 'config/constants/network'
import { QueryDepositInfo, RewardTransaction, SwapTransaction, Transaction } from 'config/types/transaction'
import { BalanceResponse, BaseResponse, Paging, PrepareSignMessageResponse, VerificationResponse } from 'services/types'
import { formatApiNetworkField } from 'utils'
import {
  parseBonusTransaction,
  parseDepositTransaction,
  parseRewardTransaction,
  parseSwapTransaction,
  parseWithdrawalTransaction,
} from './utils'

export const parseBalances = (data: any) => ({
  amount: data.amount,
  currency: data.currency,
  network: ChainIdEnum[formatApiNetworkField(data.network)],
})

export const BalanceMapper = (rawResponse: string): BaseResponse<Paging<BalanceResponse>> => {
  const response: BaseResponse<Paging<any>> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: null,
      items: response.data.items.map((item) => parseBalances(item)),
    },
  }
}

export const TraditionalDepositMapper = (rawResponse: string): BaseResponse<{ address: string; minAmount: string }> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      address: response.data.address,
      minAmount: response.data.min_amount,
    },
  }
}

export const WithdrawFeeMapper = (rawResponse: string): BaseResponse<{ withdrawFee: string }> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      withdrawFee: response.data.withdraw_fee,
    },
  }
}

export const PrepareTraditionalWithdrawMapper = (rawResponse: string): BaseResponse<VerificationResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      token: response.data.verification_code,
      expireTime: response.data.otp_expire_time * 1000,
      nextResentTime: response.data.otp_next_request_time * 1000,
    },
  }
}

export const PrepareWalletWithdrawMapper = (rawResponse: string): BaseResponse<PrepareSignMessageResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      acceptMessage: response.data.accept_message,
      acceptMessageText: response.data.accept_message_text,
      address: response.data.subject,
    },
  }
}

export const WithdrawMapper = (rawResponse: string): BaseResponse<PrepareSignMessageResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && response.data.id.toString(),
  }
}

export const WithdrawalTransactionMapper = (rawResponse: string): BaseResponse<Transaction> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && parseWithdrawalTransaction(response.data),
  }
}

export const WithdrawalTransactionsMapper = (rawResponse: string): BaseResponse<Paging<Transaction>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: response.data.paging,
      items: response.data.items && response.data.items.map((item) => parseWithdrawalTransaction(item)),
    },
  }
}

export const DepositTransactionMapper = (rawResponse: string): BaseResponse<Transaction> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && parseDepositTransaction(response.data),
  }
}

export const DepositTransactionsMapper = (rawResponse: string): BaseResponse<Paging<Transaction>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: response.data.paging,
      items: response.data.items && response.data.items.map((item) => parseDepositTransaction(item)),
    },
  }
}

export const RewardTransactionsMapper = (rawResponse: string): BaseResponse<Paging<RewardTransaction>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: response.data.paging,
      items: response.data.items && response.data.items.map((item) => parseRewardTransaction(item)),
    },
  }
}

export const BonusTransactionMapper = (rawResponse: string): BaseResponse<Paging<RewardTransaction>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }
  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: response.data.paging,
      items: response.data.items && response.data.items.map((item) => parseBonusTransaction(item)),
    },
  }
}

export const SwapTransactionMapper = (rawResponse: string): BaseResponse<Paging<SwapTransaction>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: response.data.paging,
      items: response.data.items && response.data.items.map((item) => parseSwapTransaction(item)),
    },
  }
}

export const QueryDepositByHashMapper = (rawResponse: string): BaseResponse<QueryDepositInfo> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      status: response.data.watch.status,
      depositInfo: response.data.deposit
        ? {
            currency: response.data.deposit.currency,
            network: ChainIdEnum[formatApiNetworkField(response.data.deposit.network)],
            value: response.data.deposit.value,
            status: response.data.deposit.status,
          }
        : null,
    },
  }
}
