import { NextLinkFromReactRouter } from 'components/NextLink'
import React from 'react'
import styled, { css } from 'styled-components'
import getExternalLinkProps from 'utils/getExternalLinkProps'
import Text from '../Text'
import { LinkProps } from './types'

const StyledLink = styled(Text)<{ $disabledDefaultStyle: boolean }>`
  display: flex;
  align-items: center;

  ${({ $disabledDefaultStyle }) =>
    $disabledDefaultStyle
      ? ''
      : css`
          &:hover {
            * {
              color: ${({ theme }) => theme.colors.text};
              fill: ${({ theme }) => theme.colors.text};
            }
          }
        `}
`

const Link: React.FC<LinkProps> = ({
  external,
  href,
  children,
  target,
  title,
  disabledDefaultStyle = false,
  ...props
}) => {
  const internalProps = external ? getExternalLinkProps() : {}

  return external ? (
    <a title={title} href={href} target={target} {...internalProps}>
      <StyledLink $disabledDefaultStyle={disabledDefaultStyle} {...props}>
        {children}
      </StyledLink>
    </a>
  ) : (
    <NextLinkFromReactRouter title={title} to={href} {...internalProps}>
      <StyledLink $disabledDefaultStyle={disabledDefaultStyle} {...props}>
        {children}
      </StyledLink>
    </NextLinkFromReactRouter>
  )
}

Link.defaultProps = {
  color: 'text',
}

export default Link
