import Box from 'components/Box/Box'
import { BoxProps } from 'components/Box/types'
import IconButton from 'components/Button/IconButton'
import useKeydownEventListener from 'hooks/useKeydownEventListener'
import React from 'react'
import styled from 'styled-components'
import { Icons } from 'svgs'
import hunnyKeyframes from 'theme/keyframes'

export interface ModalProps<T = any> {
  id?: string
  onDismiss?: () => void
  data?: T
  disableClose?: boolean
}

const Modal: React.FC<ModalProps & BoxProps> = ({ id, onDismiss, children, disableClose, ...props }) => {
  const handleOnDismiss = () => {
    if (onDismiss && !disableClose) {
      onDismiss()
    }
  }

  useKeydownEventListener((event) => {
    if (event && event.key === 'Escape') {
      handleOnDismiss()
    }
  })

  return (
    <StyledModal id={id} {...props}>
      {!disableClose && (
        <StyledCloseButton className="modal-closebutton" id="modal-closebutton" onClick={onDismiss}>
          <Icons.CloseIcon />
        </StyledCloseButton>
      )}

      {children}
    </StyledModal>
  )
}

const StyledModal = styled(Box)`
  box-sizing: border-box;
  backdrop-filter: blur(10px);

  border-top-left-radius: ${({ theme }) => theme.radii.large};
  border-top-right-radius: ${({ theme }) => theme.radii.large};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 100%;
  width: 100%;
  min-height: 100px;
  background: ${({ theme }) => theme.colors.modalBackground};
  max-width: 512px;
  max-height: calc(var(--screen-height) - 24px);
  ${({ theme }) => theme.mediaQueries.xs} {
    backdrop-filter: unset;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 512px;
    border-radius: ${({ theme }) => theme.radii.large};
  }
`

const StyledCloseButton = styled(IconButton)`
  position: absolute;
  background: transparent;
  z-index: 5;
  width: 24px;
  height: 24px;
  top: 18px;
  right: 25px;
  cursor: pointer;
  animation: ${hunnyKeyframes.fade} 1s ease-out forwards;
  > svg {
    margin-left: 0 !important;
    fill: ${({ theme }) => theme.colors.text} !important;
  }
`

export default Modal
