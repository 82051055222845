import { HeaderType } from 'components/Table/type'

interface HeaderTypeCustome extends HeaderType {
  center?: boolean
}

export const LevelDesktopColumnSchema: HeaderTypeCustome[] = [
  {
    name: 'level',
    label: 'Level',
    width: '20%',
    center: false,
  },
  {
    name: 'name',
    label: 'Rank',
    width: '20%',
    center: false,
  },
  {
    name: 'minWagerUsd',
    label: 'Wagered',
    width: '20%',
    center: false,
  },
  {
    name: 'mainCashbackPercentage',
    label: 'Cashback',
    width: '20%',
    center: true,
  },
  {
    name: 'bonusUsd',
    label: 'Bonus',
    width: '20%',
    center: false,
  },
]

export const LevelMobileColumnSchema: HeaderTypeCustome[] = [
  {
    name: '',
    label: 'Level',
    width: '30%',
  },
  {
    name: 'minWagerUsd',
    label: 'Wagered',
    width: '25%',
  },
  {
    name: 'mainCashbackPercentage',
    label: 'Cashback',
    width: '25%',
  },
  {
    name: 'bonusUsd',
    label: 'Bonus',
    width: '25%',
  },
]
