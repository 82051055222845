import { ALL_GAME_TAG } from 'config/constants/gameTags'
import { ScreenTag } from 'config/types'
import { Game, GameSession, GameDetail } from 'config/types/game'
import { BaseResponse, Paging } from 'services/types'

export const parseScreenTags = (data: any[]): ScreenTag[] => {
  let items = (data || [])?.map((item) => ({
    tag: item.tag_code,
    childTags: item.child_tags.split(' '),
  }))

  if (!items.find((item) => item.tag === ALL_GAME_TAG)) {
    items = [
      {
        tag: ALL_GAME_TAG,
        childTags: [],
      },
      ...items,
    ]
  }
  return items
}

export const ScreenTagMapper = (rawResponse: string): BaseResponse<Paging<ScreenTag>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  const items = parseScreenTags(response.data.items)

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      ...response.data,
      items,
    },
  }
}

export const GamesMapper = (rawResponse: string): BaseResponse<Paging<Game>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      ...response.data,
      items: (response.data.items || response.data.games).map((item: any) => parseGame(item)),
    },
  }
}

export const FavoriteGamesMapper = (rawResponse: string): BaseResponse<Paging<Game>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      ...response.data,
      items: (response.data.items || response.data.games).map((item: any) => parseGame(item)),
    },
  }
}

export const GameSessionMapper = (rawResponse: string): BaseResponse<GameSession> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      code: response.data.code,
      gameUrl: response.data['open_value'],
    },
  }
}

export const GameDetailMapper = (rawResponse: string): BaseResponse<GameDetail> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      ...parseGame(response.data),
      categoryId: response.data.category_id,
      merchantCode: response.data.merchant_code,
      type: response.data.type,
    },
  }
}

const parseGame = (game: any): Game => {
  return {
    code: game.code,
    name: game.name,
    providerCode: game.provider_code || 'Unknow',
    slug: game.slug,
    thumbnail: game.banner_big_url || game.banner_url,
    favoriteCount: game.favorite_count || 0,
  }
}
