import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import { BoxProps } from 'components/Box/types'
import Button from 'components/Button'
import Image from 'components/Image'
import Link from 'components/Link/Link'
import Skeleton from 'components/Skeleton'
import Text from 'components/Text'
import { TimeSince } from 'components/TimeSince'
import { Notification, NotificationStatusEnums, NotificationTypeEnums } from 'config/types/notification'
import useToggle from 'hooks/useToggle'
import { useCallback, useState } from 'react'
import NotificationService from 'services/NotificationService'
import { useUnreadNotificationAmount } from 'state/notification/hooks'
import styled from 'styled-components'
import { Icons } from 'svgs'
import theme from 'theme'
import { formatDisplayDateTime } from 'utils/dateHelper'
import { useRouter } from 'hooks/useRouter'
import { RowCenter } from '../Row'

const MINIMUM_COLLAPSED_DESCRIPTION_HEIGHT = 32
export interface NotificationItemProps extends BoxProps {
  notification: Notification
  hideExtendDescButton?: boolean
}

export const NotificationItemLoader = () => {
  return (
    <StyledContainer height={96} width={['100%', '100%', 320]} $isUnread={false}>
      <Skeleton width={90} height={70} />
      <RowCenter flexDirection="column" width="100%" ml={2}>
        <Skeleton width="100%" height={40} />
        <Skeleton width="100%" height={16} mt={2} />
      </RowCenter>
    </StyledContainer>
  )
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  hideExtendDescButton = false,
  ...props
}) => {
  const { unreadPrivateAmount, unreadSystemAmount, updateUnreadPrivateAmount, updateUnreadSystemAmount } =
    useUnreadNotificationAmount()
  const [descriptionHeight, setDescriptionHeight] = useState<number>(0)
  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setDescriptionHeight(node?.offsetHeight)
    }
  }, [])

  const [isUnread, setIsUnread] = useState<boolean>(notification.status === NotificationStatusEnums.UNREAD)
  const [isExtendDescription, toggleExtendDescription] = useToggle(false)
  const router = useRouter()
  const descriptionArray = notification.description?.split('\n')

  const handleDirectNotificationUrl = useCallback(() => {
    if (notification.external) {
      window.open(notification.url, '__blank')
    } else router.push(notification.url)
  }, [notification])

  const handleUpdateUnreadAmount = useCallback(async () => {
    if (!isUnread) return
    let response
    if (notification.type === NotificationTypeEnums.PRIVATE)
      response = await NotificationService.markAsReadPrivateNotification([notification.id])
    else response = await NotificationService.markAsReadSystemNotification([notification.id])

    if (response.code === 'success') {
      setIsUnread(false)

      if (notification.type === NotificationTypeEnums.PRIVATE) updateUnreadPrivateAmount(unreadPrivateAmount - 1)
      else if (notification.type === NotificationTypeEnums.SYSTEM) updateUnreadSystemAmount(unreadSystemAmount - 1)
    }
  }, [unreadPrivateAmount, unreadSystemAmount, isUnread, notification])

  const handleToggleExtendDescription = (e) => {
    e.stopPropagation()
    e.preventDefault()
    toggleExtendDescription()
  }

  return (
    <StyledContainer
      as={notification.external ? Link : 'a'}
      external={notification.external}
      href={notification.url}
      width="100%"
      $isUnread={isUnread}
      borderRadius={theme.radii.small}
      onClick={(e) => {
        if (!e.ctrlKey) {
          e.preventDefault()
          handleDirectNotificationUrl()
          handleUpdateUnreadAmount()
        }
      }}
      {...props}
    >
      <Box minWidth={80} minHeight={80}>
        <StyledNotificationThumbnail
          width={80}
          height={80}
          src={notification.bannerUrl || '/images/notification/default_thumbnail.png'}
          alt="Notification Thumbnail"
        />
      </Box>

      <Flex flexDirection="column" ml={2} height="100%" width="100%" pb={0} pr={12}>
        <Text fontSize="12px" textAlign={'left'} fontWeight={400} lineHeight="15px">
          <TimeSince
            date={new Date(notification.createTime)}
            fontSize="12px"
            color="textSubtle"
            display={formatDisplayDateTime(new Date(notification.createTime))}
          />
        </Text>

        <StyledNotificationTitle
          fontSize="14px"
          color={!isUnread ? theme.colors.textSubtle : 'text'}
          bold
          textAlign={'left'}
          fontWeight="500"
          lineHeight="18px"
        >
          {notification.title}
        </StyledNotificationTitle>

        <StyledDescriptionContainer flexDirection="column" ref={measuredRef}>
          <StyledToggleExpandContainer $isExtend={isExtendDescription}>
            {descriptionArray?.length
              ? descriptionArray.map((message) => {
                  return (
                    <StyledDescription as="p" $isRead={!isUnread}>
                      {message}
                    </StyledDescription>
                  )
                })
              : null}
          </StyledToggleExpandContainer>
          {!hideExtendDescButton ? (
            <>
              {!isExtendDescription && descriptionHeight >= MINIMUM_COLLAPSED_DESCRIPTION_HEIGHT ? (
                <StyledToggleExtendButton onClick={handleToggleExtendDescription}>
                  <Text fontSize="12px" bold>
                    ...See more
                  </Text>
                </StyledToggleExtendButton>
              ) : null}
              {isExtendDescription ? (
                <StyledToggleExtendButton onClick={handleToggleExtendDescription}>
                  <Text fontSize="12px" bold>
                    Hide all
                  </Text>
                </StyledToggleExtendButton>
              ) : null}
            </>
          ) : null}
        </StyledDescriptionContainer>

        <Flex
          as={notification.external ? Link : 'a'}
          external={notification.external}
          href={notification.url}
          mt={'5px'}
          alignItems="center"
        >
          <Text color="strokeAlt3" fontSize="12px" textTransform="capitalize" mr={1} fontWeight={400}>
            {notification.anchorText}
          </Text>

          <Icons.ArrowRight fill={theme.colors.strokeAlt3} />
        </Flex>

        {isUnread ? <StyledUnreadNotiDot /> : null}
      </Flex>
    </StyledContainer>
  )
}

const StyledContainer = styled(Flex)<{ $isUnread: boolean }>`
  margin: 8px 0;
  padding: 12px;
  position: relative !important;
  align-items: flex-start !important;
  cursor: pointer;

  background: ${({ $isUnread, theme: { colors } }) =>
    $isUnread ? colors.backgroundAlt5 : 'rgba(255, 255, 255, 0.02)'};

  &:hover {
    opacity: 0.9;
  }
`

const StyledUnreadNotiDot = styled(Box)`
  background: ${({ theme: { colors } }) => colors.strokeAlt3};

  width: 8px;
  height: 8px;

  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);

  border-radius: 50%;
`

const StyledNotificationTitle = styled(Text)`
  margin-top: 5px;
`

const StyledNotificationThumbnail = styled(Image)`
  img {
    border-radius: ${({ theme: { radii } }) => radii.tiny};
  }
`

const StyledDescriptionContainer = styled(Flex)`
  margin-top: 6px;
  position: relative;
`
const StyledDescription = styled(Text)<{ $isRead: boolean }>`
  font-size: 12px;
  line-height: 16px;
  word-break: break-word;

  color: ${({ $isRead, theme: { colors } }) => ($isRead ? colors.textSubtle : colors.textTertiary)};
`

const StyledToggleExpandContainer = styled(Box)<{ $isExtend: boolean }>`
  max-height: ${({ $isExtend }) => ($isExtend ? '900px' : '32px')};
  margin-bottom: ${({ $isExtend }) => ($isExtend ? '25px' : '0px')};

  will-change: transition;
  transition: ${({ theme: { transitions } }) => transitions.medium};
  overflow: hidden;
`

const StyledToggleExtendButton = styled(Button).attrs({ variant: 'text' })`
  padding: 6px;
  height: fit-content;
  background: #1f252d;
  position: absolute;
  right: 0px;
  bottom: -5px;
`

export default NotificationItem
