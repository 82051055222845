import { LoginMethod } from 'config/constants/auth'
import { useCallback } from 'react'
import AuthenticationService from 'services/AuthenticationService'
import { useAppDispatch } from 'state'
import { updateDeviceUid } from 'state/session/actions'
import { useClientLogin } from 'state/auth/hooks'
import { constructEncryptedPassword } from 'utils/auth'
import { getID } from 'utils/fingerprint'
import { setIsSigned } from 'state/app/actions'

export const useSignInWithEmail = () => {
  const login = useClientLogin()
  const dispatch = useAppDispatch()
  return useCallback(async (password: string, username: string) => {
    const prepareResponse = await AuthenticationService.getTraditionalSignInPrepare(username)
    if (!prepareResponse?.data) return { code: 'error' }
    const deviceUid = await getID()
    const response = await AuthenticationService.signInByEmail({
      deviceUid,
      username,
      password: constructEncryptedPassword(password, prepareResponse.data),
      nonceId: prepareResponse.data.id,
    })

    if (response.code === 'success') {
      dispatch(updateDeviceUid({ deviceUid }))
      login({
        loginBy: LoginMethod.Email,
        uid: response.data.uid,
        username,
        wallet: null,
        email: username,
      })

      dispatch(setIsSigned({ isSigned: true, atTime: new Date().getTime() }))
    }

    return response
  }, [])
}
