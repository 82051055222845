import { getNetworkInitForWagami } from 'config/constants/network'
import { configureChains, createConfig } from 'wagmi'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectLegacyConnector } from 'wagmi/connectors/walletConnectLegacy'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { LOGO_200_URL, PRODUCT_NAME, PRODUCT_URL } from 'config/constants/variables'
import { BinanceWalletConnector } from './connectors/binanceWallet'
import { TrustWalletConnector } from './connectors/trustWallet'
import { ConnectorNameEnum } from './types'

export const { chains, publicClient, webSocketPublicClient } = configureChains(
  getNetworkInitForWagami(),
  [
    jsonRpcProvider({
      rpc: (chain) => ({ http: chain.rpcUrls.default.http[0] }),
    }),
  ],
  {
    batch: {
      multicall: false,
    },
  },
)

export const injectedConnector = new InjectedConnector({
  chains,
  options: {
    shimDisconnect: false,
  },
})

export const coinbaseConnector = new CoinbaseWalletConnector({
  chains,
  options: {
    appName: PRODUCT_NAME,
    appLogoUrl: `${PRODUCT_URL}${LOGO_200_URL}`,
  },
})

// export const walletConnectConnector = new WalletConnectConnector({
//   chains,
//   options: {
//     metadata: {
//       name: 'HunnyPlay | The best crypto gaming platform',
//       description: 'HunnyPlay.io - The Best Crypto iGaming Platform',
//       url: 'https://hunnyplay.io',
//       icons: ['https://hunnyplay.io/logo-hunny-200.png'],
//     },
//     projectId: process.env.NEXT_PUBLIC_CONNECT_WALLET_PROJECT_ID,
//     showQrModal: true,
//   },
// })

export const walletConnectConnector = new WalletConnectLegacyConnector({
  chains,
  options: {
    qrcode: true,
    bridge: 'https://walletconnect-bridge.hunny.finance/',
  },
})

export const metaMaskConnector = new MetaMaskConnector({
  chains,
  options: {
    shimDisconnect: false,
  },
})

export const trustWalletConnector = new TrustWalletConnector({
  chains,
  options: {
    shimDisconnect: false,
    shimChainChangedDisconnect: true,
  },
})

export const binanceWalletConnector = new BinanceWalletConnector({
  chains,
})

export const connectorsByName: { [connectorName in ConnectorNameEnum]?: any } = {
  [ConnectorNameEnum.Injected]: injectedConnector,
  [ConnectorNameEnum.WalletConnect]: walletConnectConnector,
  [ConnectorNameEnum.BSC]: binanceWalletConnector,
  [ConnectorNameEnum.MetaMask]: metaMaskConnector,
  [ConnectorNameEnum.TrustWallet]: trustWalletConnector,
}

export const wagmiConfig = createConfig({
  autoConnect: false,
  connectors: [
    metaMaskConnector,
    injectedConnector,
    coinbaseConnector,
    trustWalletConnector,
    binanceWalletConnector,
    walletConnectConnector,
  ],
  publicClient,
})

