import BigNumber from 'bignumber.js'
import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Button from 'components/Button'
import { StyledControlContainer } from 'components/FormControl/styled'
import Image from 'components/Image'
import { InputContainer, InputLabel, InputMessage } from 'components/Input/styled'
import CircleLoader from 'components/Loader/CircleLoader'
import OpenEffect from 'components/OpenEffect'
import Text from 'components/Text'
import TokenInput from 'components/TokenInput'
import FormValidator from 'config/constants/formValidator'
import { ChainIdEnum } from 'config/constants/network'
import { HUSD_TOKEN } from 'config/constants/tokens'
import { ValidationError } from 'config/types/validator'
import { WalletType } from 'config/types/wallet'
import { useAnalytics } from 'hooks/useAnalytics'
import useForm from 'hooks/useForm'
import { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import BonusService from 'services/BonusService'
import { BalanceResponse } from 'services/types'
import { useAppDispatch, useAppSelector } from 'state'
import { useAllTokens } from 'state/app/hooks'
import { updateBalances } from 'state/profile/actions'
import styled from 'styled-components'
import { getBalanceTokenKey } from 'utils'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { HunnyToast } from 'utils/toastify'
import { isSolToken } from 'utils/token'
import EstimateReceive from './EstimateReceive'
import SelectReceiveToken from './SelectReceiveToken'

const SwapInputErrorMessages = {
  [ValidationError.Insufficient]: 'Insufficient Balance',
  [ValidationError.LessThanEqual]: 'Value must be greater than 0 HUSD',
}

const Swap = () => {
  const { t } = useTranslation()
  const { recordEvent } = useAnalytics()
  const HUSDAmount = useAppSelector(
    (state) => state.profile.balances[getBalanceTokenKey(HUSD_TOKEN.network, HUSD_TOKEN.code)],
  )
  const parsedHUSDAmount = useMemo(() => new BigNumber(HUSDAmount || 0), [HUSDAmount])
  const { wallet } = useAppSelector((state) => state.auth)
  const stableTokens = useAllTokens(
    (token) => token?.isStable && (wallet?.type === WalletType.SOL ? isSolToken(token) : !isSolToken(token)),
  )

  const dispatch = useAppDispatch()

  const { states, controls, validate, isValid } = useForm({
    value: {
      validators: [FormValidator.lte(0), FormValidator.max(parsedHUSDAmount)],
      value: '',
    },
  })

  const [submiting, setSubmiting] = useState<boolean>(false)
  const [selectedToken, setSelectedToken] = useState(stableTokens[0])

  const handleSwap = async () => {
    validate('value')
    if (new BigNumber(states.value.value).gt(parsedHUSDAmount)) return

    setSubmiting(true)

    const updatedBalance: BigNumber = parsedHUSDAmount.minus(states.value.value)
    const result = await BonusService.swap(states.value.value, selectedToken)

    if (result) {
      recordEvent('swap_husd', {
        [ChainIdEnum[selectedToken.network]]: selectedToken.name,
      })
      HunnyToast.success(
        t('Swap successful!'),
        t('Your HUSD has been swapped for {{tokenName}}. Your account balance has been updated.', {
          tokenName: selectedToken.name,
        }),
      )

      dispatch(
        updateBalances({
          data: [{ amount: updatedBalance.toString(), currency: 'HUSD', network: ChainIdEnum.HPN } as BalanceResponse],
        }),
      )
    }

    setSubmiting(false)
  }

  return (
    <OpenEffect openType="grow" duration={0.4}>
      <InputContainer p="10px 15px">
        <Image src="/images/token/hpd.png" width={25} height={25} />
        <Text ml="5px" fontSize="14px" fontWeight="700" color="textSubtle">
          HUSD
        </Text>
      </InputContainer>

      <StyledControlContainer mt="16px" state={states.value}>
        <StyledLabel>
          <InputLabel mb="0px !important">
            <Trans>Amount</Trans>
          </InputLabel>
          <InputLabel mb="0px !important">
            <Trans>Available</Trans>: {getFullDisplayBalance(parsedHUSDAmount, 0, 5)} {HUSD_TOKEN.name}
          </InputLabel>
        </StyledLabel>
        <TokenInput
          tabIndex={1}
          value={states.value.value}
          token={HUSD_TOKEN}
          errors={states.value.errors}
          validators={controls.value.validators}
          onErrorChanged={controls.value.onErrorChanged}
          onValueChanged={controls.value.onValueChanged}
          max={parsedHUSDAmount}
        />
        <Flex flexDirection={['column']} justifyContent="space-between">
          <InputMessage textAlign="right">{t(SwapInputErrorMessages[states.value.errors[0]])}</InputMessage>
        </Flex>
      </StyledControlContainer>

      <Flex my="24px" justifyContent="center">
        <Image src="/images/arrow-down.png" width={32} height={32} alt="arrow down" />
      </Flex>

      <SelectReceiveToken tokens={stableTokens} value={selectedToken} onValueChanged={setSelectedToken} />
      <Flex mt="24px" alignItems="center" justifyContent="space-between">
        <Text color="textSubtle" bold>
          <Trans>You will get</Trans>:
        </Text>

        <EstimateReceive token={selectedToken} amount={states.value.value} />
      </Flex>
      <Button onClick={handleSwap} width="100%" mt="54px" disabled={submiting || !isValid || !states.value.value}>
        {submiting && (
          <Box mr="12px">
            <CircleLoader />
          </Box>
        )}

        <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'}>
          <Trans>Swap</Trans>
        </Text>
      </Button>
    </OpenEffect>
  )
}

const StyledLabel = styled(InputLabel)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export default Swap

