import axios, { AxiosRequestConfig } from 'axios'
import { APIEndpointEnum } from 'config/constants/server'
import { BaseResponse } from './types'

export const hunnyAxios = axios.create({
  timeout: 30000,
})

export abstract class BaseRequest {
  protected get AxiosCancelToken() {
    const _cancelToken = axios.CancelToken
    return _cancelToken.source()
  }

  protected abstract _request(
    url: string,
    payload: any,
    config: any,
    mapperKey?: APIEndpointEnum,
  ): Promise<BaseResponse<any>>

  protected abstract callRequest(url: string, config: AxiosRequestConfig, payload?: any): Promise<any>

  protected abstract buildConfig(config: AxiosRequestConfig, mapper: any): AxiosRequestConfig
}
