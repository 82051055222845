import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import { Checkbox } from 'components/Checkbox'
import { InputProps } from 'components/Input'
import { InputMessage } from 'components/Input/styled'
import Link from 'components/Link'
import Text from 'components/Text'
import { RouteConfig } from 'config/constants/route'
import Column from 'layout/Components/Column'
import React from 'react'
import { Trans } from 'react-i18next'
import theme from 'theme'

const CheckCondition: React.FC<InputProps> = ({ value, onValueChanged, ...props }) => {
  return (
    <Column>
      <Flex>
        <Box mr="8px">
          <Checkbox width="18px" height="18px" value={value} onValueChanged={onValueChanged} tabIndex={1} {...props} />
        </Box>
        <Box
          style={{
            display: 'inline',
          }}
        >
          <Text as="span" fontWeight={300} fontSize="12px" lineHeight="14.5px" color="textTertiary" mr="4px">
            <Trans
              i18nKey="confirmOver18Content"
              components={{
                highlight_tag: (
                  <Link
                    id="condition-terms-of-service-text"
                    style={{
                      display: 'inline-flex',
                      wordBreak: 'break-word',
                    }}
                    fontSize="12px"
                    color="primary"
                    target="_blank"
                    href={RouteConfig.TermOfService}
                    external
                  />
                ),
              }}
            />
          </Text>
        </Box>
      </Flex>
      {!value && (
        <InputMessage color={`${theme.colors.error} !important`} mt="4px" ml="30px">
          <Trans>Please read and agree to the terms of service.</Trans>
        </InputMessage>
      )}
    </Column>
  )
}

export default CheckCondition
