import BigNumber from 'bignumber.js'
import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import FormControl from 'components/FormControl'
import FormInput from 'components/FormControl/FormInput'
import { StyledControlContainer } from 'components/FormControl/styled'
import { InputLabel, InputMessage, StyledInput } from 'components/Input/styled'
import { NextLinkFromReactRouter } from 'components/NextLink'
import OpenEffect from 'components/OpenEffect'
import Text from 'components/Text'
import TokenInput from 'components/TokenInput'
import { LoginMethod } from 'config/constants/auth'
import FormValidator from 'config/constants/formValidator'
import { ChainIdEnum } from 'config/constants/network'
import { BIG_ZERO } from 'config/constants/number'
import { RouteConfig } from 'config/constants/route'
import { HUSD_TOKEN } from 'config/constants/tokens'
import { Token } from 'config/types'
import { ValidationError } from 'config/types/validator'
import { WalletType } from 'config/types/wallet'
import useForm from 'hooks/useForm'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'state'
import { useListNetworks } from 'state/app/hooks'
import { useUserInfo } from 'state/auth/hooks'
import { useAllWageringBonusesHaveAmount } from 'state/bonus/hooks'
import { useTokenAppBalance } from 'state/profile/hooks'
import { useTokenSelected } from 'state/session/hooks'
import { addPendingWithdrawTransaction } from 'state/transaction/action'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'
import { getBalanceTokenKey } from 'utils'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { checkTokenEqual } from 'utils/token'
import HUSDInfo from '../HUSDInfo'
import PaymentTokenSelect from '../PaymentTokenSelect'
import TraditionalWithdrawButton from './TraditionalWithdrawButton'
import WalletWithdrawButton from './WalletWithdrawButton'
import { useWithdrawFee } from './hooks'

const WithdrawInputErrorMessages = {
  receiveAddress: {
    [ValidationError.Required]: 'Address is required',
    [ValidationError.IsEtherAddress]: 'Your address is invalid',
  },
  withdrawValue: {
    [ValidationError.Insufficient]: 'Insufficient Balance',
    [ValidationError.LessThanEqual]: 'Amount must be greater than withdraw fee',
  },
}

interface WithdrawModalProps {
  onDismiss: () => void
}

const Withdraw: React.FC<WithdrawModalProps> = ({ onDismiss }) => {
  const dispatch = useAppDispatch()

  const { loginBy, username } = useUserInfo()
  const { wallet } = useAppSelector((state) => state.auth)
  const isLoginByEmail = loginBy === LoginMethod.Email
  const { t } = useTranslation()

  const initialToken = useTokenSelected()
  const networks = useListNetworks(
    wallet?.type == WalletType.SOL
      ? { selectChains: [ChainIdEnum.SOL, ChainIdEnum.SOL_TESTNET] }
      : { excludeChains: [ChainIdEnum.SOL, ChainIdEnum.SOL_TESTNET] },
  )
  const [selectedToken, setSelectedToken] = useState<Token>(initialToken)
  const withdrawFee = useWithdrawFee(selectedToken)

  const tokenBalances = useAppSelector((state) => state.profile.balances)
  const selectTokenAmount = useTokenAppBalance(selectedToken)
  const bonuses = useAllWageringBonusesHaveAmount()
  const bonusAmount = useMemo(() => {
    if (selectedToken === HUSD_TOKEN) return BIG_ZERO
    return bonuses
      .filter((bonus) => checkTokenEqual(bonus.bonusAmount.token, selectedToken))
      .reduce((result, value) => result.plus(value.bonusAmount.amount), new BigNumber(0))
  }, [bonuses, selectedToken])

  const { states, controls, validateAll, isValid, validate } = useForm({
    value: {
      validators: [FormValidator.lte(withdrawFee), FormValidator.max(selectTokenAmount?.amount)],
      value: '',
    },
    receiveAddress: {
      validators:
        wallet?.type == WalletType.SOL
          ? [FormValidator.required]
          : [FormValidator.required, FormValidator.etherAddress],
      value: isLoginByEmail ? '' : wallet?.address || username,
    },
  })

  useEffect(() => {
    if (states.value.isDirty && !Number.isNaN(withdrawFee.toNumber())) {
      validate('value')
    }
  }, [withdrawFee])

  const fetchTokenBalanceFn = useCallback(
    async (token: Token) => {
      return Promise.resolve(new BigNumber(tokenBalances[getBalanceTokenKey(token.network, token.code)] || 0))
    },
    [tokenBalances],
  )

  const onWithdrawSuccess = async (txnCode: string) => {
    dispatch(addPendingWithdrawTransaction({ code: txnCode }))
    controls.value.onValueChanged('')
  }

  return (
    <OpenEffect openType="grow" duration={0.4}>
      <StyledWrapper flexDirection="column" width="100%" height="100%" justifyContent="space-between" pb="24px">
        <Flex flex="1 1" flexDirection="column">
          <PaymentTokenSelect
            token={selectedToken}
            onTokenSelect={setSelectedToken}
            fetchTokenBalanceFn={fetchTokenBalanceFn}
            networks={networks}
          />

          {selectedToken === HUSD_TOKEN ? (
            <HUSDInfo />
          ) : (
            <>
              {loginBy === LoginMethod.Email && (
                <StyledFormControl
                  mt="16px"
                  state={states.receiveAddress}
                  label={t('Address')}
                  formatErrorMessage={(errors) => WithdrawInputErrorMessages.receiveAddress[errors[0]]}
                >
                  <FormInput
                    tabIndex={1}
                    control={controls.receiveAddress}
                    placeholder={t('Address')}
                    type="address"
                    name="address"
                  />
                </StyledFormControl>
              )}

              {bonusAmount.gt(0) && (
                <StyledReminderBox mt="16px">
                  <Box>
                    <Icons.InfoCircleIcon width="16px" fill={colors.textSubtle} />
                  </Box>
                  <Box ml="5px">
                    <Text fontSize="12px" lineHeight="14.63px" color="textSubtle">
                      <Trans>
                        Please kindly remember to complete the wagering process in order to receive your bonus before
                        proceeding with your withdrawal.
                      </Trans>
                    </Text>

                    <Flex mt="4px" mb="4px">
                      <Text fontSize="12px" lineHeight="14.63px" color="textTertiary">
                        <Trans>Your Bonus</Trans>
                      </Text>
                      <Text fontSize="12px" lineHeight="14.63px" ml="5px" color="textTertiary">
                        {getFullDisplayBalance(bonusAmount, 0, 5)}
                      </Text>
                    </Flex>

                    <Box onClick={() => onDismiss()}>
                      <StyledTextButton as={NextLinkFromReactRouter} to={RouteConfig.BonusCenter} alignItems="center">
                        <Text mr="5px" fontSize="12px" color="#6C727E">
                          <Trans>Details</Trans>
                        </Text>
                        <Icons.ChevronRightIcon width="4.5px" fill={colors.textTertiary} />
                      </StyledTextButton>
                    </Box>
                  </Box>
                </StyledReminderBox>
              )}

              <StyledControlContainer mt="16px" state={states.value}>
                <StyledLabel>
                  <InputLabel mb="0px !important">
                    <Trans>Amount</Trans>
                  </InputLabel>
                  <InputLabel mb="0px !important">
                    <Trans>Withdrawable</Trans>: {getFullDisplayBalance(selectTokenAmount?.amount, 0, 5)}{' '}
                    {selectedToken?.name}
                  </InputLabel>
                </StyledLabel>
                <TokenInput
                  tabIndex={1}
                  value={states.value.value}
                  token={selectedToken}
                  errors={states.value.errors}
                  validators={controls.value.validators}
                  onErrorChanged={controls.value.onErrorChanged}
                  onValueChanged={controls.value.onValueChanged}
                  max={selectTokenAmount?.amount}
                />
                <Flex flexDirection={['column']} justifyContent="space-between">
                  <InputMessage textAlign="right">
                    <Trans>{WithdrawInputErrorMessages.withdrawValue[states.value.errors[0]]}</Trans>
                  </InputMessage>

                  <Text color="textAlt" fontSize="14px" textAlign="right" mt="4px">
                    <Trans>
                      Withdrawal fee:{' '}
                      {{ withdrawFee: Number.isNaN(withdrawFee.toNumber()) ? '...' : withdrawFee.toNumber() }}{' '}
                      {{ currency: selectedToken?.name }}
                    </Trans>
                  </Text>
                </Flex>
              </StyledControlContainer>

              <Flex mt="30px" justifyContent="space-between" height="16px">
                {states.value.value && Number(states.value.value) > withdrawFee.toNumber() && (
                  <>
                    <Text fontSize={['12px', '12px', '14px']} color="primary" bold>
                      <Trans>You will get</Trans>:
                    </Text>

                    <Text fontSize={['12px', '12px', '14px']} color="primary" bold>
                      {getFullDisplayBalance(
                        new BigNumber(states.value.value).minus(withdrawFee),
                        0,
                        selectedToken.decimals,
                      )}{' '}
                      {selectedToken.name}
                    </Text>
                  </>
                )}
              </Flex>
            </>
          )}
        </Flex>
        {selectedToken !== HUSD_TOKEN && (
          <Box width="100%" mt="12px" mb="10px">
            {loginBy === LoginMethod.Email ? (
              <TraditionalWithdrawButton
                width="100%"
                fee={withdrawFee.toString()}
                selectedToken={selectedToken}
                toAddress={states.receiveAddress.value}
                validateAll={validateAll}
                onSuccess={onWithdrawSuccess}
                value={states.value.value}
                disabled={!isValid || !states.value.value}
                tabIndex={1}
              />
            ) : (
              <WalletWithdrawButton
                width="100%"
                selectedToken={selectedToken}
                validateAll={validateAll}
                onSuccess={onWithdrawSuccess}
                value={states.value.value}
                disabled={!isValid || !states.value.value}
                tabIndex={1}
              />
            )}
          </Box>
        )}
      </StyledWrapper>
    </OpenEffect>
  )
}

const StyledTextButton = styled(Flex)`
  cursor: pointer;
  align-items: center;

  svg {
    fill: ${({ theme }) => theme.colors.textAlt1};
  }

  &:hover {
    ${Text} {
      color: ${({ theme }) => theme.colors.text} !important;
    }

    svg {
      fill: ${({ theme }) => theme.colors.text};
    }
  }
`

const StyledReminderBox = styled(Flex)`
  background: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
  padding: 8px;
`

const StyledWrapper = styled(Flex)`
  ${({ theme }) => theme.mediaQueries.xs} {
    min-height: 480px;
  }
`

const StyledLabel = styled(InputLabel)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledFormControl = styled(FormControl)`
  ${StyledInput} {
    height: 46px;
  }
`

export default Withdraw
