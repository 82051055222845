import { ethers } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { useAccount, useChainId } from 'wagmi'

export const useSigner = (): ethers.Signer => {
  const { address, isConnected, connector } = useAccount()
  const [provider, setProvider] = useState(null)
  const chainId = useChainId()

  useEffect(() => {
    if (!connector) return

    const fetch = async () => {
      const provider = await connector.getProvider({ chainId })
      setProvider(provider)
    }

    fetch()
  }, [connector, chainId, isConnected, address])

  const getLibrary = (provider): any => {
    const library = new ethers.providers.Web3Provider(provider) as any
    library.pollingInterval = 12000
    library.mapProvider = provider
    return library
  }

  return useMemo(
    () => (address && isConnected && provider ? getLibrary(provider).getSigner(address) : null),
    [address, isConnected, chainId, provider],
  )
}
