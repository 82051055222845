import { BaseResponse } from 'services/types'

export const CreateBetbySessionMapper = (rawResponse: string): BaseResponse<string> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data.code,
  }
}
export const SportAuthTokenMapper = (rawResponse: string): BaseResponse<string> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data?.jwt,
  }
}
