import { BlogTag } from 'config/types/blog'

/**
 * This tag is used for display new post.
 */
export const newPostTag: BlogTag = {
  id: '-1',
  name: 'New Post',
  slug: 'new-post',
  posts: [],
  seoProps: null,
}

export const featuredPostTag: BlogTag = {
  id: '-11',
  name: 'Featured Post',
  slug: 'featured-post',
  posts: [],
  seoProps: null,
}

export enum PostItemDisplayLimit {
  Home = 6,
  Tag = 12,
  Post = 4,
  Banner = 5,
}
