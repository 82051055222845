import { useEffect, useState } from 'react'
import privateSocket from 'sockets/privateSocket'
import { useAuth } from 'state/auth/hooks'
import { Socket } from 'socket.io-client'

export const usePrivateSocket = (): Socket => {
  const { isSigned } = useAuth()
  const [socket, setSocket] = useState(null)

  useEffect(() => {
    if (!isSigned) return
    setSocket(privateSocket.Socket)

    return () => {
      privateSocket.close()
      setSocket(null)
    }
  }, [isSigned])

  return socket
}
