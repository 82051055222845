import { TableBody } from 'components/Table/Table'
import TableCell from 'components/Table/TableCell'
import TableHeader from 'components/Table/TableHeader'
import TableRow from 'components/Table/TableRow'
import Text from 'components/Text'
import { TextProps } from 'components/Text/types'
import { TransactionStatusEnum } from 'config/types/transaction'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

export const HEIGHT_ROW = 52

export const StyledTableCell = styled(TableCell)`
  border: none !important;
`
export const StyledTableRow = styled(TableRow)<{ startAt?: number }>`
  border: none !important;
  border-radius: ${({ theme }) => theme.radii.tiny};

  &:nth-child(2n + ${({ startAt }) => startAt || 2}) {
    td {
      background: rgba(29, 37, 51, 0.6);
    }
  }
`

export const StyledTableBody = styled(TableBody)``

export const StyledTableHeader = styled(TableHeader)`
  background: transparent;
`

export const TableHeaderValue: React.FC<{ key: string; width: string | number; label: string }> = ({
  key,
  width,
  label,
}) => (
  <StyledTableHeader key={key} padding={16} width={width}>
    <Text textAlign="left" fontSize="14px" color="textSubtle" fontWeight={700}>
      <Trans>{label}</Trans>
    </Text>
  </StyledTableHeader>
)
export const StyledValueTable = styled(Text)`
  font-weight: 300;
  color: ${({ theme }) => theme.colors.textTertiary};
  font-size: 14px;
  word-break: break-all;
  text-align: right;
`

export const TransactionStatus: React.FC<{ status: TransactionStatusEnum } & TextProps> = ({ status, ...props }) => {
  const isPending = [
    TransactionStatusEnum.Pending,
    TransactionStatusEnum.InBatch,
    TransactionStatusEnum.Reviewing,
  ].includes(status)

  return (
    <>
      {isPending ? (
        <Text color="info" fontSize="14px" {...props}>
          <Trans>Pending</Trans>
        </Text>
      ) : status === TransactionStatusEnum.Succeeded ? (
        <Text color="success" fontSize="14px" {...props}>
          <Trans>Success</Trans>
        </Text>
      ) : (
        <Text color="error" fontSize="14px" {...props}>
          <Trans>Expired</Trans>
        </Text>
      )}
    </>
  )
}
