import { useRouter } from 'hooks/useRouter'

const useQueryParam = () => {
  const router = useRouter()

  const removeParam = async (param: string) => {
    const { pathname, query } = router
    const params = new URLSearchParams(query as any)
    params.delete(param)
    await router.replace({ pathname, query: params.toString() }, undefined, { shallow: true })
  }

  const addParam = async (key: string, value: string) => {
    const { pathname, query } = router
    const params = new URLSearchParams(query as any)
    params.set(key, value)
    await router.replace({ pathname, query: params.toString() }, undefined, { shallow: true })
  }

  return {
    removeParam,
    addParam,
  }
}

export default useQueryParam
