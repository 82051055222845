import { sha512Encypt, aes128GcmEncrypt, AesProps } from './cryptography'

// FE Encrypt SHA512
export const encryptPassword = (plaintextPwd: string) => {
  const parsedBase64Pwd = btoa(plaintextPwd)
  return sha512Encypt(parsedBase64Pwd)
}

export const constructEncryptedPassword = (plaintextPwd: string, nonce: { key: string; value: string }) => {
  const hashPassword = encryptPassword(plaintextPwd + nonce.value)

  // BACKEND Encrypt AES 128 GCM
  const encryptedPwd = aes128GcmEncrypt({
    plaintext: plaintextPwd + hashPassword,
    base64Key: nonce.key,
    base64Nonce: nonce.value,
    encoding: 'base64',
  } as AesProps)
  return encryptedPwd
}
