import BigNumber from 'bignumber.js'
import Box from 'components/Box/Box'
import Skeleton from 'components/Skeleton'
import Table from 'components/Table'
import { useTable } from 'components/Table/hooks'
import Paging from 'components/Table/Paging'
import { TableBody } from 'components/Table/Table'
import TableCell from 'components/Table/TableCell'
import TableHeader from 'components/Table/TableHeader'
import TableRow from 'components/Table/TableRow'
import Text from 'components/Text'
import configTokens from 'config/constants/tokens'
import { Trans } from 'react-i18next'
import LuckySpinService from 'services/LuckySpinService'
import { Paging as APIPaging } from 'services/types'
import styled from 'styled-components'
import { range } from 'utils'
import { formatUsername } from 'utils/stringHelper'
import { SpinWinnerHistoryColumnSchema } from '../types'
import PrizeWinnerRow, { RowProps } from './PrizeWinnerRow'

const fetchTableData = async (offset: number, limit: number): Promise<APIPaging<RowProps>> => {
  const response = await LuckySpinService.getMyPrizeTransactions({ limit, offset }).call()

  const rowData: RowProps[] = response.data.items
    .map((transaction) => {
      const token = configTokens[transaction.network][transaction.currency]

      if (token)
        return {
          prize: {
            token,
            amount: new BigNumber(transaction.amount),
          },
          token,
          address: transaction.address,
          createTime: transaction.createTime,
        }
      return null
    })
    .filter((item) => item)

  return {
    paging: response.data.paging,
    items: rowData,
  }
}

const MyWinTable = () => {
  const limit = 10
  const { rows, headers, pagination, isLoading } = useTable(SpinWinnerHistoryColumnSchema, fetchTableData, {
    displayPerPage: limit,
  })
  return (
    <StyledContainer>
      <Box position="relative">
        <StyledTable>
          <thead>
            <tr>
              {headers.map((header) => (
                <TableHeader key={header.name} padding="16px" width={header.width} background="none !important">
                  <Text textAlign="left" fontSize="16px" bold color="textSubtle">
                    <Trans>{header.label}</Trans>
                  </Text>
                </TableHeader>
              ))}
            </tr>
          </thead>
          <TableBody>
            {isLoading ? (
              <>
                {range(0, 7).map((idx) => (
                  <TableRow key={`table-row-loader-${idx}`}>
                    <TableCell className="loader" colSpan={100} pl="0px !important" border="none !important">
                      <Skeleton width="100%" height="52px" my={1} />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <>
                {rows.map((row) => (
                  <PrizeWinnerRow
                    {...row.original}
                    address={formatUsername(row.original.address)}
                    key={`table-row-${row.id}`}
                  />
                ))}
              </>
            )}
          </TableBody>
        </StyledTable>

        <Paging pagination={pagination} mt="24px" justifyContent="flex-end" />
      </Box>
    </StyledContainer>
  )
}

const StyledContainer = styled(Box)`
  margin-top: 32px;
`

const StyledTable = styled(Table)`
  border-collapse: unset;
`

export default MyWinTable
