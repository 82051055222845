import { BaseResponse, Paging, TopWin } from 'services/types'

export const TopWinMapper = (rawResponse: string): BaseResponse<Paging<TopWin>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      ...response.data,
      items: response.data.items.map((item: any) => parseTopWin(item)),
    },
  }
}

const parseTopWin = (item: any): TopWin => {
  return {
    amount: item.amount,
    gameName: item.game_name,
    providerName: item.provider_name,
    thumbnail: item.game_banner_url,
    gameSlug: item.game_slug,
  }
}
