import { ALL_GAME_TAG } from 'config/constants/gameTags'
import { ChainIdEnum } from 'config/constants/network'
import { APIEndpointEnum } from 'config/constants/server'
import { ScreenTag } from 'config/types'
import { CollectionDetail } from 'config/types/collection'
import { Game, GameDetail, GamePlayMode, GameSession } from 'config/types/game'
import { formatToApiNetworkField } from 'utils'
import { HunnyPlayRequest } from './HunnyPlayRequest'
import { BaseResponse, HunnyRequest, Paging, TopWin } from './types'

export type GameFilterParams = {
  providerCodes?: string[]
  tags?: string[]
  query?: string
  gameIds?: number[]
  gameCollectionId?: number
  slug?: string
}
class GameService extends HunnyPlayRequest {
  public getGames(filter: GameFilterParams, offset = 0, limit = 1000): HunnyRequest<BaseResponse<Paging<Game>>> {
    const { query = '', providerCodes = [], tags = [ALL_GAME_TAG] } = filter

    const params = {
      query,
      filter: {},
      required_tags: null,
      provider_codes: providerCodes,
      paging: {
        offset,
        limit,
      },
    } as any

    if (filter?.gameIds && filter?.gameIds.length > 0) {
      params.filter.game_ids = filter.gameIds
    }

    if (filter?.slug) {
      params.filter.slug = filter.slug
    }

    const parseTags = tags.filter((tag) => tag != ALL_GAME_TAG)
    if (parseTags.length > 0) {
      params.required_tags = tags.filter((tag) => tag != ALL_GAME_TAG)
    }

    let result: HunnyRequest<BaseResponse<Paging<Game>>>
    if (filter.gameCollectionId) {
      result = this._post(APIEndpointEnum.BonusGameCollection, { ...params, id: filter.gameCollectionId })
    } else {
      result = this._post(APIEndpointEnum.Games, params)
    }

    return result
  }

  public getGameDetails(slug: string, code?: string): HunnyRequest<BaseResponse<GameDetail>> {
    return this._post(APIEndpointEnum.GameDetail, {
      slug,
      code,
    })
  }

  public createGameSession(
    gameCode: string,
    currency: string,
    chainId: ChainIdEnum,
  ): HunnyRequest<BaseResponse<GameSession>> {
    return this._post(APIEndpointEnum.CreateGameSession, {
      game_code: gameCode,
      coin: {
        currency,
        network: formatToApiNetworkField(ChainIdEnum[chainId]),
      },
      play_mode: GamePlayMode.NORMAL,
    })
  }

  public createDemoSession(gameCode: string): HunnyRequest<BaseResponse<GameSession>> {
    return this._post(APIEndpointEnum.CreateGameSessionDemo, {
      code: gameCode,
    })
  }

  public getGameCollections(offset = 0, limit = 100): HunnyRequest<BaseResponse<Paging<CollectionDetail>>> {
    const params = {
      paging: {
        offset,
        limit,
      },
    }

    const result: HunnyRequest<BaseResponse<Paging<CollectionDetail>>> = this._post(
      APIEndpointEnum.GameCollectionList,
      params,
    )

    return result
  }

  public getGameCollectionDetail(code: string, offset = 0, limit = 100): HunnyRequest<BaseResponse<Paging<Game>>> {
    const params = {
      code,
      paging: {
        offset,
        limit,
      },
    }
    const result: HunnyRequest<BaseResponse<Paging<Game>>> = this._post(APIEndpointEnum.GameCollectionDetail, params)

    return result
  }

  public getTopWins(): HunnyRequest<BaseResponse<Paging<TopWin>>> {
    const result: HunnyRequest<BaseResponse<Paging<TopWin>>> = this._post(APIEndpointEnum.TopWinList)

    return result
  }

  public getGameTagByProvider(providerCode: string): HunnyRequest<BaseResponse<Paging<ScreenTag>>> {
    const result: HunnyRequest<BaseResponse<Paging<ScreenTag>>> = this._post(APIEndpointEnum.ScreenTagList, {
      screen_code: providerCode,
    })

    return result
  }
}

const instance = new GameService()

export default instance
