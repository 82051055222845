import BigNumber from 'bignumber.js'
import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Heading from 'components/Heading/Heading'
import Image from 'components/Image/Image'
import { ModalBody, ModalHeader } from 'components/Modal'
import Modal, { ModalProps } from 'components/Modal/Modal'
import Table, { TableBody } from 'components/Table/Table'
import Text from 'components/Text/Text'
import { ProfileTierInfo } from 'config/types/profile'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import { RowCenter, RowMiddle } from 'layout/Components/Row'
import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useAppSelector } from 'state'
import { useTiers } from 'state/app/hooks'
import { useProfileTier } from 'state/profile/hooks'
import styled from 'styled-components'
import { revalidateHunnyLevel } from 'utils'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { StyledTableCell, StyledTableHeader, StyledTableRow } from 'views/Profile/Transactions/components/Table'
import { LevelDesktopColumnSchema, LevelMobileColumnSchema } from './types'

const StyledLevelTableCell = styled(StyledTableCell)`
  padding-left: 16px;
`
const StyledText = styled(Text).attrs({
  color: 'textTertiary',
  fontWeight: 400,
  fontSize: ['10px', '12px', '14px'],
})``

const StyledTableRowTier = styled(StyledTableRow)`
  height: 56px;
  position: relative;
`

const StyledMyLevel = styled(RowCenter)`
  border-radius: 4px 0px 0px 0px;
  font-size: 8px;
  line-height: 10px;

  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  transform: translate(-22%, -100%);

  width: 56px;
  height: 14px;
`

const RowTier: React.FC<{
  tier: ProfileTierInfo
  isMyLevel: boolean
  isBonusLevel: boolean
  isMobile: boolean
  isXs: boolean
}> = ({ tier, isMyLevel, isBonusLevel, isMobile, isXs }) => {
  return (
    <StyledTableRowTier startAt={1}>
      <StyledLevelTableCell>
        <RowMiddle>
          {!isXs && (
            <Box mr="10px">
              <Image src={tier.logoUrl} width={32} height={32} />
            </Box>
          )}
          <StyledText
            style={{
              position: 'relative',
            }}
          >
            {(isMyLevel || isBonusLevel) && (
              <StyledMyLevel top={isXs && '-2px !important'}>
                <Trans>{isBonusLevel ? 'Bonus Level' : 'Base Level'}</Trans>
              </StyledMyLevel>
            )}
            VIP {revalidateHunnyLevel(tier?.level)}
          </StyledText>
        </RowMiddle>
      </StyledLevelTableCell>

      {!isMobile && (
        <StyledLevelTableCell>
          <StyledText>{tier.name}</StyledText>
        </StyledLevelTableCell>
      )}

      <StyledLevelTableCell>
        <StyledText>${getFullDisplayBalance(new BigNumber(tier.minWagerUsd), 0, 0)}</StyledText>
      </StyledLevelTableCell>

      <StyledLevelTableCell pl="0 !important">
        <Flex alignItems="center" mx="auto">
          <Text fontSize={['10px', '12px', '14px']} fontWeight={600}>
            {tier.mainCashbackPercentage}%
          </Text>
        </Flex>
      </StyledLevelTableCell>

      <StyledLevelTableCell>
        <StyledText>${getFullDisplayBalance(new BigNumber(tier.bonusUsd), 0, 0)}</StyledText>
      </StyledLevelTableCell>
    </StyledTableRowTier>
  )
}

const AllLevelModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const tiers = useTiers()
  const tier = useAppSelector((state) => state.profile.tier)
  const { isMobile, isXs } = useMatchBreakpoints()
  const userTier = useProfileTier()

  const parseTiers = useMemo(
    () =>
      tiers.reduce((state, tier) => {
        state[tier.code] = tier
        return state
      }, {}),
    [tiers],
  )

  const schema = isMobile ? LevelMobileColumnSchema : LevelDesktopColumnSchema

  return (
    <Modal minWidth={['100%', '', '600px !important']} onDismiss={onDismiss}>
      <ModalHeader pl="24px">
        <Heading scale="xs">
          <Trans>All Levels</Trans>
        </Heading>
      </ModalHeader>
      <ModalBody overflowY="auto" px={['8px', '', '24px']} py={['8px', '', '24px']} maxHeight="600px" width="100%">
        <Table>
          <thead>
            <tr>
              {schema.map((header) => (
                <StyledTableHeader key={`table-header-all-level-${header.label}`} padding={16} width={header.width}>
                  <Text
                    mx={header.center ? 'auto' : ''}
                    fontSize={['10px', '', '14px']}
                    color="textSubtle"
                    fontWeight={700}
                  >
                    <Trans>{header.label}</Trans>
                  </Text>
                </StyledTableHeader>
              ))}
            </tr>
          </thead>
          <Box height="8px" />
          <TableBody>
            {tiers?.map((row) => (
              <RowTier
                tier={parseTiers?.[row.code]}
                isMyLevel={tier?.code === row.code}
                isBonusLevel={userTier?.code !== tier?.code && userTier?.code === row.code}
                key={`table-row-${row.id}`}
                isMobile={isMobile}
                isXs={isXs}
              />
            ))}
          </TableBody>
        </Table>
      </ModalBody>
    </Modal>
  )
}

export default AllLevelModal
