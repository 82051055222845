import { PublicKey } from '@solana/web3.js'

export const TOKEN_PROGRAM_ID = new PublicKey('4nCjq1Wp5BUaGjimH92SH7qB5Jo8tbkx4pwsirC9WPbx')
export const RENT_TOKEN = 'SysvarRent111111111111111111111111111111111'
export const FALLBACK_SOL_TRANSFER_FEE = 0.00005

export const TOKEN_PROGRAM_ACCOUNT = new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA')
export const HUNNYPLAY_SENDER_ACCOUNT = new PublicKey('HrxKt8pwwP7WGcLooMmgGUcVkijRFBk8DndxwXPYm8qa')

export const SYSTEM_PROGRAM_ID = new PublicKey('11111111111111111111111111111111')
export const SOL_USDT_MINT_KEYPAIR = [
  86, 46, 5, 134, 109, 169, 40, 84, 183, 3, 230, 181, 60, 223, 106, 22, 43, 253, 117, 56, 53, 143, 130, 100, 162, 163,
  211, 137, 161, 189, 185, 33, 4, 13, 196, 65, 56, 157, 5, 167, 94, 110, 179, 255, 39, 125, 9, 191, 188, 54, 134, 245,
  231, 219, 55, 137, 11, 170, 174, 17, 182, 42, 254, 249,
]
export const SEED_ARRAY = ['token_holder']

/**
 * @getOrCreateAssociatedAccount active to create associated account when its not available
 *  @mintTo mint testnet tokens
 */
export const DEV_SUPPORTED_METHODS: Record<string, any> = {
  getOrCreateAssociatedAccount: false,
  mintTo: {
    active: false,
    mintAddresses: {
      USDT: new PublicKey('72Zu6BSqJ87Be4KxmzqdQcGDF3mB6Tm1VgNb6WvWrsNS').toBase58(),
    },
    mintValues: {
      USDT: 10000000 * 10 ** 9, /// Value * token decimals
    },
  },
}
