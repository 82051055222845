import BigNumber from 'bignumber.js'
import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Grid from 'components/Box/Grid'
import Button from 'components/Button'
import FormControl from 'components/FormControl'
import FormCheckbox from 'components/FormControl/FormCheckbox'
import FormInput from 'components/FormControl/FormInput'
import { InputContainer } from 'components/Input/styled'
import Modal, { ModalBody, ModalHeader, ModalProps } from 'components/Modal'
import Text from 'components/Text'
import FormValidator from 'config/constants/formValidator'
import { ValidationError } from 'config/types/validator'
import useForm, { FieldState } from 'hooks/useForm'
import { useRequest } from 'hooks/useRequest'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ReferralService from 'services/ReferralService'
import styled from 'styled-components'
import theme from 'theme'
import { HunnyToast } from 'utils/toastify'
import { REFERRAL_COMMISION_MAX_PERCENT } from '../utils'

export const MAX_LIMIT_CREATE_CAMPAIGN = 100
interface ReferralCreateCampaignModalProps {
  onCampaignCreated: () => void
}

const ReferralCreateCampaignModal: React.FC<ModalProps<ReferralCreateCampaignModalProps>> = ({
  data: { onCampaignCreated },
  onDismiss,
}) => {
  const { t } = useTranslation()
  const { execute } = useRequest()

  const [myCommissionPercent, setMyCommisionPercent] = useState<BigNumber>(
    new BigNumber(REFERRAL_COMMISION_MAX_PERCENT),
  )
  const [refereeCommisionPercent, setRefereeCommissionPercent] = useState<BigNumber>(new BigNumber(0))
  const [activeCommisionPercent, setActiveCommisionPercent] = useState<number>(0)
  const [submiting, setSubmiting] = useState<boolean>(false)

  const handleUpdateCampaignCommision = (percent: number) => {
    setMyCommisionPercent(new BigNumber(REFERRAL_COMMISION_MAX_PERCENT).minus(percent))
    setRefereeCommissionPercent(new BigNumber(percent))
    setActiveCommisionPercent(percent)
  }

  const { states, controls, isValid } = useForm({
    campaignName: {
      validators: [FormValidator.required, FormValidator.inputLength({ min: 1, max: 64 })],
      value: '',
    },
    isCampaignDefault: {
      value: false,
    },
  })

  const handleCreateCampaign = async () => {
    setSubmiting(true)

    const response = await execute(ReferralService.getListCampaign())
    if (response && response.data.paging.total >= MAX_LIMIT_CREATE_CAMPAIGN)
      return HunnyToast.error(t('Max campaign limit reached'))

    const campaignResponse = await execute(
      ReferralService.createReferralCampaign({
        campaignName: states.campaignName.value?.trim(),
        refereeCommisionPercent: refereeCommisionPercent.toString(),
        myCommisionPercent: myCommissionPercent.toString(),
      }),
    )

    if (campaignResponse && campaignResponse.data) {
      if (states.isCampaignDefault.value) await execute(ReferralService.setDefaultCampaign(campaignResponse.data.id))

      HunnyToast.success(
        t('Create campaign {{campaignName}} successfully', {
          campaignName: campaignResponse.data.name,
        }),
      )

      onCampaignCreated()
      onDismiss()
    }
  }

  const formatCampaignErrorMessages = (errors: ValidationError[]) => {
    if (errors[0] === ValidationError.Required) {
      return (
        <Text color={theme.colors.error}>
          <Trans>Campaign name is required</Trans>
        </Text>
      )
    }
    if (errors[0] === ValidationError.TooLong) {
      return (
        <Text color={theme.colors.error}>
          <Trans>The campaign name limit is 64 characters</Trans>
        </Text>
      )
    }
  }

  return (
    <Modal maxWidth="724px !important" onDismiss={onDismiss}>
      <ModalHeader p={20}>
        <Text color="primary" fontSize="16px" bold>
          <Trans>New Campaign</Trans>
        </Text>
      </ModalHeader>

      <ModalBody p={20}>
        <Box mt={18}>
          <Text fontSize="14px" color="textSubtle">
            <Trans>Your base commision rate</Trans>: {REFERRAL_COMMISION_MAX_PERCENT}%
          </Text>
          <Text fontSize="14px" color="textSubtle" mt="12px">
            <Trans>Set Friend's Commission Kickback Rate</Trans>:
          </Text>
        </Box>

        <Flex justifyContent="space-between" py={20} my={10}>
          <Flex flexDirection="column">
            <Text fontSize="14px" color="textSubtle">
              <Trans>You Get</Trans>
            </Text>
            <Box
              minWidth={150}
              py="10px"
              px="15px"
              border={`1px solid ${theme.colors.strokeAlt1}`}
              background={theme.colors.backgroundAlt}
              borderRadius={theme.radii.small}
              my={10}
            >
              <Text>{myCommissionPercent.toString()}%</Text>
            </Box>
          </Flex>

          <Flex flexDirection="column">
            <Text fontSize="14px" color="textSubtle">
              <Trans>Friends Get</Trans>
            </Text>
            <Box
              minWidth={150}
              py="10px"
              px="15px"
              border={`1px solid ${theme.colors.strokeAlt1}`}
              background={theme.colors.backgroundAlt}
              borderRadius={theme.radii.small}
              my={10}
            >
              <Text>{refereeCommisionPercent.toString()}%</Text>
            </Box>
          </Flex>
        </Flex>

        <Grid gridTemplateColumns={['1fr 1fr 1fr 1fr']} gridGap={2} width="100%" my={20}>
          <Button
            variant={activeCommisionPercent === 0 ? 'highlight' : 'secondary'}
            onClick={() => handleUpdateCampaignCommision(0)}
          >
            <Text>0%</Text>
          </Button>
          <Button
            variant={activeCommisionPercent === 0.25 ? 'highlight' : 'secondary'}
            onClick={() => handleUpdateCampaignCommision(0.25)}
          >
            <Text>0.25%</Text>
          </Button>
          <Button
            variant={activeCommisionPercent === 0.5 ? 'highlight' : 'secondary'}
            onClick={() => handleUpdateCampaignCommision(0.5)}
          >
            <Text>0.5%</Text>
          </Button>
          <Button
            variant={activeCommisionPercent === 0.75 ? 'highlight' : 'secondary'}
            onClick={() => handleUpdateCampaignCommision(0.75)}
          >
            <Text>0.75%</Text>
          </Button>
        </Grid>

        <Box as="form">
          <StyledFormControl
            state={states.campaignName}
            label={t('Campaign Name')}
            formatErrorMessage={formatCampaignErrorMessages}
            my={12}
          >
            <FormInput
              tabIndex={1}
              control={controls.campaignName}
              placeholder={t('Campaign Name')}
              name="campaign-name"
            />
          </StyledFormControl>

          <StyledFormControl state={states.isCampaignDefault} formatErrorMessage={formatCampaignErrorMessages} my={12}>
            <Flex alignItems="center">
              <FormCheckbox width="18px" height="18px" control={controls.isCampaignDefault} tabIndex={1} />
              <Text fontSize="14px" color="textSubtle" ml={10} bold>
                <Trans>Make Default Campaign</Trans>
              </Text>
            </Flex>
          </StyledFormControl>
        </Box>

        <Button
          disabled={!states.campaignName.value?.length || submiting || !isValid}
          mt={24}
          onClick={handleCreateCampaign}
        >
          <Text>
            <Trans>Submit</Trans>
          </Text>
        </Button>
      </ModalBody>
    </Modal>
  )
}

const StyledFormControl = styled(FormControl)<{ state: FieldState }>`
  ${({ state, theme }) =>
    state.errors.length === 0 && state.isDirty
      ? `
     ${InputContainer} {
        background-color: transparent !important;
        border-color: ${theme.colors.strokeAlt} !important;
      }
    `
      : ''}
`

export default ReferralCreateCampaignModal
