import io, { Socket, SocketOptions, ManagerOptions } from 'socket.io-client'

export abstract class BaseSocket {
  protected abstract _buildConfig(): Partial<ManagerOptions & SocketOptions>

  private _socket: Socket
  private _path: string

  public get Socket() {
    if (!this._socket) {
      this._build()
    }

    return this._socket
  }

  constructor(path: string) {
    this._path = path
  }

  protected _build() {
    this._socket = io(this._path, this._buildConfig())
  }

  public close() {
    this._socket?.close()
    this._socket = null
  }
}
