import Flex from 'components/Box/Flex'
import styled from 'styled-components'

export const RowMiddle = styled(Flex)`
  align-items: center;
`

export const RowBetween = styled(RowMiddle)`
  justify-content: space-between;
  flex: 1;
`

export const RowCenter = styled(RowMiddle)`
  justify-content: center;
`
