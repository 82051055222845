import { DepositBonusInfo } from 'config/types'
import { Promotion, PromotionDetails, PromotionDetailsSerialized } from 'config/types/promotion'
import { BaseResponse, Paging } from 'services/types'

export const parsePromotion = (promoItem): Promotion => ({
  id: promoItem.id,
  title: promoItem.name,
  imgUrl: promoItem.thumb_url,
  menuImageUrl: promoItem.thumb_navi_url,
  bannerUrl: promoItem.banner_url,
  slug: promoItem.slug,
  type: promoItem.type,
  fromTime: new Date(promoItem.from_time * 1000),
  endTime: new Date(promoItem.to_time * 1000),
})

export const ListPromoMapper = (rawResponse: string): BaseResponse<Paging<Promotion>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: {
      ...response.data,
      items: response.data?.contents?.map((promoItem) => parsePromotion(promoItem)) || [],
    },
  }
}

export const PromotionDetailsMapper = (rawResponse: string): BaseResponse<PromotionDetailsSerialized> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  const promotion = response.data

  return {
    code: response.code,
    message: response.message,
    data: promotion.id
      ? {
          id: promotion.id,
          slug: promotion.slug,
          title: promotion.name,
          bannerUrl: promotion.banner_url,
          redirectUrl: promotion.redirect_url,
          menuImageUrl: promotion.thumb_navi_url,
          thumbUrl: promotion.thumb_url,
          content: promotion.content,
          fromTime: promotion.from_time * 1000,
          endTime: promotion.to_time * 1000,
        }
      : null,
  }
}

export const serializePromotionDetails = (promotion: PromotionDetails): PromotionDetailsSerialized => {
  return {
    ...promotion,
    fromTime: promotion.fromTime.getTime(),
    endTime: promotion.endTime.getTime(),
  }
}

export const deserializePromotionDetails = (promotion: PromotionDetailsSerialized): PromotionDetails => {
  if (!promotion) return {} as PromotionDetails

  return {
    ...promotion,
    fromTime: new Date(promotion.fromTime),
    endTime: new Date(promotion.endTime),
  }
}

export const DepositBonusMapper = (rawResponse: string): BaseResponse<DepositBonusInfo> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  const config: any[] = response.data.promotion?.config?.deposit_configs
  const userDepositTime = response.data.deposit_no
  return {
    code: response.code,
    data: response.data && {
      config: config?.map((tierBonus) =>
        tierBonus.bonus_ranges?.map((item: any) => ({
          gteConditionAmount: Number(item.from_amount),
          percent: item.percentage,
        })),
      ),
      endAt: (response.data.promotion?.to_time || 0) * 1000,
      userDepositTime,
    },
  }
}
