import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import OpenEffect from 'components/OpenEffect'
import Text from 'components/Text'
import { LoginMethod } from 'config/constants/auth'
import { ChainIdEnum, NETWORK_MAP } from 'config/constants/network'
import { RouteConfig } from 'config/constants/route'
import { HUSD_TOKEN } from 'config/constants/tokens'
import { Token } from 'config/types'
import { WalletType } from 'config/types/wallet'
import useAuthenticationModal from 'hooks/useAuthenticationModal'
import { useRouter } from 'hooks/useRouter'
import { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useAppSelector } from 'state'
import { useListNetworks } from 'state/app/hooks'
import { useUserInfo } from 'state/auth/hooks'
import { useTokenSelected } from 'state/session/hooks'
import styled from 'styled-components'
import theme from 'theme'
import { getCurrencyBalance } from 'utils/infura'
import { validateTokenBlackList } from 'utils/token'
import GameErrorMessage from 'views/GameDetails/GameErrorMessage'
import HUSDInfo from '../HUSDInfo'
import PaymentTokenSelect from '../PaymentTokenSelect'
import DepositByContract from './DepositByContract'
import DepositByQRCode from './DepositByQRCode'
import WaitingDepositBonusList from './WaitingDepositBonusList'
import { useWatingDepositToActiveBonus } from './hooks'

const Deposit = () => {
  const [_, onDismiss] = useAuthenticationModal()
  const router = useRouter()
  const { t } = useTranslation()

  const { bonuses, handleSelectedBonus, selectedBonus, isSelectedBonusExpired } = useWatingDepositToActiveBonus()

  const [isDepositing, setIsDepositing] = useState(false)
  const tokenSelected = useTokenSelected()
  const [selectedToken, setSelectedToken] = useState<Token>(tokenSelected)
  const wallet = useAppSelector((state) => state.auth.wallet)
  const address = wallet?.address
  const { loginBy } = useUserInfo()

  const networks = useListNetworks(
    wallet?.type == WalletType.SOL
      ? { selectChains: [ChainIdEnum.SOL, ChainIdEnum.SOL_TESTNET] }
      : { excludeChains: [ChainIdEnum.SOL, ChainIdEnum.SOL_TESTNET] },
  )

  const isDepositByQRCorde = loginBy === LoginMethod.Email

  const fetchTokenBalanceFn = useCallback(
    async (token: Token) => {
      if (!address) return null
      const amount = await getCurrencyBalance(token, address)
      return amount.amount
    },
    [address],
  )

  const isTokenBlackList = validateTokenBlackList(selectedToken)

  return (
    <StyledContainer id="modalpayment" mb="15px" openType="grow" duration={0.4}>
      <PaymentTokenSelect
        disabled={isDepositing}
        token={selectedToken}
        onTokenSelect={(token) => setSelectedToken(token)}
        networks={networks}
        fetchTokenBalanceFn={isDepositByQRCorde ? null : fetchTokenBalanceFn}
      />

      {isTokenBlackList ? (
        <GameErrorMessage
          maxWidth="100% !important"
          my="12px"
          message={t(
            `Deposit with ${selectedToken.name}(${
              NETWORK_MAP[selectedToken.network].networkInfo.shortName
            }) has been suspended.`,
          )}
        />
      ) : (
        <>
          {selectedToken === HUSD_TOKEN ? (
            <HUSDInfo />
          ) : (
            <>
              <WaitingDepositBonusList
                mt="20px"
                disabled={isDepositing}
                selectedToken={selectedToken}
                bonuses={bonuses}
                handleSelectedBonus={handleSelectedBonus}
                selectedBonus={selectedBonus}
                isSelectedBonusExpired={isSelectedBonusExpired}
              />
              {isDepositByQRCorde ? (
                <DepositByQRCode depositToken={selectedToken} />
              ) : (
                <DepositByContract
                  setIsDepositing={setIsDepositing}
                  isDepositing={isDepositing}
                  depositToken={selectedToken}
                  flex="1 1"
                  handleSelectedBonus={handleSelectedBonus}
                  selectedBonus={selectedBonus}
                  isSelectedBonusExpired={isSelectedBonusExpired}
                />
              )}
              <Flex justifyContent="center" mt={2}>
                <Box
                  id="link-querydeposit"
                  color="textHighlight"
                  display="block"
                  margin="auto"
                  onClick={() => {
                    router.push(RouteConfig.QueryDeposit)
                    onDismiss()
                  }}
                >
                  <Text
                    fontSize="12px"
                    color="textTertiary"
                    fontWeight={300}
                    lineHeight="18px"
                    style={{
                      letterSpacing: '-0.02em',
                      cursor: 'pointer',
                    }}
                  >
                    <Trans>Deposit haven't arrived?</Trans>
                    <span
                      style={{
                        color: theme.colors.textHighlight,
                        fontWeight: 400,
                        marginLeft: '6px',
                      }}
                    >
                      <Trans>Click here</Trans>
                    </span>
                  </Text>{' '}
                </Box>
              </Flex>
            </>
          )}
        </>
      )}
    </StyledContainer>
  )
}

const StyledContainer = styled(OpenEffect)`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.xs} {
    min-height: 470px;
  }
`

export default Deposit
