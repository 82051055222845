import { useRef } from 'react'
import useDebounceCallback from './useDebounceCallback'
import { useIsomorphicEffect } from './useIsomorphicEffect'

const useResizeEventListener = (callback: () => void) => {
  const elementRef = useRef<HTMLDivElement | null>(null)

  const debounce = useDebounceCallback()

  useIsomorphicEffect(() => {
    if (!elementRef.current) return

    const observer = new ResizeObserver(() => {
      debounce(() => {
        callback()
      }, 100)
    })
    observer.observe(elementRef.current)

    return () => {
      observer.disconnect()
    }
  }, [elementRef, callback])
  return { elementRef }
}

export default useResizeEventListener
