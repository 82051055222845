/* eslint-disable no-unused-expressions */
import { Howl } from 'howler'

export class HowlMapper {
  private _spinClickSoundPlayerInstance = null
  private _spinPlaySoundPlayerInstance = null
  private _spinWinSoundPlayerInstance = null

  public get SpinClickSoundPlayer() {
    if (!this._spinClickSoundPlayerInstance) {
      this._spinClickSoundPlayerInstance = new Howl({ src: '/audio/luckyspin/click.mp3' })
    }

    return this._spinClickSoundPlayerInstance
  }

  public get SpinPlaySoundPlayer() {
    if (!this._spinPlaySoundPlayerInstance) {
      this._spinPlaySoundPlayerInstance = new Howl({ src: '/audio/luckyspin/play.mp3' })
    }

    return this._spinPlaySoundPlayerInstance
  }

  public get SpinWinSoundPlayer() {
    if (!this._spinWinSoundPlayerInstance) {
      this._spinWinSoundPlayerInstance = new Howl({ src: '/audio/luckyspin/win.mp3' })
    }

    return this._spinWinSoundPlayerInstance
  }

  public requestAllSpinAudio() {
    this.SpinWinSoundPlayer
    this.SpinPlaySoundPlayer
    this.SpinClickSoundPlayer
  }

  public clearAllSpinAudio() {
    this.SpinClickSoundPlayer?.stop()
    this.SpinPlaySoundPlayer?.stop()
    this.SpinWinSoundPlayer?.stop()
  }
}

export const howlMapper = new HowlMapper()
