import { useEffect, useState } from 'react'
import { Socket } from 'socket.io-client'
import publicSocket from 'sockets/publicSocket'

export const usePublicSocket = (): Socket => {
  const [socket, setSocket] = useState(null)

  useEffect(() => {
    setSocket(publicSocket.Socket)

    return () => {
      publicSocket.close()
      setSocket(null)
    }
  }, [])

  return socket
}
