import styled from 'styled-components'
import TableCell from './TableCell'

const TableRow = styled.tr`
  width: 100%;
  border-top: ${({ theme: { colors } }) => `0.25px solid ${colors.stroke}`};

  &:last-child {
    ${TableCell} {
      border-bottom: ${({ theme: { colors } }) => `0.25px solid ${colors.stroke}`};
    }

    td:first-child ${TableCell} {
      border-bottom-left-radius: ${({ theme: { radii } }) => radii.default};
    }

    td:last-child ${TableCell} {
      border-bottom-right-radius: ${({ theme: { radii } }) => radii.default};
    }
  }
`

export default TableRow
