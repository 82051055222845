import Box from 'components/Box/Box'
import { BoxProps } from 'components/Box/types'
import Heading from 'components/Heading'
import Modal, { ModalBody, ModalHeader } from 'components/Modal'
import { ModalProps } from 'components/Modal/Modal'
import { BIG_ZERO } from 'config/constants/number'
import { useState } from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import theme from 'theme'
import HistoryTable from './components/HistoryTable'
import HUSDClaim from './components/HUSDClaim'

interface HUSDUnlockProps {}

const HUSDUnlock: React.FC<ModalProps<HUSDUnlockProps & BoxProps>> = ({ onDismiss, ...props }) => {
  const [totalClaimed, setTotalClaimed] = useState(BIG_ZERO)

  return (
    <StyledModal onDismiss={onDismiss} background="#1a202b !important" {...props}>
      <StyledModalHeader>
        <Heading scale="xs" mt="10px">
          <Trans>HUSD Unlock</Trans>
        </Heading>
      </StyledModalHeader>

      <ModalBody
        overflowY="auto"
        display={'block !important'}
        background="linear-gradient(180deg, #15121D 0%, #1A2232 100%) !important"
      >
        <StyledUnlockInfoContainer
          padding={['24px', '', '24px 32px']}
          borderTopLeftRadius={theme.radii.default}
          borderTopRightRadius={theme.radii.default}
        >
          <HUSDClaim setTotalHUSDClaimed={setTotalClaimed} />
        </StyledUnlockInfoContainer>

        <Box
          padding={['24px', '', '24px 32px']}
          background="#1A2131"
          mt="8px"
          borderTopLeftRadius={theme.radii.default}
          borderTopRightRadius={theme.radii.default}
        >
          <HistoryTable totalClaimed={totalClaimed} />
        </Box>
      </ModalBody>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  padding: 0;

  box-sizing: border-box;

  background: ${({ theme: { colors } }) => colors.modalBackground};
  min-height: 85vh;

  overflow-y: hidden;
  border-top-left-radius: ${({ theme }) => theme.radii.large};
  border-top-right-radius: ${({ theme }) => theme.radii.large};

  & .modal-closebutton {
    right: 12px;
    top: 24px;
  }

  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    min-width: 420px;
    max-width: 420px;
    min-height: 576px;
    border-radius: ${({ theme }) => theme.radii.large};
  }
`

const StyledModalHeader = styled(ModalHeader)`
  min-height: calc(100% - 10px);
  display: flex;
  align-items: center;
  padding-left: 24px;
`

const StyledUnlockInfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  width: 100%;

  background: linear-gradient(180deg, #15121d 0%, #1a2232 100%) !important;
  border-radius: ${({ theme }) => theme.radii.modal};
`

export default HUSDUnlock
