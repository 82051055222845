import { useRouter } from 'hooks/useRouter'
import { useCallback, useMemo } from 'react'
import { useAuth } from 'state/auth/hooks'
import { AuthModalMethodEnums, AuthModalPageEnums } from 'views/Authentication/types'
import WelcomePackModal from 'views/BonusCenter/components/WelcomePackageSection/WelcomePackModal'
import HUSDUnlock from 'views/HUSDUnlock'
import Payment, { PaymentPageEnums } from 'views/Payment'
import AllLevelModal from 'views/Profile/Dashboard/components/AllLevelModal'
import useAuthenticationModal from './useAuthenticationModal'
import { useIsomorphicEffect } from './useIsomorphicEffect'
import useLuckySpinModal from './useLuckySpinModal'
import useModal from './useModal'
import useQueryParam from './useQueryParam'

export enum ActionQueryEnum {
  Login = 'onLogin',
  LoginEmail = 'onEmailLogin',
  SignUp = 'onSignUp',
  UnlockHUSD = 'unlockHUSD',
  Deposit = 'deposit',
  Withdraw = 'withdraw',
  OpenLuckySpin = 'openLuckySpin',
  OpenAllLevel = 'openAllLevel',
  OpenWelcomePackage = 'openWelcomePackage',
  OpenSupportChat = 'openSupportChat',
}

export const useActionQueryHandler = () => {
  const [onPresentAuthModal] = useAuthenticationModal()
  const [onPresentHUSDModal] = useModal(HUSDUnlock)
  const [onPressentModalPaymnet] = useModal(Payment)
  const [onPressentModalLuckySpin] = useLuckySpinModal()
  const [onPresentAllLevelModal] = useModal(AllLevelModal)
  const [onPresentWelcomePackModal] = useModal(WelcomePackModal)

  const { isSigned, hasSession } = useAuth()
  const actions = useMemo(
    () => ({
      [ActionQueryEnum.Login]: {
        call: () => onPresentAuthModal({ page: AuthModalPageEnums.LOG_IN }),
        condition: !isSigned && !hasSession,
      },
      [ActionQueryEnum.LoginEmail]: {
        call: () => onPresentAuthModal({ page: AuthModalPageEnums.LOG_IN, method: AuthModalMethodEnums.TRADITIONAL }),
        condition: !isSigned && !hasSession,
      },
      [ActionQueryEnum.Deposit]: {
        call: () => onPressentModalPaymnet(),
        condition: isSigned,
        fallback: () => onPresentAuthModal({ page: AuthModalPageEnums.LOG_IN }),
      },
      [ActionQueryEnum.Withdraw]: {
        call: () => onPressentModalPaymnet({ defaultPage: PaymentPageEnums.Withdraw }),
        condition: isSigned,
        fallback: () => onPresentAuthModal({ page: AuthModalPageEnums.LOG_IN }),
      },
      [ActionQueryEnum.UnlockHUSD]: {
        call: () => onPresentHUSDModal(),
        condition: isSigned,
        fallback: () => onPresentAuthModal({ page: AuthModalPageEnums.LOG_IN }),
      },
      [ActionQueryEnum.OpenLuckySpin]: {
        call: () => onPressentModalLuckySpin(),
      },
      [ActionQueryEnum.OpenAllLevel]: {
        call: () => onPresentAllLevelModal(),
      },
      [ActionQueryEnum.SignUp]: {
        call: () => onPresentAuthModal({ page: AuthModalPageEnums.SIGN_UP }),
        condition: !isSigned && !hasSession,
      },
      [ActionQueryEnum.OpenWelcomePackage]: {
        call: () => onPresentWelcomePackModal(),
      },
    }),
    [isSigned, hasSession],
  )

  const handle = useCallback(
    (action: ActionQueryEnum) => {
      if (!action) return
      const selectedAction = actions[action.toString()]
      if (!selectedAction || selectedAction.condition === false) {
        if (selectedAction?.fallback) {
          selectedAction.fallback()
        }
      } else selectedAction.call()
    },
    [actions],
  )

  return handle
}

const useActionQueryListener = () => {
  const router = useRouter()
  const { removeParam } = useQueryParam()

  const handleAction = useActionQueryHandler()

  useIsomorphicEffect(() => {
    const handle = async () => {
      const { action } = router.query
      if (!action) return
      await removeParam('action')
      handleAction(action as ActionQueryEnum)
    }

    handle()
  }, [router.query.action, handleAction])
}

export default useActionQueryListener

