import Box from 'components/Box/Box'
import Button from 'components/Button'
import { ButtonProps } from 'components/Button/types'
import CircleLoader from 'components/Loader/CircleLoader'
import Text from 'components/Text'
import { Token } from 'config/types'
import { useRequest } from 'hooks/useRequest'
import useWalletAccount from 'hooks/useWalletAccount'
import { useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import PaymentService from 'services/PaymentService'
import { useAppSelector } from 'state'
import { WALLET_INFO } from 'config/constants/wallet'
import { Icons } from 'svgs'
import Flex from 'components/Box/Flex'
import { useConnectWallet } from 'hooks/useConnectWallet'
import { colors } from 'theme/colors'
import { formatUsername } from 'utils/stringHelper'
import theme from 'theme'
import useSignMessage from 'hooks/useSignMessage'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

type WalletWithdrawButtonProps = {
  selectedToken: Token
  value: string
  validateAll: () => Promise<boolean>
  onSuccess: (txnId: string) => void
}

const WalletWithdrawButton: React.FC<WalletWithdrawButtonProps & ButtonProps> = ({
  selectedToken,
  validateAll,
  value,
  onSuccess,
  disabled,
  ...props
}) => {
  const { wallet } = useAppSelector((state) => state.auth)
  const walletInfo = useMemo(
    () => WALLET_INFO.find((item) => item.name === wallet.name && item.type === wallet.type),
    [wallet.name, wallet.type],
  )
  const walletConnector = useConnectWallet()
  const signMessage = useSignMessage(wallet.type)

  const accounts = useWalletAccount()
  const account = accounts[wallet?.type]
  const { execute } = useRequest()

  const [submiting, setSubmiting] = useState<boolean>(false)

  const handleWithdraw = async () => {
    setSubmiting(true)
    const isValid = await validateAll()
    if (!isValid) {
      setSubmiting(false)
      return
    }

    const prepareData = await execute(PaymentService.prepareWalletWithdraw(value, wallet.address, selectedToken))

    if (!prepareData.data) {
      setSubmiting(false)
      return
    }

    const messageSignature = await signMessage(prepareData.data.acceptMessageText)

    if (!messageSignature) {
      setSubmiting(false)
      return
    }

    const withdrawResponse = await PaymentService.walletWithdraw(messageSignature, prepareData.data.acceptMessage)

    if (withdrawResponse?.data) {
      onSuccess(withdrawResponse.data)
    }

    setSubmiting(false)
  }

  if (!account) {
    return (
      <Button
        {...props}
        onClick={() => {
          walletConnector[wallet.type].connect(walletInfo.adapter)
        }}
      >
        <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'}>
          <Trans>Connect Wallet</Trans>
        </Text>
      </Button>
    )
  }

  if (account !== wallet.address) {
    return (
      <>
        <Flex
          alignItems="center"
          border={`1px solid ${colors.warning}`}
          padding="12px"
          borderRadius={theme.radii.small}
          {...props}
        >
          <Box mr="12px">
            <Icons.WarningIcon width="24px" fill={colors.warning} />
          </Box>

          <Text bold fontSize="12px" color="warning" textAlign="center" lineHeight="16px">
            <Trans>
              It seems your wallet connected is active different address, please switch to account{' '}
              {{ account: formatUsername(wallet.address) }}
            </Trans>
          </Text>
        </Flex>

        {walletInfo.adapter instanceof WalletConnectConnector && (
          <Button
            mt="12px"
            width="100%"
            onClick={() => {
              walletConnector[wallet.type].connect(walletInfo.adapter)
            }}
          >
            <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'}>
              <Trans>Switch account</Trans>
            </Text>
          </Button>
        )}
      </>
    )
  }

  return (
    <Button disabled={submiting || disabled} onClick={handleWithdraw} {...props}>
      {submiting && (
        <Box mr="12px">
          <CircleLoader />
        </Box>
      )}

      <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'}>
        <Trans>Withdraw</Trans>
      </Text>
    </Button>
  )
}

export default WalletWithdrawButton
