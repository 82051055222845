import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import { BoxProps } from 'components/Box/types'
import Image from 'components/Image/Image'
import Text from 'components/Text/Text'
import { FreespinBonus, NoDepositFreespinBonus, WageringConditionBonus } from 'config/types/bonus/userBonus'
import React from 'react'
import { Trans } from 'react-i18next'
import { useTokenUsdPrice } from 'state/app/hooks'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'
import { getFullDisplayBalance } from 'utils/formatBalance'

interface FreespinBonusBodyProps {
  bonus: WageringConditionBonus
}

const FreespinBonusBody: React.FC<BoxProps & FreespinBonusBodyProps> = ({ bonus, ...props }) => {
  const bonusTokenPriceInUsd = useTokenUsdPrice(bonus.bonusAmount?.token)
  const bonusAmountInUsd = bonusTokenPriceInUsd.multipliedBy(bonus.bonusAmount.amount)
  return (
    <Box {...props}>
      {bonus instanceof NoDepositFreespinBonus ? (
        <>
          <Flex justifyContent="center" alignItems="center">
            <Box width="32px" height="32px">
              <Image src="/images/usd-icon.png" width={64} height={64} />
            </Box>
            <Text fontWeight="800" fontSize="32px" ml="8px" lineHeight="39.01px">
              {getFullDisplayBalance(bonus.bonusBigAmountInUsd)} USD
            </Text>
          </Flex>

          <StyledDescriptionBody justifyContent="center" mt="20px">
            <Box width="16px" height="16px" mr="5px">
              <Icons.InfoCircleIcon width="32px" fill={colors.textDisable} />
            </Box>
            <Text fontWeight="400" fontSize="12px" color="textSubtle" lineHeight="14.63px">
              <Trans>
                Make a deposit from {{ minDeposit: bonus.minDeposit }}$ and complete the necessary wagering requirements
                to get the bonus on your balance
              </Trans>
            </Text>
          </StyledDescriptionBody>

          <StyledDescriptionBody justifyContent="center" mt="8px">
            <Box width="16px" height="16px" mr="5px">
              <Icons.InfoCircleIcon width="32px" fill={colors.textDisable} />
            </Box>
            <Text fontWeight="400" fontSize="12px" color="textSubtle" lineHeight="14.63px">
              <Trans>Your bonus will be automatically converted to your deposit currency</Trans>
            </Text>
          </StyledDescriptionBody>
        </>
      ) : (
        bonus instanceof FreespinBonus && (
          <>
            <Box>
              <Flex justifyContent="center" alignItems="center" mb="8px">
                <Box width="16px" height="16px">
                  <Image src="/images/usd-icon.png" width={64} height={64} />
                </Box>
                <Text fontWeight="800" fontSize="16px" ml="8px" lineHeight="19.5px">
                  {getFullDisplayBalance(bonusAmountInUsd, 0, 2)} USD
                </Text>
              </Flex>
              <Flex justifyContent="center" alignItems="center">
                <Text>~</Text>
                <Box width="16px" height="16px" ml="8px">
                  <Image src={bonus.bonusAmount?.token?.logo} width={64} height={64} />
                </Box>
                <Text fontWeight="800" fontSize="16px" ml="8px" lineHeight="19.5px">
                  {getFullDisplayBalance(bonus.bonusAmount.amount, 0, 9)} {bonus.bonusAmount?.token?.name}
                </Text>
              </Flex>
            </Box>

            <StyledDescriptionBody justifyContent="center" mt="20px">
              <Box width="16px" height="16px" mr="5px">
                <Icons.InfoCircleIcon width="32px" fill={colors.textDisable} />
              </Box>
              <Text fontWeight="400" fontSize="12px" color="textSubtle" lineHeight="14.63px">
                <Trans>
                  Complete the necessary wagering requirements of {{ targetWager: bonus.targetWager }} USD within
                  {{ wagerDurationInDays: bonus.wagerDurationInDays }} days to get the bonus on your balance
                </Trans>
              </Text>
            </StyledDescriptionBody>
          </>
        )
      )}
    </Box>
  )
}

const StyledDescriptionBody = styled(Flex)`
  background: rgba(255, 255, 255, 0.02);
  border-radius: 4px;
  padding: 8px;
`

export default FreespinBonusBody
