import { useVariableLoadedListener } from 'hooks/useVariableLoadedListener'
import { useEffect, useRef } from 'react'

export const PROCESS_CLASS_NAME = 'has-list-process-07c3e491'
export const SHOW_MORE_BTN_CLASS_NAME = 'has-list-btn-40709bd4'
export const NO_CONTENT = 'has-list-btn-415441d0'

const ListStyleUpdater = ({ isDisplayShowMore, isDisplayProgress, isNoContent }) => {
  const ref = useRef<HTMLElement>(null)

  const parent = useVariableLoadedListener(() => {
    return ref.current?.parentElement
  })

  useEffect(() => {
    if (!parent) return

    if (!parent.className.includes('hunny-lazy-list')) {
      parent.className = `${parent.className} hunny-lazy-list`
    }
  }, [parent])

  useEffect(() => {
    if (!parent) return

    if (isDisplayShowMore && !parent.className.includes(SHOW_MORE_BTN_CLASS_NAME)) {
      parent.className = `${parent.className} ${SHOW_MORE_BTN_CLASS_NAME}`
    } else if (!isDisplayShowMore && parent.className.includes(SHOW_MORE_BTN_CLASS_NAME)) {
      parent.className = parent.className.replaceAll(SHOW_MORE_BTN_CLASS_NAME, '')
    }
  }, [parent, isDisplayShowMore])

  useEffect(() => {
    if (!parent) return

    if (isDisplayProgress && !parent.className.includes(PROCESS_CLASS_NAME)) {
      parent.className = `${parent.className} ${PROCESS_CLASS_NAME}`
    } else if (!isDisplayProgress && parent.className.includes(PROCESS_CLASS_NAME)) {
      parent.className = parent.className.replaceAll(PROCESS_CLASS_NAME, '')
    }
  }, [parent, isDisplayProgress])

  useEffect(() => {
    if (!parent) return

    if (isNoContent && !parent.className.includes(NO_CONTENT)) {
      parent.className = `${parent.className} ${NO_CONTENT}`
    } else if (!isNoContent && parent.className.includes(NO_CONTENT)) {
      parent.className = parent.className.replaceAll(NO_CONTENT, '')
    }
  }, [parent, isNoContent])
  return <span style={{ display: 'none' }} ref={ref} />
}

export default ListStyleUpdater
