import tokens, { HUSD_TOKEN } from 'config/constants/tokens'
import { Token } from 'config/types'
import { useMemo } from 'react'
import { useAppSelector } from 'state'

export const useTokenSelected = (): Token => {
  const selectToken = useAppSelector((state) => state.session.selectToken)
  const tokenInfos = useAppSelector((state) => state.app.tokenInfoes)

  return useMemo(() => {
    const selectedToken = selectToken && tokens[selectToken.network] && tokens[selectToken.network][selectToken.token]

    return selectedToken || HUSD_TOKEN
  }, [selectToken, tokenInfos])
}
