// static UI
import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import { BoxProps } from 'components/Box/types'
import Text from 'components/Text/Text'
import { useCountDown } from 'hooks/useCountdown'
import React from 'react'
import { Trans } from 'react-i18next'
import { useUserWelcomePackage } from 'state/bonus/hooks'
import styled from 'styled-components'
import theme from 'theme'

const UserWelcomePackageCountdown: React.FC<BoxProps> = ({ ...props }) => {
  const userWelcomePackageInfo = useUserWelcomePackage()
  const shudownTimeTextRef = useCountDown(
    userWelcomePackageInfo?.expiredAt.getTime(),
    (s, m, h, d) => {
      return `<div>${d.toString().padStart(2, '0')}</div><span>:</span> <div>${h
        .toString()
        .padStart(2, '0')}</div> <span>:</span> <div>${m.toString().padStart(2, '0')}</div> <span>:</span> <div>${s
        .toString()
        .padStart(2, '0')}</div>`
    },
    () => {},
  )

  return (
    <Box
      background="rgba(255, 255, 255, 0.04)"
      py={['9px']}
      px={['6px', '6px', '23px']}
      borderRadius={theme.radii.tiny}
      maxHeight="40px"
      {...props}
    >
      <StyledCountdownContent ref={shudownTimeTextRef} />
      <Flex mt="4px">
        <Text fontSize="10px" color="textAlt1" width={['20px', '20px', '28px']} textAlign="center">
          <Trans>day</Trans>
        </Text>
        <Box width="18px" />
        <Text fontSize="10px" color="textAlt1" width={['20px', '20px', '28px']} textAlign="center">
          <Trans>hour</Trans>
        </Text>
        <Box width="18px" />
        <Text fontSize="10px" color="textAlt1" width={['20px', '20px', '28px']} textAlign="center">
          <Trans>min</Trans>
        </Text>
        <Box width="18px" />
        <Text fontSize="10px" color="textAlt1" width={['20px', '20px', '28px']} textAlign="center">
          <Trans>sec</Trans>
        </Text>
      </Flex>
    </Box>
  )
}

const StyledCountdownContent = styled(Flex)`
  color: ${({ theme: { colors } }) => colors.text};
  font-size: 20px;
  font-weight: bold;

  div {
    width: 20px;
    font-size: 12px;
    text-align: center;

    ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
      width: 28px;
    }
  }

  span {
    font-size: 12px;
    color: ${({ theme: { colors } }) => colors.textAlt1};
    width: 18px;
    text-align: center;
  }
`

export default UserWelcomePackageCountdown
