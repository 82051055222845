import { APIEndpointEnum, SERVER_JSON } from 'config/constants/server'
import { ProviderInfo } from 'config/types'
import { HunnyPlayRequest } from './HunnyPlayRequest'
import { BaseResponse, MetaDataResponse, TokenUsdPriceResponse } from './types'

class MetaService extends HunnyPlayRequest {
  public async getServiceInfo(): Promise<{
    shutDownAt: number
    openAt: number
    isEnableMaintenanceMode: boolean
  }> {
    const result = await this._get(`${SERVER_JSON}?time=${Date.now()}`)

    if (!result) {
      return null
    }

    const shutDownAt = result['from_time'] * 1000
    const openAt = result['to_time'] * 1000

    return { shutDownAt, openAt, isEnableMaintenanceMode: result?.status === -1 }
  }

  public async getMetaData(): Promise<BaseResponse<MetaDataResponse>> {
    const result = await this._request(APIEndpointEnum.Meta)

    if (!result) {
      return null
    }

    return result
  }

  public async getProvider(slug: string): Promise<BaseResponse<ProviderInfo>> {
    const result = await this._request(APIEndpointEnum.Provider, { slug })
    if (!result) {
      return null
    }
    return result
  }

  public async getTokenInUsdPrices(): Promise<BaseResponse<TokenUsdPriceResponse>> {
    const result = await this._request(APIEndpointEnum.TokenUsdPrices)
    if (!result) {
      return null
    }
    return result
  }
}

const instance = new MetaService()
export default instance
