import React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import hunnyKeyframes from 'theme/keyframes'

const CopySuccessLoader = () => {
  return (
    <Wrapper>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
        <circle
          className="path circle"
          fill="none"
          stroke={theme.colors.success}
          strokeWidth="6"
          strokeMiterlimit="10"
          cx="65.1"
          cy="65.1"
          r="62.1"
        />
        <polyline
          className="path check"
          fill="none"
          stroke={theme.colors.success}
          strokeWidth="6"
          strokeLinecap="round"
          strokeMiterlimit="10"
          points="100.2,40.2 51.5,88.8 29.8,67.5 "
        />
      </svg>
    </Wrapper>
  )
}

const Wrapper = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;

    circle,
    polyline {
      stroke-dasharray: 1000;
      stroke-dashoffset: 0;
    }

    circle {
      animation: ${hunnyKeyframes.dash} 0.9s ease-in-out;
    }
    polyline {
      stroke-dashoffset: -100;
      animation: ${hunnyKeyframes.dashChecked} 0.9s 0.35s ease-in-out forwards;
    }
  }
`

export default React.memo(CopySuccessLoader)
