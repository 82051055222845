/* eslint-disable no-useless-computed-key */
import { APIEndpointEnum } from 'config/constants/server'
import {
  LoginResponseMapper,
  NewPasswordNonceMapper,
  PasswordNonceResponseMapper,
  PrepareSignMessageMapper,
  RegisterMapper,
  ResetPasswordVerificationResponseMapper,
  UserDataMapper,
  VerificationResponseMapper,
} from './Authenication'
import { PostDetailsMapper, PostsMapper, TagDetailsMapper, TagsMapper } from './Blog'
import {
  ClaimBonusMapper,
  ClaimableBalanceMapper,
  HUSDClaimedAmountMapper,
  HUSDClaimedHistoryMapper,
  LockBalanceMapper,
  SwapBonusMapper,
  UnlockFormulaMapper,
} from './Bonus'
import { CashbackTotalEarnedMapper, CashbackTransactionMapper } from './Cashback'
import { CollectionsGameDetailMapper, CollectionsMapper } from './Collection'
import { FavoriteGamesMapper, GameDetailMapper, GameSessionMapper, GamesMapper, ScreenTagMapper } from './Game'
import { BannerMapper, HomeCategoryMapper } from './Home'
import {
  SpinFreePlayTimeMapper,
  SpinMyPrizeTransactionMapper,
  SpinPlayMapper,
  SpinPrizeListMapper,
  SpinPrizeTransactionMapper,
} from './LuckySpin'
import { MetaDataMapper, ProviderListMapper, ProviderMapper, TokenInUsdPriceMapper } from './Meta'
import { MetaTagMapper } from './MetaTag'
import { PrivateNotificationMapper, SystemNotificationMapper } from './Notification'
import {
  BalanceMapper,
  BonusTransactionMapper,
  DepositTransactionMapper,
  DepositTransactionsMapper,
  PrepareTraditionalWithdrawMapper,
  PrepareWalletWithdrawMapper,
  QueryDepositByHashMapper,
  RewardTransactionsMapper,
  SwapTransactionMapper,
  TraditionalDepositMapper,
  WithdrawFeeMapper,
  WithdrawMapper,
  WithdrawalTransactionMapper,
  WithdrawalTransactionsMapper,
} from './Payment'
import { UserFavoriteGameCodesMapper, UserTierMapper } from './ProfileMapper'
import { DepositBonusMapper, ListPromoMapper, PromotionDetailsMapper } from './Promotions'
import {
  RefereeListMapper,
  ReferralBalanceMapper,
  ReferralCampaignDetailMapper,
  ReferralCampaignMapper,
  ReferralListCampaignMapper,
  ReferralOverviewMapper,
  ReferralPrepareClaimMapper,
} from './Referral'
import { CreateBetbySessionMapper, SportAuthTokenMapper } from './Sport'
import {
  StakingBalanceMapper,
  StakingMyPoolStatsMapper,
  StakingPoolStatsMapper,
  StakingTransactionMapper,
} from './Staking'
import { TopWinMapper } from './TopWin'
import {
  PublicBonusDetailsMapper,
  UserBonusApplyMapper,
  UserBonusDetailsMapper,
  UserBonusMapper,
  UserBonusRedeemMapper,
  UserWageringBonusGameCodesMapper,
} from './Voucher'

class Mapper {
  private _mapper = {
    [APIEndpointEnum.Meta]: MetaDataMapper,
    [APIEndpointEnum.User]: UserDataMapper,
    [APIEndpointEnum.TokenUsdPrices]: TokenInUsdPriceMapper,
    [APIEndpointEnum.DepositBonus]: DepositBonusMapper,
    [APIEndpointEnum.WalletLoginPrepare]: PrepareSignMessageMapper,
    [APIEndpointEnum.SignInByWallet]: LoginResponseMapper,
    [APIEndpointEnum.Refresh]: LoginResponseMapper,
    [APIEndpointEnum.SignUpPrepare]: PasswordNonceResponseMapper,
    [APIEndpointEnum.PrepareTraditionalLogin]: PasswordNonceResponseMapper,
    [APIEndpointEnum.VerifyRegisterUser]: VerificationResponseMapper,
    [APIEndpointEnum.Register]: RegisterMapper,
    [APIEndpointEnum.VerifyResetPassword]: ResetPasswordVerificationResponseMapper,
    [APIEndpointEnum.ValidateResetPassword]: NewPasswordNonceMapper,
    [APIEndpointEnum.ProviderList]: ProviderListMapper,
    [APIEndpointEnum.Provider]: ProviderMapper,
    [APIEndpointEnum.BannerList]: BannerMapper,
    [APIEndpointEnum.HomeCategoryList]: HomeCategoryMapper,
    [APIEndpointEnum.ScreenTagList]: ScreenTagMapper,

    [APIEndpointEnum.Balances]: BalanceMapper,
    [APIEndpointEnum.TraditionalDeposit]: TraditionalDepositMapper,
    [APIEndpointEnum.WithdrawFee]: WithdrawFeeMapper,
    [APIEndpointEnum.PrepareTraditionalWithdraw]: PrepareTraditionalWithdrawMapper,
    [APIEndpointEnum.PrepareWalletWithdraw]: PrepareWalletWithdrawMapper,
    [APIEndpointEnum.WalletWithdraw]: WithdrawMapper,
    [APIEndpointEnum.TraditionalWithdraw]: WithdrawMapper,
    [APIEndpointEnum.QueryDepositByHash]: QueryDepositByHashMapper,

    [APIEndpointEnum.WithdrawalTransaction]: WithdrawalTransactionMapper,
    [APIEndpointEnum.WithdrawalTransactions]: WithdrawalTransactionsMapper,
    [APIEndpointEnum.DepositTransaction]: DepositTransactionMapper,
    [APIEndpointEnum.DepositTransactions]: DepositTransactionsMapper,
    [APIEndpointEnum.RewardTransactions]: RewardTransactionsMapper,
    [APIEndpointEnum.BonusTransactions]: BonusTransactionMapper,
    [APIEndpointEnum.SwapTransactions]: SwapTransactionMapper,

    [APIEndpointEnum.Games]: GamesMapper,
    [APIEndpointEnum.UserFavoriteGames]: FavoriteGamesMapper,
    [APIEndpointEnum.BonusGameCollection]: GamesMapper,
    [APIEndpointEnum.CreateGameSession]: GameSessionMapper,
    ['CREATE_BETBY_SESSION']: CreateBetbySessionMapper,
    [APIEndpointEnum.GameDetail]: GameDetailMapper,
    [APIEndpointEnum.MerchantBetby]: SportAuthTokenMapper,
    [APIEndpointEnum.GameCollectionList]: CollectionsMapper,
    [APIEndpointEnum.GameCollectionDetail]: CollectionsGameDetailMapper,

    [APIEndpointEnum.PromotionList]: ListPromoMapper,
    [APIEndpointEnum.PromotionDetail]: PromotionDetailsMapper,
    [APIEndpointEnum.ReferralOverview]: ReferralOverviewMapper,
    [APIEndpointEnum.ReferralAvailableBalanceClaim]: ReferralBalanceMapper,
    [APIEndpointEnum.ReferralTotalEarned]: ReferralBalanceMapper,
    [APIEndpointEnum.ReferralPrepareClaim]: ReferralPrepareClaimMapper,
    [APIEndpointEnum.ReferralListCampaign]: ReferralListCampaignMapper,
    [APIEndpointEnum.ReferralCampaignDetails]: ReferralCampaignDetailMapper,
    [APIEndpointEnum.ReferralCreateCampaign]: ReferralCampaignMapper,
    [APIEndpointEnum.RefereeList]: RefereeListMapper,
    [APIEndpointEnum.CashbackTransaction]: CashbackTransactionMapper,
    [APIEndpointEnum.CashbackTotalEarned]: CashbackTotalEarnedMapper,

    [APIEndpointEnum.StakingPoolStats]: StakingPoolStatsMapper,
    [APIEndpointEnum.StakingMyPoolStats]: StakingMyPoolStatsMapper,
    [APIEndpointEnum.StakingAvailableClaim]: StakingBalanceMapper,
    [APIEndpointEnum.StakingTotalEarned]: StakingBalanceMapper,
    [APIEndpointEnum.StakingTransaction]: StakingTransactionMapper,

    [APIEndpointEnum.MyTier]: UserTierMapper,
    [APIEndpointEnum.UserFavoriteGameCodes]: UserFavoriteGameCodesMapper,

    [APIEndpointEnum.Posts]: PostsMapper,
    [APIEndpointEnum.Tags]: TagsMapper,
    [APIEndpointEnum.TagDetails]: TagDetailsMapper,
    [APIEndpointEnum.PostDetails]: PostDetailsMapper,
    [APIEndpointEnum.TopWinList]: TopWinMapper,
    [APIEndpointEnum.HUSDLockBalance]: LockBalanceMapper,
    [APIEndpointEnum.HUSDClaimableBalance]: ClaimableBalanceMapper,
    [APIEndpointEnum.ClaimHUSD]: ClaimBonusMapper,
    [APIEndpointEnum.UnlockFormula]: UnlockFormulaMapper,
    [APIEndpointEnum.TotalHUSDClaimed]: HUSDClaimedAmountMapper,
    [APIEndpointEnum.HUSDTxnList]: HUSDClaimedHistoryMapper,
    [APIEndpointEnum.SwapHUSD]: SwapBonusMapper,

    [APIEndpointEnum.SpinInternalPrizeList]: SpinPrizeListMapper,
    [APIEndpointEnum.SpinExternalPrizeList]: SpinPrizeListMapper,
    [APIEndpointEnum.SpinPlay]: SpinPlayMapper,
    [APIEndpointEnum.SpinPrizeTransactions]: SpinPrizeTransactionMapper,
    [APIEndpointEnum.SpinMyPrizeTransactions]: SpinMyPrizeTransactionMapper,

    [APIEndpointEnum.GetSystemNotifications]: SystemNotificationMapper,
    [APIEndpointEnum.GetPrivateNotifications]: PrivateNotificationMapper,
    [APIEndpointEnum.SpinFreePlayTimes]: SpinFreePlayTimeMapper,

    [APIEndpointEnum.UserBonuses]: UserBonusMapper,
    [APIEndpointEnum.UserBonusDetails]: UserBonusDetailsMapper,
    [APIEndpointEnum.BonusDetails]: PublicBonusDetailsMapper,
    [APIEndpointEnum.BonusRedeem]: UserBonusRedeemMapper,
    [APIEndpointEnum.BonusApply]: UserBonusApplyMapper,
    [APIEndpointEnum.WageringBonusGameCodes]: UserWageringBonusGameCodesMapper,

    [APIEndpointEnum.MetaTag]: MetaTagMapper,
  }

  public getMapper(url: string) {
    return (this._mapper as any)[url]
  }
}

const mapper = new Mapper()

export default mapper
