import { ChainIdEnum } from 'config/constants/network'
import { StakingActionTypeEnums } from 'config/constants/staking'
import { StakingPayoutBalanceTokenAmount, StakingPoolStat, StakingTransaction } from 'config/types/staking'
import { BaseResponse, Paging } from 'services/types'
import { formatApiNetworkField } from 'utils'
import { parseStakingPoolStat } from './utils'

export const StakingPoolStatsMapper = (
  rawResponse: any,
): BaseResponse<{ poolStats: StakingPoolStat[]; apy: number }> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      poolStats: parseStakingPoolStat(response.data.pools, 'total_amount'),
      apy: Number(response.data.apy),
    },
  }
}
export const StakingMyPoolStatsMapper = (rawResponse: any): BaseResponse<StakingPoolStat[]> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && parseStakingPoolStat(response.data.items, 'value'),
  }
}

export const StakingBalanceMapper = (rawResponse: any): BaseResponse<Paging<StakingPayoutBalanceTokenAmount>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: {},
      items: response.data.items.map((item) => ({
        currency: item.currency,
        network: ChainIdEnum[formatApiNetworkField(item.network)],
        value: item.value,
      })),
    },
  }
}

export const StakingTransactionMapper = (rawResponse: any): BaseResponse<Paging<StakingTransaction>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: response.data.paging,
      items: response.data.items.map((item) => {
        return {
          id: item.id,
          currency: item.currency,
          network: ChainIdEnum[formatApiNetworkField(item.network)],
          value: item.value,
          action: StakingActionTypeEnums[item.type - 1],
          createTime: item.create_time * 1000,
        } as StakingTransaction
      }),
    },
  }
}
