import Text from 'components/Text'
import { TextProps } from 'components/Text/types'
import { Time } from 'config/constants'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { timeSince } from 'utils'
import { formatDisplayDate, formatDisplayFullDateTime } from 'utils/dateHelper'

export const TimeSince: React.FC<{ date: Date; display?: string } & Omit<TextProps, 'display'>> = ({
  date,
  display = '',
  ...props
}) => {
  const { t } = useTranslation()

  const isDisplayFullDate = useMemo(() => {
    const [_, time] = timeSince(date.getTime())

    if (time === Time.SEC || time === Time.MIN || time === Time.HOUR) return false

    return true
  }, [date])

  const displayTime = useMemo(() => {
    const [timeAmount, time] = timeSince(date.getTime())

    switch (time) {
      case Time.SEC:
        return t('Just now')

      case Time.MIN:
        return timeAmount === 1 ? t('A minute ago') : t('{{amount}} minutes ago', { amount: timeAmount })

      case Time.HOUR:
        return timeAmount === 1 ? t('A hour ago') : t('{{amount}} hours ago', { amount: timeAmount })

      default:
        return display || formatDisplayDate(date)
    }
  }, [date])

  return (
    <Text
      {...props}
      data-tooltip-content={formatDisplayFullDateTime(new Date(date))}
      data-tooltip-id={!isDisplayFullDate ? 'common-tip-bottom' : ''}
    >
      {displayTime}
    </Text>
  )
}
