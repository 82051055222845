import BigNumber from 'bignumber.js'
import Flex from 'components/Box/Flex'
import { BoxProps } from 'components/Box/types'
import Image from 'components/Image/Image'
import Text from 'components/Text/Text'
import { FreeSpinCommonBonus } from 'config/types/bonus'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import Column from 'layout/Components/Column'
import { RowBetween } from 'layout/Components/Row'
import React from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { useDisplayBonusName } from 'views/BonusCenter/hooks'

const WelcomePackageBonusRow: React.FC<BoxProps & { bonus: any; order: string }> = ({ bonus, order, ...props }) => {
  const [name] = useDisplayBonusName(bonus)
  const { isMobile } = useMatchBreakpoints()

  return (
    <Flex minHeight="72px" {...props} alignItems="center" flexWrap={['wrap', 'wrap', 'unset']} padding={'8px 0px'}>
      <Flex flex="1 1" alignItems="center" minWidth={['100%', '', 'unset']}>
        <Image src={bonus.image} width={48} height={48} />
        <Column pl="12px">
          <StyledContentTitle bold>
            <Trans>Bonus {{ order }}</Trans>
          </StyledContentTitle>
          <Text fontSize="16px" bold color="text" lineHeight="20px">
            {name || bonus.name}
          </Text>
          {bonus instanceof FreeSpinCommonBonus && (
            <Text fontWeight={500} color="textSubtle" fontSize="12px" mt={['4px', '', '2px']}>
              <Trans>Bet amount</Trans>: ${getFullDisplayBalance(new BigNumber(bonus.spinPrice), 0, 3)}
            </Text>
          )}
        </Column>
      </Flex>

      <RowBetween height="68px">
        <Column
          borderRadius="5px"
          minWidth="72px"
          width={['calc(33% - 4px)', '', '86px']}
          ml={['0px', '', '20px']}
          alignItems={['center', 'center', 'unset']}
          justifyContent="center !important"
          height={['calc(100% - 12px)', '', '']}
          padding={['6px', '', '0px']}
          background={['rgba(255,255,255, 0.2)', '', 'unset']}
        >
          <StyledContentTitle $isMobile={isMobile}>
            <Trans>Min Deposit</Trans>
          </StyledContentTitle>
          <Text color="textTertiary" fontSize="13px" fontWeight={700}>
            {bonus.minDeposit ? `$${bonus.minDeposit}` : '-'}
          </Text>
        </Column>

        <Column
          borderRadius="5px"
          minWidth="66px"
          ml={['6px', '', '20px']}
          width={['calc(33% - 4px)', '', '86px']}
          alignItems={['center', 'center', 'unset']}
          justifyContent="center !important"
          height={['calc(100% - 12px)', '', '']}
          padding={['6px', '', '0px']}
          background={['rgba(255,255,255, 0.2)', '', 'unset']}
        >
          <StyledContentTitle $isMobile={isMobile}>
            <Trans>Max Bonus</Trans>
          </StyledContentTitle>
          <Text color="textTertiary" fontSize="13px" fontWeight={700}>
            {bonus.maxBonusAmountInUsd ? `$${getFullDisplayBalance(new BigNumber(bonus.maxBonusAmountInUsd))}` : '-'}
          </Text>
        </Column>

        <Column
          borderRadius="5px"
          minWidth="40px"
          ml={['6px', '', '20px']}
          width={['calc(33% - 4px)', '', '40px']}
          alignItems={['center', 'center', 'unset']}
          justifyContent="center !important"
          height={['calc(100% - 12px)', '', '']}
          padding={['6px', '', '0px']}
          background={['rgba(255,255,255, 0.2)', '', 'unset']}
        >
          <StyledContentTitle $isMobile={isMobile}>
            <Trans>Wager</Trans>
          </StyledContentTitle>
          <Text color="textTertiary" fontSize="13px" fontWeight={700}>
            {bonus.wagerMultiplier ? `X${bonus.wagerMultiplier}` : '-'}
          </Text>
        </Column>
      </RowBetween>
    </Flex>
  )
}

const StyledContentTitle = styled(Text)<{ $isMobile?: boolean }>`
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 14.5px;
  color: ${({ theme: { colors } }) => colors.textSubtle};

  text-align: ${({ $isMobile }) => ($isMobile ? 'center' : 'left')};

  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    text-align: left;
  }
`

export default WelcomePackageBonusRow
