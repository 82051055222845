import BigNumber from 'bignumber.js'
import { BIG_TEN } from 'config/constants/number'

/**
 * Take a formatted amount, e.g. 15 BNB and convert it to full decimal value, e.g. 15000000000000000
 */
export const getDecimalAmount = (amount: BigNumber | number | string, decimals = 18) => {
  return new BigNumber(amount).times(BIG_TEN.pow(decimals))
}

export const getBalanceAmount = (amount: BigNumber, decimals = 18) => {
  return new BigNumber(amount).dividedBy(BIG_TEN.pow(decimals))
}

/**
 * This function is not really necessary but is used throughout the site.
 */
export const getBalanceNumber = (balance: BigNumber, decimals = 18) => {
  const parsedBalance = getBalanceAmount(balance, decimals).toNumber()
  return Number.isNaN(parsedBalance) ? 0 : parsedBalance
}

export function delineate(bnStr: string, decimalDigitTake?: number) {
  const parts = bnStr.split('.')
  if (decimalDigitTake && parts[1]) {
    parts[1] = parts[1].substr(0, decimalDigitTake)
  }

  const formatDecimal = parts[1] && Number(parts[1]) && decimalDigitTake !== 0 ? `.${parts[1]}` : ''
  return parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + formatDecimal
}

export const getFullDisplayBalance = (balance: BigNumber, decimals = 0, displayDecimals?: number) => {
  return delineate(getBalanceAmount(balance, decimals).toString(10), displayDecimals)
}

export const formatNumber = (number: number, minPrecision = 2, maxPrecision = 2) => {
  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  }
  return number.toLocaleString('en-En', options)
}

export const formatLocalisedCompactNumber = (number: number): string => {
  return new Intl.NumberFormat('US-us', {
    notation: 'compact',
    compactDisplay: 'short',
    maximumSignificantDigits: 2,
  }).format(number)
}

export default formatLocalisedCompactNumber
