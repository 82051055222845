import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Grid from 'components/Box/Grid'
import Button from 'components/Button'
import Image from 'components/Image'
import NextImageLoaderCallback from 'components/Image/NextImageLoaderCallback'
import Modal, { ModalProps } from 'components/Modal'
import OpenEffect from 'components/OpenEffect'
import Text from 'components/Text'
import { howlMapper } from 'config/constants/audio'
import { PRIZE_ITEM_ROTATE_DEG } from 'config/constants/luckyspin'
import { RouteConfig } from 'config/constants/route'
import { SpinPrizeInfo } from 'config/types/luckyspin'
import { WalletType } from 'config/types/wallet'
import useAuthenticationModal from 'hooks/useAuthenticationModal'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import { useOpenLuckySpinCallback } from 'hooks/useLuckySpinModal'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import useModal from 'hooks/useModal'
import { useRequest } from 'hooks/useRequest'
import { useRouter } from 'hooks/useRouter'
import useToggle from 'hooks/useToggle'
import { RowCenter } from 'layout/Components/Row'
import { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import LuckySpinService from 'services/LuckySpinService'
import { useAppSelector } from 'state'
import { useAuth } from 'state/auth/hooks'
import { useUpdateUserLuckySpin, useUserLeftSpinTimes } from 'state/profile/hooks'
import styled from 'styled-components'
import { delayed } from 'utils'
import { HunnyToast } from 'utils/toastify'
import HUSDUnlock from 'views/HUSDUnlock'
import { AuthModalPageEnums } from 'views/Authentication/types'
import { LOGO_WHEEL_TITLE } from 'config/constants/variables'
import PrizeBottomSlide from './components/PrizeBottomSlide'
import PrizeDetailModal from './components/PrizeDetailModal'
import PrizeWinnerModal from './components/PrizeWinnerModal'
import SpinCountdown from './components/SpinCountdown'
import Wheel from './components/Wheel'
import WheelBackground from './components/WheelBackground'

const preloadImgs = [
  {
    src: '/images/luckyspin/prize-detail-bg.png',
    width: 800,
    height: 585,
  },
  {
    src: '/images/luckyspin/prize-detail-bg-mobile.png',
    width: 600,
    height: 279,
  },
  {
    src: '/images/luckyspin/spin-wheel.png',
    width: 400,
    height: 400,
  },
]

const LuckySpin: React.FC<ModalProps> = ({ onDismiss }) => {
  const wallet = useAppSelector((state) => state.auth.wallet)
  const update = useUpdateUserLuckySpin()
  const amount = useUserLeftSpinTimes()

  const openCallback = useOpenLuckySpinCallback()
  const [handlePresentAuthModal] = useAuthenticationModal()
  const [handlePresentHusdUnlock] = useModal(HUSDUnlock)
  const { hasSession, isSigned } = useAuth()
  const { isMobile } = useMatchBreakpoints()

  const [isSpinning, toggleIsSpinning, setIsSpinning] = useToggle(false)
  const [isAssetsLoaded, toggleAssetsLoaded] = useToggle(false)

  const [prizes, setPrizes] = useState<SpinPrizeInfo[]>([])
  const prizeItemOffset = prizes?.length ? Math.floor(180 / prizes.length) : 0

  const [selectedPrize, setSelectedPrize] = useState<SpinPrizeInfo>(null)
  const [spinFinishFinalDegree, setSpinFinishFinalDegree] = useState<number>(0)

  const { execute } = useRequest()
  const router = useRouter()

  const [onPresentDetailModal] = useModal(PrizeDetailModal)
  const [onPresentPrizeWinnerModal] = useModal(PrizeWinnerModal)
  const { t } = useTranslation()

  const useCanPlay = (amount > 0 && !isSpinning) || !hasSession

  const handlePlaySpin = useCallback(async () => {
    if (!hasSession) return handlePresentAuthModal({ page: AuthModalPageEnums.LOG_IN })
    if (isSpinning) return
    if (amount === 0) return HunnyToast.show(t('Sorry, you do not have any free spins available at this time.'))

    howlMapper.SpinClickSoundPlayer?.play()

    const response = await execute(LuckySpinService.play())
    if (response && response.data) {
      const prizeIndex = prizes.findIndex((prize) => prize.id === response.data.id)
      const finalDeg = PRIZE_ITEM_ROTATE_DEG * prizeIndex + prizeItemOffset

      update()
      setSelectedPrize(response.data)
      setSpinFinishFinalDegree(finalDeg)

      await delayed(500)
      toggleIsSpinning()
      howlMapper.SpinPlaySoundPlayer?.play()
    }
  }, [hasSession, isSpinning, amount, prizes])

  const handleCloseModal = (isReceivedLockedHusd) => {
    onDismiss()
    if (isReceivedLockedHusd) {
      handlePresentHusdUnlock()
    } else router.push(RouteConfig.Home)
  }

  useIsomorphicEffect(() => {
    if (wallet?.type === WalletType.SOL) return

    const fetch = async () => {
      if (isSigned) {
        const internalPrizeListResponse = await execute(LuckySpinService.getInternalPrizeList())
        if (internalPrizeListResponse?.data?.items) setPrizes(internalPrizeListResponse.data.items)

        update()
      } else {
        const response = await execute(LuckySpinService.getExternalPrizeList())
        if (response?.data?.items) setPrizes(response.data.items)
      }
    }

    fetch()
  }, [isSigned, wallet])

  useEffect(() => {
    howlMapper.requestAllSpinAudio()
    return () => howlMapper.clearAllSpinAudio()
  }, [])

  useEffect(() => {
    openCallback(null, () => {
      onDismiss()
    })
  }, [openCallback])

  return (
    <StyledModal onDismiss={onDismiss}>
      <NextImageLoaderCallback imgs={preloadImgs} onLoaded={() => toggleAssetsLoaded()} />

      <Box width={[585, 585, 585, 800]} height={[656, 656, 656, 585]}>
        <Box
          position="absolute"
          width="100%"
          height="100%"
          background={'url(/images/luckyspin/spin-bg.png)'}
          backgroundPosition="center center"
          backgroundSize="cover"
        />
        <WheelBackground />

        {isAssetsLoaded && (
          <StyledSpinContainer
            gridTemplateColumns={['1fr', '1fr', '1fr', '1fr', '1fr 1fr']}
            width="100%"
            height="100%"
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            $isSpinning={isSpinning}
            zIndex={10}
          >
            <Box position="relative" width="100%" height="100%">
              <Wheel
                prizes={prizes}
                onSpinStart={handlePlaySpin}
                onSpinFinished={(selectedPrize) => {
                  onPresentDetailModal({ selectedPrize, onModalClosed: handleCloseModal })
                  howlMapper.SpinWinSoundPlayer?.play()
                  setIsSpinning(false)
                }}
                selectedPrize={selectedPrize}
                spinFinishFinalDegree={spinFinishFinalDegree}
                isSpinning={isSpinning}
                disabled={amount === 0}
                top={['20px']}
              />
            </Box>

            <OpenEffect openType="zoom">
              <Flex
                flexDirection="column"
                width="100%"
                height="100%"
                alignItems="center"
                justifyContent="center"
                position="relative"
              >
                <Box position="relative" minWidth={[0, 0, 0, 0, 281]} mt={[14, 14, 14, 18, 0]}>
                  <Image src={LOGO_WHEEL_TITLE} width={331} height={108} mt={[3, 3, 3, 4, 0]} />
                </Box>
                <Box position="relative" top="-36px">
                  <Image
                    src="/images/luckyspin/title-wheel.png"
                    width={isMobile ? 146 : 179}
                    height={isMobile ? 66 : 67}
                    alt="Lucky Spin Wheel Title"
                  />
                </Box>

                <Box position="relative" top={[-12, -12, -14, -20, 0]}>
                  {amount > 0 ? (
                    <RowCenter mb={2}>
                      <Image src="/images/gift_box.png" width={24} height={25} alt="Lucky spin gift box" />
                      <Text fontSize="14px" lineHeight={1} textTransform="capitalize">
                        <Trans>Free Spin</Trans>
                      </Text>
                      <Text color="#06C270" fontSize="14px" bold ml={1} lineHeight={1}>
                        +{amount}
                      </Text>
                    </RowCenter>
                  ) : null}

                  {(amount > 0 || !hasSession) && (
                    <>
                      <StyledSpinButton
                        variant="gradient"
                        width={200}
                        height={52}
                        onClick={() => handlePlaySpin()}
                        disabled={!useCanPlay}
                        mb={[12, 12, 12, 12, 0]}
                      >
                        <Text fontSize="16px" bold>
                          {hasSession ? t('Spin') : t('Log in and Spin')}
                        </Text>
                      </StyledSpinButton>
                    </>
                  )}
                </Box>
                <SpinCountdown />
              </Flex>
            </OpenEffect>
          </StyledSpinContainer>
        )}
        <PrizeBottomSlide onDisplayPrizeWinnerModal={() => onPresentPrizeWinnerModal()} isSpinning={isSpinning} />
      </Box>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  max-width: 800px;
  max-height: 585px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: unset;
  border-radius: ${({ theme: { radii } }) => radii.default};
  background: none;
  position: relative;
  .modal-closebutton {
    right: 10px;
    z-index: 100;
  }

  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    .modal-closebutton {
      right: 25px;
    }
  }
`

const StyledSpinContainer = styled(Grid)<{ $isSpinning: boolean }>`
  will-change: transition;
  transition: backdrop-filter 300ms linear;
  backdrop-filter: ${({ $isSpinning }) => ($isSpinning ? 'blur(2px)' : '')};
`

const StyledSpinButton = styled(Button)`
  border-radius: 36px;
`

export default LuckySpin

