import { useAccount, useChainId } from 'wagmi'

export class ActiveWeb3React {
  chainId?: number
  account: string
}

const useWeb3React = (): ActiveWeb3React => {
  const { address } = useAccount()
  const chainId = useChainId()

  return { chainId, account: address }
}

export default useWeb3React
