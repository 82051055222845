import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import { BoxProps } from 'components/Box/types'
import Image from 'components/Image/Image'
import Text from 'components/Text/Text'
import { WelcomePackItemType } from 'config/types/bonus/welcomePackage'
import useDebounce from 'hooks/useDebounce'
import { ColumnCenter } from 'layout/Components/Column'
import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'
import theme from 'theme'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { getWelcomePackageFreeSpinAmount, getWelcomePackageStats } from 'views/Boarding/utils'

interface WelcomePackItemProps extends BoxProps {
  welcomePackage: WelcomePackItemType
  isActive: boolean
}

const WelcomePackItem: React.FC<WelcomePackItemProps> = ({ welcomePackage, isActive, ...props }) => {
  const freeSpinAmount = getWelcomePackageFreeSpinAmount(welcomePackage)
  const { maxReceiveBonus, minDepositCondition } = useMemo(
    () => getWelcomePackageStats(welcomePackage),
    [welcomePackage],
  )

  const isActiveDebouced = useDebounce(isActive, 100)
  return (
    <StyledContainer
      {...props}
      $isActive={isActive}
      width={['calc(100vw / 2.1 - 24px)', 'calc(100vw / 2.1 - 24px)', isActive ? 234 : 141]}
      height={[180, 180, isActive ? 202 : 129]}
      borderTopLeftRadius={[12, 12, isActive ? `${theme.radii.extraLarge} !important` : 'inherit']}
      borderTopRightRadius={[12, 12, isActive ? `${theme.radii.extraLarge} !important` : 'inherit']}
    >
      <StyledImgContainer $isActive={isActive}>
        <Image src={welcomePackage.thumbnail} width={120} height={120} alt={welcomePackage.code} />
      </StyledImgContainer>
      <Box>
        <ColumnCenter>
          <StyledBonusText
            fontSize={['14px', '16px', isActive ? '24px' : '14px']}
            fontWeight="700"
            overflow="hidden"
            maxHeight="24px"
            textAlign="center"
            width={['calc(100vw / 2.1 - 24px)', 'calc(100vw / 2.1 - 24px)', isActive ? 234 : 141]}
          >
            {freeSpinAmount > 0 ? `${freeSpinAmount} FS + ` : ''} $
            {maxReceiveBonus.gt(0) ? `${getFullDisplayBalance(maxReceiveBonus)}` : ''}
          </StyledBonusText>

          <StyledMinDepositContainer $isActive={isActive && isActiveDebouced} mt="8px">
            <Text fontSize={['12px', '12px', '14px']} fontWeight="500">
              <Trans>Deposit from</Trans> ${getFullDisplayBalance(minDepositCondition)}
            </Text>
          </StyledMinDepositContainer>
        </ColumnCenter>
      </Box>
    </StyledContainer>
  )
}

const StyledMinDepositContainer = styled(Box)<{ $isActive: boolean }>`
  opacity: ${({ $isActive }) => ($isActive ? '1' : '0')};
  margin-bottom: ${({ $isActive }) => ($isActive ? '0px' : '-14px')};
  transition: ${({ theme }) => theme.transitions.fast};
`

const StyledImgContainer = styled(Box)<{ $isActive: boolean }>`
  width: ${({ $isActive }) => ($isActive ? '120px' : '80px')};
  height: ${({ $isActive }) => ($isActive ? '120px' : '80px')};
  transition: ${({ theme }) => theme.transitions.fast};
`

const StyledBonusText = styled(Text)`
  transition: ${({ theme }) => theme.transitions.fast};
`

const StyledContainer = styled(Flex)<{ $isActive: boolean }>`
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 12px;

  background: ${({ $isActive }) =>
    $isActive
      ? 'linear-gradient(180deg, #FFFFFF -27.49%, rgba(255, 255, 255, 0.570505) 6.8%, rgba(255, 255, 255, 0) 100%)'
      : 'linear-gradient(180deg, #FFFFFF -317.46%, rgba(255, 255, 255, 0) 94.28%)'};
  ${({ $isActive }) =>
    $isActive &&
    css`
      transition: ${({ theme }) => theme.transitions.fast};
    `}
`

export default WelcomePackItem
