import { getAnalytics, setUserId, setUserProperties } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { useUserInfo } from 'state/auth/hooks'

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
}

type UserPropertiesKey = 'language' | 'coin' | 'type' | 'wallet' | 'vip' | 'referral_by'
type EventKey = 'search' | 'select_balance' | 'swap_husd' | 'withdraw' | 'deposit' | 'login'
// Initialize Firebase
const app = typeof window !== 'undefined' ? initializeApp(firebaseConfig) : null
const analytics = app ? getAnalytics(app) : null

export const useAnalytics = () => {
  const { uid } = useUserInfo()

  const recordEvent = (event: EventKey, value: any, overrideUid: number = null) => {
    let leafValue = value || {}
    let parsedValue = `[${event}]`

    while (typeof leafValue === 'object') {
      const key = Object.keys(leafValue)[0]
      leafValue = leafValue[key]
      if (key) parsedValue = `${parsedValue}[${key}]`
    }

    if (leafValue) parsedValue = `${parsedValue}[${leafValue}]`

    addEventString(parsedValue, overrideUid)
  }

  const addEventString = (event: string, overrideUid: number = null) => {
    window?.dataLayer?.push({ event, userId: overrideUid || uid })
    window?.WonderPush?.push(['trackEvent', event])
  }

  const updateUserType = (key: UserPropertiesKey, value: string) => {
    setUserProperties(analytics, { [key]: value })
  }

  const updateUserId = (uid: string) => {
    setUserId(analytics, uid)

    window?.gtag('config', process.env.NEXT_PUBLIC_GA, {
      user_id: Number(uid) || null,
    })

    window?.dataLayer?.push({ userId: Number(uid) || null })
  }

  return { recordEvent, updateUserType, updateUserId, addEventString }
}

// Initialize Analytics and get a reference to the service
