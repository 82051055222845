import styled from 'styled-components'
import React, { useCallback, useRef } from 'react'
import Flex from 'components/Box/Flex'
import { FlexProps } from 'components/Box/types'
import Box from 'components/Box/Box'

interface InputAdornmentProps extends FlexProps {
  endAdornment?: React.ReactElement
}

const InputAdornment: React.FC<InputAdornmentProps> = ({ endAdornment, children, ...props }) => {
  const containerRef = useRef(null)

  const focusInput = useCallback(() => {
    const input: HTMLElement = containerRef.current.querySelector('input')
    if (input) {
      input.focus()
    }
  }, [])

  return (
    <StyledContainer ref={containerRef} width="100%" alignItems="center" onClick={focusInput} {...props}>
      {children}
      {endAdornment && (
        <StyledRightAdornmentContainer ml="8px" alignItems="center">
          {endAdornment}
        </StyledRightAdornmentContainer>
      )}
    </StyledContainer>
  )
}

const StyledRightAdornmentContainer = styled(Flex)`
  gap: 6px;
`

export const StyledAdornment = styled(Box)`
  display: flex;
`

const StyledContainer = styled(Flex)`
  width: 100%;
  position: relative;
  cursor: text;

  .hide-when-focus {
    display: block;
  }

  &:focus-within {
    .hide-when-focus {
      display: none;
    }
  }
`

export default InputAdornment
