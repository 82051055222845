import { ChainIdEnum } from 'config/constants/network'
import { DepositedTokenInfo, GameTag, ProviderInfo } from 'config/types'
import { ProfileTierInfo } from 'config/types/profile'
import { PinnedPromotion } from 'config/types/promotion'
import { parseTierInfos } from 'services/mapper/utils'
import { BaseResponse, MetaDataResponse, Paging, TokenUsdPriceResponse } from 'services/types'
import { formatApiNetworkField } from 'utils'
import { parseScreenTags } from './Game'
import { parseDataWelcomePack } from './Voucher'

export const MetaDataMapper = (rawResponse: string): BaseResponse<MetaDataResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  const data = response?.data

  if (!response || response?.code !== 'success') {
    return {
      code: response?.code || 'error',
      data: null,
    }
  }

  const tokenDepositAddressMap = data?.wallets?.reduce((result, cur) => {
    for (const currency of cur.currencies) {
      result[`${currency}${formatApiNetworkField(cur.network)}`] = cur.address
    }
    return result
  }, {})

  const currencyNetworkMappings = data?.['currency_network_mappings'] || []

  const tokenInfoes: DepositedTokenInfo[] = currencyNetworkMappings
    .map(
      (item: any): DepositedTokenInfo => ({
        network: ChainIdEnum[formatApiNetworkField(item.network)],
        token: item.currency,
        order: item.priority,
        contractAddress: tokenDepositAddressMap[`${item.currency}${formatApiNetworkField(item.network)}`],
      }),
    )
    .sort((pre: DepositedTokenInfo, next: DepositedTokenInfo) => pre.order - next.order)

  const tags: GameTag[] = data?.tags
    ?.sort((pre: any, next: any) => pre.priority - next.priority)
    .map(
      (tag: any): GameTag => ({
        content: tag.name,
        img: tag.icon_url,
        value: tag.code,
      }),
    )

  const networkIds: string[] = data?.['networks']
    ?.filter((network) => network.code !== 'HPN')
    .map((network: any) => formatApiNetworkField(network.code))

  const profileTierInfos: ProfileTierInfo[] = data?.tiers
    ?.map((tier) => parseTierInfos(tier))
    .sort((pre: ProfileTierInfo, next: ProfileTierInfo) => pre.level - next.level)

  const defaultScreenTags = parseScreenTags(data?.menu_allgame_tags)
  const welcomePackage = parseDataWelcomePack(data?.welcome_package)

  const pinPromotions = data?.pin_promo_contents?.map(
    (promo: any): PinnedPromotion => ({
      slug: promo.slug,
      menuImageUrl: promo.thumb_navi_url,
    }),
  )
  const providers = data?.providers?.map((provider) => parseProvider(provider))

  return {
    code: response.code,
    data: {
      serverTime: (data?.['server_time'] || 0) * 1000,
      providers,
      defaultScreenTags,
      tags,
      networkIds,
      tokenInfoes,
      tiers: profileTierInfos,

      pinPromotions,
      welcomePackage,
    },
  }
}

export const parseProvider = (item) => ({
  name: item.name,
  code: item.code,
  icon: item.icon_url || item.logo_url,
  logo: item.logo_url,
  banner: item.banner_url,
  slug: item.slug,
})

export const ProviderListMapper = (rawResponse: string): BaseResponse<Paging<ProviderInfo[]>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    data: response.data && {
      items: response.data.items.map((item) => parseProvider(item)),
    },
  }
}

export const ProviderMapper = (rawResponse: string): BaseResponse<ProviderInfo> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }
  const provider = response.data?.provider

  return {
    code: response.code,
    data: provider && {
      name: provider.name,
      code: provider.code,
      icon: provider.icon_url || provider.logo_url,
      logo: provider.logo_url,
      desktopBanner: provider.banner_desktop_url,
      mobileBanner: provider.banner_mobile_url,
      slug: provider.slug,
    },
  }
}

export const TokenInUsdPriceMapper = (rawResponse: string): BaseResponse<TokenUsdPriceResponse> => {
  const response = JSON.parse(rawResponse)

  return {
    code: response.code,
    data:
      response.data &&
      response.data.items.reduce((result, value) => {
        result[value.code] = value['price_usd']
        return result
      }, {}),
  }
}
