import BigNumber from 'bignumber.js'
import GameListModal from 'components/GameListModal/GameListModal'
import {
  CommonBonus,
  DepositCommonBonus,
  FreeSpinCommonBonus,
  HUSDUnLockBoosterCommonBonus,
  HusdLockRewardCommonBonus,
  LevelUpCommonBonus,
  LuckSpinRewardCommonBonus,
  NoDepositCommonBonus,
  NoDepositFreeSpinCommonBonus,
} from 'config/types/bonus'
import {
  BonusStatusEnums,
  BonusUserTypeEnums,
  DepositBonus,
  FreeHUSDLockBonus,
  FreeLuckyspinBonus,
  FreespinBonus,
  HUSDUnLockBoosterBonus,
  LevelUpBonus,
  NoDepositBonus,
  NoDepositFreespinBonus,
  UserBonus,
  WageringBonusStatusEnums,
  WageringConditionBonus,
} from 'config/types/bonus/userBonus'
import useModal from 'hooks/useModal'
import { useRequest } from 'hooks/useRequest'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BonusService from 'services/BonusService'
import GameService from 'services/GameService'
import { BaseResponse } from 'services/types'
import { useAppSelector } from 'state'
import { useActiveHUSDBoosterBonus, useActiveLevelUpBonus } from 'state/bonus/hooks'
import { useUserWager } from 'state/profile/hooks'
import { buildPluralizeText, delayed } from 'utils'
import { forkjoinRequest } from 'utils/requestHelper'

export enum ValidatedBonusStatusEnums {
  Claimed,
  Available,
  Expired,
  Used,
  HasOtherActivated,
  UserLevel,
  UserType,
  Cancelled,
}

export const useFreespinGamesModal = (bonus) => {
  const [presentModalChooseGame, onDimiss] = useModal(GameListModal)
  const { t } = useTranslation()
  const request = useCallback(
    (offset: number, limit: number) => {
      return GameService.getGames({ gameIds: bonus.spinGameIds }, offset, limit)
    },
    [bonus],
  )

  const handlePresentModal = () => {
    presentModalChooseGame({
      request,
      titleHeader: t('Choose Game'),
      contentDescription: t('Please choose a desirable game to play all your Free Spins'),
    })
  }

  return [handlePresentModal, onDimiss]
}

export const useValidateBonus = (bonus: UserBonus) => {
  const activeLevelUpBonus = useActiveLevelUpBonus()
  const activeHUSDBoosterBonus = useActiveHUSDBoosterBonus()
  const { score } = useUserWager()
  const userTier = useAppSelector((state) => state.profile.tier)

  const bigScore = useMemo(() => new BigNumber(score), [score])

  return useMemo(() => {
    if (bonus.status === BonusStatusEnums.Used) {
      if (bonus instanceof WageringConditionBonus && bonus.wageringBonusStatus === WageringBonusStatusEnums.Claimed) {
        return ValidatedBonusStatusEnums.Claimed
      }

      if (bonus instanceof WageringConditionBonus && bonus.wageringBonusStatus === WageringBonusStatusEnums.Cancelled) {
        return ValidatedBonusStatusEnums.Cancelled
      }

      return ValidatedBonusStatusEnums.Used
    }

    if (bonus.condition.isExpired) return ValidatedBonusStatusEnums.Expired

    if (!bonus.condition.validateUserLevelEligibility(userTier?.level)) return ValidatedBonusStatusEnums.UserLevel

    if (bigScore.gt(0) && bonus.condition.userType === BonusUserTypeEnums.NewUser)
      return ValidatedBonusStatusEnums.UserType

    if (bonus instanceof LevelUpBonus && activeLevelUpBonus) return ValidatedBonusStatusEnums.HasOtherActivated

    if (bonus instanceof HUSDUnLockBoosterBonus && activeHUSDBoosterBonus)
      return ValidatedBonusStatusEnums.HasOtherActivated

    return ValidatedBonusStatusEnums.Available
  }, [
    bonus.status,
    bonus,
    activeLevelUpBonus,
    activeHUSDBoosterBonus,
    userTier,
    (bonus as any).wageringBonusStatus,
    bigScore.gt(0),
  ])
}

export const buildBonusName = (t: (content: string, config?: any) => string, bonus: any): [string, string] => {
  if (!bonus) return ['', '']

  if (bonus instanceof LevelUpBonus || bonus instanceof LevelUpCommonBonus) {
    return [
      t(
        buildPluralizeText('Increase {{extraLevel}} VIP {{level}} for {{time}} {{day}}', [
          { number: bonus.extraLevels, key: 'level', word: ['level', 'levels'] },
          { number: bonus.duration, key: 'day', word: ['day', 'days'] },
        ]),
        { extraLevel: bonus.extraLevels, time: bonus.duration },
      ),
      t('VIP upgrade'),
    ]
  }

  if (bonus instanceof HUSDUnLockBoosterBonus || bonus instanceof HUSDUnLockBoosterCommonBonus) {
    return [
      t(
        buildPluralizeText('HUSD unlock bonus +{{extraUnlockPercent}}% for {time} {{day}}', [
          { number: bonus.duration, key: 'day', word: ['day', 'days'] },
        ]),
        {
          extraUnlockPercent: bonus.extraUnlockPercent,
          time: bonus.duration,
        },
      ),
      t('HUSD unlock booster'),
    ]
  }

  if (bonus instanceof FreeLuckyspinBonus || bonus instanceof LuckSpinRewardCommonBonus) {
    return [
      t(
        buildPluralizeText('Receive {{amount}} lucky {{spin}}', [
          { number: bonus.amount, key: 'spin', word: ['spin', 'spins'] },
        ]),
        { amount: bonus.amount },
      ),
      t('Free lucky spin'),
    ]
  }

  if (bonus instanceof FreeHUSDLockBonus || bonus instanceof HusdLockRewardCommonBonus) {
    return [t('Receive {{amount}} locked HUSD', { amount: bonus.amount }), t('HUSD bonus')]
  }

  if (bonus instanceof DepositBonus || bonus instanceof DepositCommonBonus) {
    return [t('{{percent}} on the deposit', { percent: `${bonus.percent}%` }), t('Deposit bonus')]
  }

  if (bonus instanceof NoDepositFreespinBonus || bonus instanceof NoDepositFreeSpinCommonBonus) {
    return [t('{{amount}} free spins', { amount: bonus.freeSpinAmount }), t('Freespin bonus')]
  }

  if (bonus instanceof FreespinBonus || bonus instanceof FreeSpinCommonBonus) {
    return [t('{{amount}} free spins', { amount: bonus.freeSpinAmount }), t('Freespin bonus')]
  }

  if (bonus instanceof FreespinBonus || bonus instanceof FreeSpinCommonBonus) {
    return [t('{{amount}} free spins', { amount: bonus.freeSpinAmount }), t('Freespin bonus')]
  }

  if (bonus instanceof NoDepositBonus || bonus instanceof NoDepositCommonBonus) {
    return [
      t('Receive {{amount}} {{currency}}', {
        amount: bonus.bonusAmount?.amount.toString(),
        currency: bonus.bonusAmount?.token.code,
      }),
      t('{{currency}} bonus', {
        currency: bonus.bonusAmount?.token.code,
      }),
    ]
  }

  return ['', '']
}
export const useDisplayBonusName = (bonus: UserBonus | CommonBonus): [string, string] => {
  const { t } = useTranslation()
  return useMemo(() => {
    return buildBonusName(t, bonus)
  }, [bonus, t])
}

export const useRedeemBonus = () => {
  const redeem = async (code: string, delay = 700): Promise<BaseResponse<boolean>> => {
    const [response] = await forkjoinRequest([BonusService.redeem(code.trim().toUpperCase()), delayed(delay)])

    return response
  }

  return redeem
}

export const useBonusLogic = (bonus: UserBonus) => {
  const [bonusInstance, setBonusInstance] = useState({ instance: bonus })
  const { execute } = useRequest()

  useEffect(() => {
    setBonusInstance({ instance: bonus })
  }, [bonus])

  const handleBonusUsed = useCallback(async (useSuccessfully: boolean) => {
    if (useSuccessfully) return
    const newBonus = await execute(BonusService.getUserBonusDetails(bonus.id))
    if (newBonus.data) {
      setBonusInstance({ instance: newBonus.data })
    }
  }, [])

  const updateBonusInstance = useCallback((bonus: UserBonus) => {
    setBonusInstance({ instance: bonus })
  }, [])

  return { handleBonusUsed, updateBonusInstance, bonusInstance: bonusInstance.instance }
}
