import Box from 'components/Box/Box'
import { FlexProps } from 'components/Box/types'
import Image from 'components/Image'
import Link from 'components/Link/Link'
import Text from 'components/Text'
import { RouteConfig } from 'config/constants/route'
import { Game } from 'config/types/game'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import { useCloseAllModal } from 'hooks/useModal'
import { RowCenter } from 'layout/Components/Row'
import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useFeaturedGame } from 'state/profile/hooks'
import styled, { keyframes } from 'styled-components'
import { formatRoute } from 'utils/stringHelper'
import GameItemActions from './GameItemActions'

interface GameItemProps {
  game: Game
  large?: boolean
}

const GameItem: React.FC<GameItemProps & FlexProps> = ({ game, large, ...props }) => {
  const [isHovered, setIsHovered] = useState(false)
  const closeAllModal = useCloseAllModal()
  const { isMobile } = useMatchBreakpoints()
  const {
    featuredGameInfo: { isWageringBonusGame },
  } = useFeaturedGame(game.code)
  const [favoriteCount, setFavoriteCount] = useState(game.favoriteCount)

  return (
    <Link
      aria-label={game.name}
      id="game-item"
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      href={formatRoute(RouteConfig.GameDetail, { slug: game.slug })}
      disabledDefaultStyle
      onClick={() => {
        if (isMobile) closeAllModal()
      }}
    >
      <StyledWrapper {...props}>
        <StyledImgWrapper position="relative">
          {isWageringBonusGame ? (
            <StyledBonusBadge>
              <Text fontSize="max(12px,0.5vw)" fontWeight={500} lineHeight="134%">
                <Trans>Bonus wager</Trans>
              </Text>
            </StyledBonusBadge>
          ) : null}
          <Image width={large ? 800 : 350} height={large ? 800 : 350} src={game.thumbnail} alt={game.name} blurLoader />
          <StyledHoverWrapper>
            {isHovered ? (
              <GameItemActions
                game={game}
                defaultFavoriteCount={favoriteCount}
                onFavoriteCountUpdated={(favoriteCount) => setFavoriteCount(favoriteCount)}
              />
            ) : null}
          </StyledHoverWrapper>
        </StyledImgWrapper>
      </StyledWrapper>
    </Link>
  )
}

const StyledBonusBadge = styled(Text)`
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  padding: 4px 8px;

  background: linear-gradient(223deg, #7f00ff 0%, rgba(225, 0, 255, 0.8) 100%);
  top: 0;
  right: 0;

  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  backdrop-filter: blur(6px);

  z-index: 1;
`

const StyledHoverWrapper = styled(RowCenter)`
  z-index: 1;
  top: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: linear-gradient(180deg, rgba(22, 28, 36, 0) -22.78%, #191d25e0 55%, #17181b 86.75%);
`

const grow = keyframes`
  0% {
    bottom: -10px;
  }
  100% {
    bottom: 0;
  };
`

const StyledWrapInfoGame = styled(Box)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
`

const StyledWrapper = styled(Box)`
  width: 100%;
  padding: 12px;
  cursor: pointer;
  position: relative;

  scroll-snap-align: start;
  scroll-snap-stop: normal;

  img {
    transition: ${({ theme }) => theme.transitions.medium};
  }

  ${({ theme }) => theme.mediaQueries.md} {
    &:hover {
      ${StyledHoverWrapper} {
        opacity: 1;
        ${StyledWrapInfoGame} {
          animation-name: ${grow};
          animation-duration: 300ms;
        }
      }
    }
  }
`

const StyledImgWrapper = styled(Box)`
  overflow: hidden;
  border-radius: ${({ theme }) => theme.radii.small};
`

export default GameItem
