import BigNumber from 'bignumber.js'
import Box from 'components/Box/Box'
import { BoxProps } from 'components/Box/types'
import { StyledControlContainer } from 'components/FormControl/styled'
import { InputLabel } from 'components/Input/styled'
import NetworkSelect from 'components/NetworkSelect'
import TokenSelect from 'components/TokenSelect'
import { ChainIdEnum, NETWORK_MAP } from 'config/constants/network'
import { HUSD_TOKEN } from 'config/constants/tokens'
import { Network, Token } from 'config/types'
import { WalletType } from 'config/types/wallet'
import { useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { useAppSelector } from 'state'
import { useListTokens } from 'state/app/hooks'
import { getNetworkInfo } from 'utils/network'

interface PaymentTokenSelectProps extends BoxProps {
  token: Token
  disabled?: boolean
  onTokenSelect: (token: Token) => void
  networks: Network[]
  fetchTokenBalanceFn?: (token: Token) => Promise<BigNumber>
}

const PaymentTokenSelect: React.FC<PaymentTokenSelectProps> = ({
  onTokenSelect,
  token,
  disabled,
  networks,
  fetchTokenBalanceFn,
  ...props
}) => {
  const wallet = useAppSelector((state) => state.auth.wallet)
  const tokenInfoes = useAppSelector((state) => state.app.tokenInfoes)

  const fallbackNetwork = useMemo(() => {
    return tokenInfoes.find((token) =>
      wallet?.type === WalletType.SOL
        ? token.network === ChainIdEnum.SOL || token.network === ChainIdEnum.SOL_TESTNET
        : token,
    )?.network
  }, [])

  const initialNetwork = token.network === ChainIdEnum.HPN ? fallbackNetwork : token.network
  const [chainId, setChainId] = useState<ChainIdEnum>(initialNetwork)
  const network = useMemo(() => NETWORK_MAP[chainId], [chainId])
  const listToken = useListTokens(chainId)
  const parsedListToken = useMemo(() => [...listToken, HUSD_TOKEN], [listToken])

  const handleSelectNetwork = (network: Network) => {
    setChainId(network.chainId)

    const { native } = getNetworkInfo(network.chainId)
    onTokenSelect(native)
  }

  return (
    <Box {...props}>
      <StyledControlContainer state={null} mt="10px">
        <InputLabel>
          <Trans>Select Network</Trans>
        </InputLabel>
        <NetworkSelect
          disabled={disabled}
          options={networks}
          value={network}
          onNetworkChange={handleSelectNetwork}
          dropdownContentProps={{
            maxHeight: '200px !important',
          }}
        />
      </StyledControlContainer>

      <StyledControlContainer state={null} mt="16px">
        <InputLabel>
          <Trans>Select Coin</Trans>
        </InputLabel>
        <TokenSelect
          disabled={disabled}
          value={token}
          onTokenChange={(token) => onTokenSelect(token)}
          fetchTokenBalanceFn={fetchTokenBalanceFn}
          options={parsedListToken}
          dropdownContentProps={{
            maxHeight: '200px !important',
          }}
        />
      </StyledControlContainer>
    </Box>
  )
}

export default PaymentTokenSelect
