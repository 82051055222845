import BigNumber from 'bignumber.js'
import { UINT256_MAX } from 'config/constants/number'
import { Token } from 'config/types'
import useWeb3React from 'packages/wagmi/hooks/useWeb3React'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppSelector } from 'state'
import { getTokenAllowance } from 'utils/infura'
import { isSolToken } from 'utils/token'

function useDepositTokenAllowance(token: Token) {
  const { account } = useWeb3React()
  const [fetching, setFetching] = useState(false)
  const [allowance, setAllowance] = useState<BigNumber>(UINT256_MAX)

  const tokenInfoes = useAppSelector((state) => state.app.tokenInfoes)

  const tokenDepositInfo = useMemo(() => {
    if (!token) return null

    const spender = tokenInfoes.find(
      (tokenInfo) => tokenInfo.network === token.network && tokenInfo.token === token.code,
    )?.contractAddress

    if (!spender) return null

    return {
      spender,
      token,
    }
  }, [token, tokenInfoes])

  useEffect(() => {
    if (!tokenDepositInfo) return
    refresh()
  }, [tokenDepositInfo])

  const refresh = useCallback(async () => {
    if (tokenDepositInfo.token.isNative) {
      setAllowance(UINT256_MAX)
      return
    }

    if (isSolToken(tokenDepositInfo.token)) {
      setAllowance(UINT256_MAX)
      return
    }

    setFetching(true)
    const allowance = await getTokenAllowance(tokenDepositInfo.token, account, tokenDepositInfo.spender)
    setAllowance(allowance)
    setFetching(true)
  }, [tokenDepositInfo])

  return {
    allowance,
    fetching,
    refresh,
  }
}

export default useDepositTokenAllowance
