import { ChainIdEnum, NETWORK_MAP } from 'config/constants/network'
import Tokens from 'config/constants/tokens'
import { Network, Token } from 'config/types'

export const getNetworkInfo = (chainId: ChainIdEnum): { network: Network; native: Token } => {
  const listTokenByChain = Tokens[chainId]
  const networkInfo = NETWORK_MAP[chainId]

  const nativeToken: Token = Object.values(listTokenByChain)?.filter((token) => token.isNative)?.[0] || null

  return {
    network: networkInfo,
    native: nativeToken,
  }
}

export const getBlockExplorerUrls = ({ chainId, txnHash }: { chainId: ChainIdEnum; txnHash: string }) => {
  const networkInfo = NETWORK_MAP[chainId]
  return networkInfo ? `${networkInfo.blockExplorerUrls}/tx/${txnHash}` : ''
}

export const isSolChain = (chainId: ChainIdEnum) =>
  [ChainIdEnum.SOL, ChainIdEnum.SOL_TESTNET].includes(chainId) || false
