import Flex from 'components/Box/Flex'
import { BoxProps } from 'components/Box/types'
import { InputContainer } from 'components/Input/styled'
import Select from 'components/Select'
import { Network } from 'config/types'
import styled from 'styled-components'
import NetworkSelectItem from './NetworkSelectItem'

interface NetworkSelectProps {
  options: Network[]
  onNetworkChange: (network: Network) => void
  value: Network
  dropdownContentProps?: BoxProps
  disabled?: boolean
}

const NetworkSelect: React.FC<NetworkSelectProps> = ({
  options,
  onNetworkChange,
  value,
  dropdownContentProps,
  disabled,
}) => {
  return (
    <StyledSelectContainer width="100%" $disabled={disabled}>
      <Select
        disabled={disabled}
        options={options}
        onSelect={onNetworkChange}
        OptionItemComponent={NetworkSelectItem}
        dropdownContentProps={dropdownContentProps}
        id="network-selected"
      >
        <StyledActiveNetwork>
          <NetworkSelectItem item={value} />
        </StyledActiveNetwork>
      </Select>
    </StyledSelectContainer>
  )
}

const StyledSelectContainer = styled(InputContainer)`
  position: static;
  box-sizing: border-box;
  overflow: initial;

  width: 100%;

  border-radius: ${({ theme }) => theme.radii.small};
  border: 1px solid ${({ theme }) => theme.colors.stroke};
  background-color: 'transparent';

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.strokeAlt};
  }
`

const StyledActiveNetwork = styled(Flex)`
  padding: ${({ theme: { spacing } }) => `${spacing[1]}px`};

  align-items: center;
  justify-content: space-between;

  width: 100%;

  padding: 12px;
`

export default NetworkSelect
