import Box from 'components/Box/Box'
import Modal, { ModalBody } from 'components/Modal'
import { ModalProps } from 'components/Modal/Modal'
import Tab from 'components/Tab'
import Tabs from 'components/Tabs'
import Text from 'components/Text'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Icons } from 'svgs'
import theme from 'theme'
import { colors } from 'theme/colors'
import Flex from 'components/Box/Flex'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import { useRouter } from 'next/router'
import { RouteConfig } from 'config/constants/route'
import Deposit from './components/Deposit'
import Swap from './components/Swap'
import TabLabel from './components/TabLabel'
import Withdraw from './components/Withdraw'

export enum PaymentPageEnums {
  Deposit = 'deposit',
  Withdraw = 'withdraw',
  Swap = 'swap',
}

interface PaymentProps {
  defaultPage?: PaymentPageEnums
}

const Payment: React.FC<ModalProps<PaymentProps>> = ({ data: { defaultPage }, onDismiss }) => {
  const { isMobile } = useMatchBreakpoints()
  const [paymentPage, setPaymentPage] = useState<PaymentPageEnums>(defaultPage || PaymentPageEnums.Deposit)
  const { t } = useTranslation()

  const router = useRouter()

  return (
    <StyledModal id="payment" onDismiss={onDismiss}>
      <ModalBody height="100%" overflowY="auto">
        <Flex height="100%" flexDirection={['column', 'column', 'row']} pt={['40px', '', '0px']} overflow="hidden">
          {isMobile ? (
            <Tabs
              width="100%"
              value={paymentPage}
              onTagChanged={(value) => {
                setPaymentPage(value)
              }}
              backgroundBlurColor={colors.background}
              indicator={<StyledIndicator />}
            >
              <StyledTab value={PaymentPageEnums.Deposit} $active={paymentPage === PaymentPageEnums.Deposit}>
                <TabLabel Icon={<Icons.WalletIcon fill={colors.text} width="24px" />} label={t('Deposit')} />
              </StyledTab>
              <StyledTab value={PaymentPageEnums.Withdraw} $active={paymentPage === PaymentPageEnums.Withdraw}>
                <TabLabel Icon={<Icons.WithdrawIcon fill={colors.text} width="24px" />} label={t('Withdraw')} />
              </StyledTab>
              <StyledTab value={PaymentPageEnums.Swap} $active={paymentPage === PaymentPageEnums.Swap}>
                <TabLabel Icon={<Icons.SwapIcon fill={colors.text} width="24px" />} label={t('Swap')} />
              </StyledTab>
            </Tabs>
          ) : (
            <Box display={['none', '', 'block']} minWidth="184px" background="#192029" p="0px 12px" pt="40px">
              <StyledVerticalTab
                $active={paymentPage === PaymentPageEnums.Deposit}
                onClick={() => {
                  setPaymentPage(PaymentPageEnums.Deposit)
                }}
              >
                <Flex alignItems="center">
                  <Icons.WalletIcon width="24px" fill={colors.text} />
                  <Text bold small ml="8px">
                    <Trans>Deposit</Trans>
                  </Text>
                </Flex>
              </StyledVerticalTab>

              <StyledVerticalTab
                $active={paymentPage === PaymentPageEnums.Withdraw}
                onClick={() => {
                  setPaymentPage(PaymentPageEnums.Withdraw)
                }}
              >
                <Flex alignItems="center">
                  <Icons.WithdrawIcon width="24px" fill={colors.text} />
                  <Text bold small ml="8px">
                    <Trans>Withdraw</Trans>
                  </Text>
                </Flex>
              </StyledVerticalTab>

              <StyledVerticalTab
                $active={paymentPage === PaymentPageEnums.Swap}
                onClick={() => {
                  setPaymentPage(PaymentPageEnums.Swap)
                }}
              >
                <Flex alignItems="center">
                  <Icons.SwapIcon width="24px" fill={colors.text} />
                  <Text bold small ml="8px">
                    <Trans>Swap</Trans>
                  </Text>
                </Flex>
              </StyledVerticalTab>
            </Box>
          )}

          <StyledContent
            p={['20px', '', '40px 24px']}
            pt={'0px !important'}
            overflowY="auto"
            mt={['20px !important', '', '40px !important']}
          >
            <Box>
              <Text display={['none', '', 'block']} fontWeight="600" mb="20px">
                {paymentPage === PaymentPageEnums.Deposit && <Trans>Deposit</Trans>}
                {paymentPage === PaymentPageEnums.Withdraw && <Trans>Withdraw</Trans>}
                {paymentPage === PaymentPageEnums.Swap && <Trans>Swap</Trans>}
              </Text>

              {paymentPage === PaymentPageEnums.Deposit && <Deposit />}
              {paymentPage === PaymentPageEnums.Withdraw && <Withdraw onDismiss={onDismiss} />}
              {paymentPage === PaymentPageEnums.Swap && <Swap />}
            </Box>
            <Flex
              mb="12px"
              justifyContent="flex-end"
              alignItems="center"
              onClick={() => {
                router.push({
                  pathname: RouteConfig.TransactionHistory,
                  query: {
                    page: paymentPage,
                  },
                })
                onDismiss()
              }}
            >
              <StyledViewHistoryText>
                <Trans>View History</Trans>
              </StyledViewHistoryText>
              <Box position="relative" top={1.6}>
                <Icons.ChevronRightIcon width={20} height={12} fill={theme.colors.text} />
              </Box>
            </Flex>
          </StyledContent>
        </Flex>
      </ModalBody>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  padding: 0;

  box-sizing: border-box;

  background: ${({ theme: { colors } }) => colors.modalBackground};
  min-height: 85vh;

  overflow-y: hidden;
  border-top-left-radius: ${({ theme }) => theme.radii.large};
  border-top-right-radius: ${({ theme }) => theme.radii.large};

  height: max(calc(var(--screen-height) - 20vh), min(820px, calc(var(--screen-height) - 40px)));

  & .modal-closebutton {
    right: 12px;
    top: 12px;
  }

  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    min-width: 600px;
    max-width: 600px;
    border-radius: ${({ theme }) => theme.radii.large};
    height: max(calc(var(--screen-height) - 20vh), min(760px, calc(var(--screen-height) - 40px)));
  }
`

const StyledIndicator = styled.div`
  border-radius: ${({ theme }) => theme.radii.small};
  background: rgba(255, 255, 255, 0.04);
  height: 100%;
  width: 100%;
`

const StyledVerticalTab = styled(Box)<{ $active: boolean }>`
  padding: 12px;
  margin-bottom: 8px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;

  svg {
    fill: ${({ theme }) => theme.colors.textSubtle};
    transition: 0.2s;
  }

  div {
    color: ${({ theme }) => theme.colors.textSubtle};
    transition: 0.2s;
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.text};
    }

    div {
      color: ${({ theme }) => theme.colors.text};
    }
  }

  ${({ $active }) =>
    $active
      ? css`
          background: linear-gradient(90deg, rgba(255, 255, 255, 0.08) 1.89%, rgba(255, 255, 255, 0) 108.73%);

          svg {
            fill: ${({ theme }) => theme.colors.text};
          }

          div {
            color: ${({ theme }) => theme.colors.text};
          }
        `
      : ''}
`

const StyledViewHistoryText = styled(Text)`
  cursor: pointer;
  font-size: 12px;
`

const StyledTab = styled(Tab)<{ $active: boolean }>`
  padding: 12px;
  width: 33%;

  svg {
    min-width: 20px;
    transition: ${({ theme }) => theme.transitions.fast};
    fill: ${({ theme, $active }) => ($active ? `${theme.colors.text} !important` : theme.colors.textSubtle)};
  }

  ${Text} {
    transition: ${({ theme }) => theme.transitions.fast};
    color: ${({ theme, $active }) => ($active ? `${theme.colors.text} !important` : theme.colors.textSubtle)};
    white-space: pre !important;
  }
`

const StyledContent = styled(Box)`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ::-webkit-scrollbar {
    width: 3px !important;
  }
`

export default Payment
