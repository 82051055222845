import { ChainIdEnum } from 'config/constants/network'
import { LuckySpinStatusProps, SpinPrizeInfo, SpinPrizeTransaction } from 'config/types/luckyspin'
import { BaseResponse, Paging } from 'services/types'
import { formatApiNetworkField } from 'utils'

export const SpinPrizeListMapper = (
  rawResponse: string,
): BaseResponse<{ items: SpinPrizeInfo[]; nextSpinTime: number }> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      items: response.data.items.map(
        (item) =>
          ({
            id: item.id,
            currency: item.currency,
            network: ChainIdEnum[formatApiNetworkField(item.network)],
            amount: item.amount,
          } as SpinPrizeInfo),
      ),
      nextSpinTime: response.data.next_paying_time * 1000 || 0,
    },
  }
}
export const SpinPlayMapper = (rawResponse: string): BaseResponse<SpinPrizeInfo> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      id: response.data.id,
      currency: response.data.currency,
      network: ChainIdEnum[formatApiNetworkField(response.data.network)],
      amount: response.data.amount,
    },
  }
}
export const SpinPrizeTransactionMapper = (rawResponse: string): BaseResponse<SpinPrizeTransaction[]> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data:
      response.data &&
      response.data.items.map(
        (item) =>
          ({
            id: item.id,
            currency: item.currency,
            network: ChainIdEnum[formatApiNetworkField(item.network)],
            amount: item.amount,
            address: item.subject,
            createTime: item.create_time * 1000,
          } as SpinPrizeTransaction),
      ),
  }
}
export const SpinMyPrizeTransactionMapper = (rawResponse: string): BaseResponse<Paging<SpinPrizeTransaction>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      paging: response.data.paging,
      items: response.data.items.map(
        (item) =>
          ({
            id: item.id,
            currency: item.currency,
            network: ChainIdEnum[formatApiNetworkField(item.network)],
            amount: item.amount,
            address: item.subject,
            createTime: item.create_time * 1000,
          } as SpinPrizeTransaction),
      ),
    },
  }
}

export const SpinFreePlayTimeMapper = (rawResponse: string): BaseResponse<LuckySpinStatusProps> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: {
      nextSpinTime: response.data.next_paying_time * 1000,
      amount: response.data.next_paying_time === null ? Number(response.data.amount) + 1 : response.data.amount,
    },
  }
}
